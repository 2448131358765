		//  ------------------------------------------
		//	Styling for HTML elements which overrides and
		//	adds to the base styling set in _BaseElements.scss.
		//	Try to oranize your code according to the
		//	groupings below.
		//  ------------------------------------------


		//  ------------------------------------------
		//	Base elements (html, body)
		//  ------------------------------------------



		//  ------------------------------------------
		//	Links
		//  ------------------------------------------

a {
	font-weight: 700;

	&:hover {
		border: none;
	}
}


		//  ------------------------------------------
		//	Paragraphs, headlines & general typography
		//  ------------------------------------------

p {
	text-indent: 0;
	font-size: 16px;
	line-height: 26px;

	a:hover {
		text-decoration: underline;
	}
}

li {
	font-size: 16px;
	line-height: 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $headline-font-color;
}

h1 + h2.alternate,
header + h2.alternate,
.main-headline + .frame-default header:first-child h2.alternate {
	margin-top: -4px;
}

		//  ------------------------------------------
		//	Lists and block quotes
		//  ------------------------------------------

		//  ------------------------------------------
		//	Tables
		//  ------------------------------------------

		//  ------------------------------------------
		//	Code and pre
		//  ------------------------------------------

		//  ------------------------------------------
		//	Additional tags
		//  ------------------------------------------

blockquote {
	color: $color-2;
	border-top: $border-2b;
	border-bottom: $border-2b;
	margin: 9px 10px 9px 0 !important;
	padding-left: 18px;
	padding-right: 18px;
	padding-top: 12px;
	padding-bottom: 12px;

	p {
		font-family: $headline-font;
		font-weight: bold;
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 9px;

		&:first-child {
			text-indent: -7px;
		}

		&.caption {
			font-size: 13px;
			line-height: 15px;
			margin-bottom: 9px;
			color: $color-2;
		}
	}
}
