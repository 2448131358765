		//  =========================================
		//	DO NOT EDIT THIS FILE!!!!.
		//	This establishes base settings. Use the SiteElements.scss file in the
		//	skin to change these values by overriding them.
		//  =========================================


		//  ------------------------------------------
		//	Base elements
		//  ------------------------------------------

html {
	font-size: $base-rhythm-raw/16*100%;
}

body {
	background-color: $page-background-color;
	color: $text-color;
	font-family: $font-1;
	@include block-typography(base);
}

		//  ------------------------------------------
		//	Links
		//  ------------------------------------------

a {
	color: $link-color;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $link-color-hover;
		border-bottom: 1px dotted $link-color-hover;
	}
}

		//  ------------------------------------------
		//	Paragraphs, headlines & general typography
		//  ------------------------------------------

p {
	@include block-typography(base);
	text-indent: 30px;

	&.small {
		@include block-typography(small);
		text-indent: 0;
	}

	&.x-small {
		@include block-typography(x-small);
		text-indent: 0;
	}

	&.large {
		@include block-typography(large);
		text-indent: 0;
	}

	&.x-large {
		@include block-typography(x-large);
		text-indent: 0;
	}
}

h1 {
	@include block-typography(h1);
	@include headline-setup();
}

h2 {
	@include block-typography(h2);
	@include headline-setup();
}

h3 {
	@include block-typography(h3);
	@include headline-setup();
}

h4 {
	@include block-typography(h4);
	@include headline-setup();
}


h5 {
	@include block-typography(h4);
	@include headline-setup();
}

h6 {
	@include block-typography(h4);
	@include headline-setup();
}

strong, b {
	font-weight: bold;
}

em, i {
	font-style: italic;
}

		//  ------------------------------------------
		//	Lists and block quotes
		//  ------------------------------------------

ul, ol {
  margin: 0 0 $base-rhythm $indent-1;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul {
	list-style: disc;
}
ol {
	list-style: decimal;
}

dl {
	margin-bottom: $base-rhythm;
}

dl dt {
	font-weight: bold;
}

dl dd {
	margin-left: $indent-1;
}

blockquote {
	margin-right: $indent-1;
	margin-left: $indent-1;
	@include rem(margin-top, $base-rhythm);
	@include rem(margin-bottom, $base-rhythm);

	p {
		text-indent: 0;
	}
}

nav ul {
	margin: 0;
	list-style: none;
}

		//  ------------------------------------------
		//	Tables
		//  ------------------------------------------

table {
	width: 100%;
}

		//  ------------------------------------------
		//	Code and pre
		//  ------------------------------------------

code,
pre,
kbd,
samp {
	font-family: $font-mono;
}

		//  ------------------------------------------
		//	Additional tags
		//  ------------------------------------------

figcaption {
	@include block-typography(x-small);
	font-style: italic;
	margin-top: 3px;
}

sup {
	font-size: .85em;
	vertical-align: super;
}

sub {
	font-size: .85em;
	vertical-align: sub;
}

q {
	quotes:"\201C" "\201D" "\2018" "\2019";
}

q:before {
	content:open-quote;
}

q:after {
	content:close-quote;
}

cite,
var {
	font-style: italic;
}

ins {
	text-decoration: underline;
}

.responsive-image {
    img {
        width: 100%;
    }
}
