		//  ------------------------------------------
		//	Styling for the header, footer and menus
		//  ------------------------------------------

		//  ------------------------------------------
		//	Header styling
		//  ------------------------------------------

#header-page {
	height: 165px;
	margin-bottom: 37px;
	z-index: 100;
	position: relative;

	#identity {
		width: 394px;
		height: 97px;
		margin: 20px 0 19px;
		float: left;
		background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/BronxLebanonLogo.png) no-repeat left top;
		overflow: hidden;
		border: none;

		a {
			display: block;
			padding-top: 100px;
		}
	}
	#utility-nav {
		list-style: none;
		float: right;
		margin: 20px 0 23px 16px;

		li {
			float: left;
			font-size: 13px;
			line-height: 13px;
			margin-right: 13px;
			padding-right: 13px;
			border-right: 1px solid $color-1b;

			&:last-child {
				padding-right: 0;
				margin-right: 0;
				border-right: none;
			}

			a {
				color: $color-1;
				font-weight: 400;
			}
		}
	}
    .search {
		float: right;
		clear: right;
		position: relative;
		overflow: hidden;
        margin-top: 27px;

		label {
			position: absolute;
			left: -9999px;
		}

		input {
			@include border-radius();
			width: 160px;
			padding: 6px 30px 6px 10px;
			background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/SearchGlass.png) no-repeat right 3px;
			border: $border-1;
		}
	}
}

		//  ------------------------------------------
		//	Footer styling
		//  ------------------------------------------
#footer-page {
	@extend .clearfix;
	clear: both;
	background-color: $color-1b;
	padding: 30px 20px 10px;

	a {
		font-weight: 400;
	}

	h5 {
		font-size: 12px;
		line-height: 20px;
		margin: 0 0 9px;
		color: $color-1;
		text-transform: uppercase;
	}

	#footer-columns-inner-wrap {
		width: 680px;
		float: left;
		padding-bottom: 10px;

		p {
			font-size: 13px;
			line-height: 15px;
			margin-bottom: 7px;

			a {
				color: $text-color;
				font-weight: 400;
			}
		}
	}
	#footer-contact {
		width: 215px;
		float: right;
		padding-left: 20px;
		border-left: 1px solid #a1b1c4;

		p {
			font-weight: bold;
			color: $color-1;
			font-size: 12px;
		}
		 margin-bottom: 20px;
	}

	.bottom-cap {
        clear: both;
		position: relative;
		border-top: 1px solid #a1b1c4;
		padding-top: 10px;

		.footer-id {
            display: block;
			width: 276px;
			height: 81px;
			background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/BronxLebanonFooterLogo.png) no-repeat left top;
			overflow: hidden;
			&:hover {
				border-bottom: none;
			}
			span {
				display: block;
				padding-top: 100px;
			}
			margin: 0;
		}

		.copyright {
            position: absolute;
			bottom: 9px;
			right: 0;
			font-size: 11px;

			a {
				color: $text-color;
			}
		}
	}
}

#copyright {
	float: left;
}
#creator-link {
	float: right;
}

		//  ------------------------------------------
		//	Globlal and Section Nav styling
		//  ------------------------------------------

// @todo_ben the following css code is important, that fixes a very bad issue in IE8 and IE9 (maybe later)
#global-nav-wrap {
	clear: both;
	position: relative;

	#global-nav {
		position: absolute;
		top: 100%;
		z-index: 200;
	}

	#section-nav {
		position: absolute;
		top: 34px;
		width: 100%;
		z-index: 100;
	}
}
// end of @todo_ben



#global-nav {
	@extend .clearfix;
	clear: both;

	background-color: $color-1;

	ul {
		margin: 0;
		width: 960px;
	}

	li {
		float: left;
		height: 33px;
		border-left: 1px solid white;
		border-top: 1px solid $color-1;
		// overflow: hidden;

		&:first-child {
			border-left: 1px solid $color-1;
		}

		&:last-child {
			// @todo_ben the reason I comment it out is because that causes a small issue (a extra vertical line) for the the Job menu, the menu still work without the last child floating to right
			//float: right;
		}

		a {
			font-family: $font-2;
			font-size: 15px;
			line-height: 15px;
			font-weight: 600;
			color: $color-1b;
			display: block;
			text-align: center;
			padding: 9px 0px;

			&:hover {
				color: white;
			}
		}

		&:first-child,
		&:first-child a {
			width: 90px;
		}

		&:nth-child(2),
		&:nth-child(2) a {
			width: 112px;
		}

		&:nth-child(3),
		&:nth-child(3) a {
			width: 120px;
		}

		&:nth-child(4),
		&:nth-child(4) a {
			width: 136px;
		}

		&:nth-child(5),
		&:nth-child(5) a {
			width: 120px;
		}

		&:nth-child(6),
		&:nth-child(6) a {
			width: 106px;
		}

		&:nth-child(7),
		&:nth-child(7) a {
			width: 184px;
		}

		&:last-child,
		&:last-child a {
			width: 83px;
		}
	}

	.active {
		background-color: $color-1b;
		border-top: 1px solid $color-1b;
		border-left: 1px solid white;

		a,
		a:hover,
		a:focus {
			color: $color-1;
		}
	}
	.open,
	.open:first-child,
	.hovered,
	.hovered:first-child {
		border-left: 1px solid $color-2;
		border-top: 1px solid $color-2;

		& + .trigger {
			border-left: 1px solid $color-2;
		}

		a,
		a:hover,
		a:focus {
			color: $color-1;
			background-color: white;
			height: 30px;
		}
	}
	.open:last-child,
	.hovered:last-child {
		border-right: 1px solid $color-2;
	}

	.next {
		//border-left: 1px solid $color-2;
	}
}

#section-nav {

	a {
		color: $color-1;

		&:hover {
			color: $color-2;
		}
		font-size: 15px;
		line-height: 23px;
	}

	p {
		margin-bottom: 3px;
		margin-left: 10px;
		text-indent: -10px;

		&.indent {
			margin-left: 20px;
			margin-bottom: 0;
		}

		&.line-after {
			margin-bottom: 5px;
		}

		&,
		& a {
			font-size: 15px;
			line-height: 23px;
		}
	}



	.column-group .column {
		padding-right: 14px;
		border-right: 1px solid $color-1b;
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
			padding-right: 0;
			border: none;
		}
	}

	.mega-menu-container {
		display: none;
	}

	// #c71 is the three column group for the Our Services mega menu
    .mega-menu-container, #c71 {
        padding: 20px;
        background-color: white;
        border: 1px solid $color-2;
	}
    .mega-menu-container--single {
		width: 200px;
    }
}

		//  ------------------------------------------
		//	Local Nav styling
		//  ------------------------------------------


.local-nav {

	font-family: $font-2;
	font-size: 15px;
	line-height: 17px;

	&__title {
		font-family: $font-2;
		font-size: 15px;
		line-height: 18px;
		margin: 0;
		padding-bottom: 7px;
		border-bottom: 1px solid $color-1b;

		a {
			color: black !important;
		}
	}

	a {
		color: $color-1;
	}

	li {
		padding: 7px 0 7px 20px;
		border-bottom: 1px solid $color-1b;
		text-indent: -10px;

		a {
			font-weight: 600;
		}

		&.current {

			background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/MenuDot.png) no-repeat left 16px;

			& > a {
				color: $color-2;
			}
		}

		li {
			border: none;
			font-size: 13px;
			line-height: 15px;
			padding: 4px 0 0 20px;
			margin-left: -10px;

			&.current {
				background-position: left 8px;
			}
		}
	}
}
