

/* END SELECTOR PLUGIN ONLY */

/* .bronx-donations .row .column {
  margin: 0 20px 0 0 !important;
} */


@media only screen and (min-width: 320px) and (max-width: 767px) {
    body #page-wrap .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
        /* width: 100%; */
        width: 101px;
        float: left !important;
        /* margin-left: 20px; */
    }
    .bronx-donations .row .column {
        margin-bottom: 0 !important;
        margin-right: 20px !important;
    }
    #programs_wrap .input-combo:last-child {
        display: block;
        margin-top: 10px;
    }
}

#powermail_fieldwrap_24 label, #powermail_fieldwrap_25 label {
    font-size: 19px !important;
}

.bronx-donations .form-section--payment-form-amount {
    margin-bottom: 0;
}


.bronx-donations {
    border-top: none;
    border-bottom: none;
}

#payment_form {
    border-top: 1px solid #b42c33;
    border-bottom: 1px solid #b42c33;
}

/* END SELECTOR PLUGIN ONLY */

.form-section--payment-form-amount .last.active label,
.form-section--payment-form-amount .last.active .donation_select {
    display: none;
}

.parsley-error-list {
    color: red;
    font-size: 13px;
}

.form-section--donation-select h3 {
    display:none !important;
}

.bronx-donations .form-section .credit-card {
    margin-top: 0 !important;
}


/* .bronx-donations .form-section--donation-select fieldset.donation .amount-wrap .column:nth-last-child(1) {
  width: 101px !important;
  margin-top: 0px !important;
  margin-right: 0 !important;
  float: left !important;
}
*/

/* .bronx-donations .form-section--donation-select fieldset.donation .amount-wrap .column:nth-last-child(2) {
  margin-right: 20px !important;
  margin-bottom: 15px;
} */



.bronx-donations .row .column {
    margin: 0 20px 15px 0;
}


.bronx-donations h3 {
    font-size: 19px !important;
    margin-bottom: 4px;
}

.donation {
    position: relative;
}

.donation label {
    position: relative;
    background-color: white;
    width: 66px;
    display: block;
    top: -30px;
    border: 1px solid #336796;
    border-radius: 5px;
    text-align: center;
    padding: 7px 0;
    // font-style: ;
    font-weight: bold;
    color: #009ddc;
    cursor: pointer;
}

.donation label:hover, .input-combo.column.last:hover .donation-other-prefix {
    background-color: #3ac2f9;
    color: #ffffff;
}

.input-combo.column.last.active:hover .donation-other-prefix {
    background-color: #009ddc;
}

.donation .active label {
    // background-color: ;
    background-color: #009ddc;
    color: white;
}

.donation label {
    width: 100px;
    padding: 14px 0;
    font-size: 17px;
}

.bronx-donations .form-section fieldset.donation .amount-wrap .column {
    /* width: 65px; */
    width: 101px;
}

.bronx-donations .form-section fieldset:first-of-type {
    /* margin-top: 0; */
    margin-top: 18px;
}

.bronx-donations h4 {
    font-size: 19px;
}

.bronx-donations input[type="text"], .bronx-donations input[type="email"] {
    height: 45px;
}

.bronx-donations select {
    height: 45px;
}

.bronx-donations .submit-wrap button {
    font-size: 22px;
}

.bronx-donations input[type="text"], .bronx-donations input[type="email"] {
    font-size: 17px;
}

.bronx-donations select {
    font-size: 17px;
}

.bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
    margin-top: 0;
    float: right;
    /* margin-right: 10px; */
}

#donationOther:disabled {
    display:none;
}

/* .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
  margin-top: -27px;
  float: right;
  margin-right: 10px;
} */

.bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
    /* width: 200px; */
    /* width: 216px; */
    width: 101px;
    position: relative;
}

#donationOther {
    display: block;
    //background-color;
    border: 1px solid #336796;
    border-radius: 5px;
    display: block;
    font-size: 17px;
    font-weight: 700;
    margin: 0;
    padding: 14px 0 14px 30px;
    position:relative;
    // text-align: center;
    top: -10px;
    vertical-align: baseline;
    width: 70px !important;
    height: auto;
    color: #009ddc;
}


@media only screen and (min-width: 320px) and (max-width: 1199px) and (orientation: portrait) {
    #donationOther {
        width: 101px !important;
        height: 50px !important;
    }
}

.active #donationOther {
    background-color: #009ddc;;
    color: white;
}

#donationOther::placeholder {
    opacity: 1;
}

/* .active #donationOther::placeholder {
  opacity: 0;
} */


.donation-other-prefix {
    position: absolute;
    top: 6px;
    left: 18px;
    font-size: 17px;
    font-weight: 700;
    color: #009ddc;
}

.active .donation-other-prefix {
    color: white;
}


@media only screen and (min-width: 320px) and (max-width: 1199px) and (orientation: portrait) {
    .bronx-donations input[type="text"], .bronx-donations input[type="email"] {
        height: 45px !important;
    }
    /* .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
      margin-right: 0;
    } */
}

.bronx-donations .form-section--billing {
    margin-bottom: 10px;
}

.pay-check {
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    color: #b42c33;
    margin-bottom: 40px;
}

.pay-check--normal-text {
    font-weight: normal;
}

#programs_wrap {
    font-size: 18px;
}

#programs_wrap input {
    width: auto;
}

#programs_wrap input:disabled {
    background-color: white;
    visibility:hidden;
}

#programs_wrap .input-combo {
    margin-right: 15px;
}
