		//  ------------------------------------------
		//	SET UP RTE Styles
		//	This file will be attached to the RTE
		//	Use it for styles in the RTE that need to
		//	on BOTH front-end and back-end
		//  ------------------------------------------


@import "~compass-mixins/lib/compass/css3";
@import "~compass-mixins/lib/compass/utilities";
@import "~compass-mixins/lib/compass/reset";
@import "../Base/_BaseFunctions";
@import "../Base/_variables";
@import "../Base/_Fonts";
@import "../Base/_BaseElements";
@import "../Partials/_SiteElements";
@import "../Base/_BaseTYPO3Overrides";
@import "../Base/_BaseHelperClasses";
@import "../Base/_BaseRTEClasses";

figcaption,
p.caption {
	font-size: 14px;
	line-height: 18px;
	font-style: italic;
	font-weight: 700;
	color: $color-1;
}

a.button-red, input.button-red {
	@include border-radius(5px);
	text-align: left;
	font-family: $font-2;
	font-weight: 400;
	padding: 2px 20px 3px 10px;
	text-decoration: none;
	font-size: 13px;
	margin-bottom: 5px;
	margin-top: 5px;
	display: inline-block;
	*display: inline;
	zoom: 1;
	color: #ffffff;
	@include box-shadow(3px 1px 3px rgba(0,0,0,.25));
	background: $color-2 url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/ArrowRightWhite.png) no-repeat right center;

	&.no-dash-arrow {
		background: $color-2 none;
		padding: 3px 18px;
		margin: 0;
	}
}

// Only here to get fancybox class to appear in RTE
a.fancybox {
	zoom: 1;
}

p.feature-text {
	font-family: $font-2;
	font-style: italic;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	color: #757575;
	margin-bottom: 15px;
}

span, b, strong, i, em {
	&.highlight-blue {
		color: $color-1;
	}
}

ul.link-list-blue {
	list-style: none;
	margin: 0;

	a {
		color: $color-1;
		line-height: 16px;


		&:hover {
			color: $color-2
		}
	}

	li {
		padding-left: 18px;
		text-indent: -12px;
		margin-bottom: 6px;
	}
}



h1.line-below,
h2.line-below,
h3.line-below,
h4.line-below,
h5.line-below,
h6.line-below {
	padding-bottom: 4px;
	border-bottom: $border-1;
}

h1.alternate,
h2.alternate,
h3.alternate,
h4.alternate,
h5.alternate,
h6.alternate
 {
	color: #4d4d4d;
	font-weight: 400;
}
