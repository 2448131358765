/*
  ##Device = Desktop
  ##Screen = 1204px and above
*/

@media only screen and (min-width: 1204px) {
    /* Mobile Menu */
    #mobile-nav-trigger,
    #mobile-nav {
        display: none;
    }
}

/*
  ##Device = Most of the Smartphones Mobiles
  ##Screen = B/w 320px to 812px
*/
@media only screen and (min-width: 320px) and (max-width: 767px) {

    /*--------------------------------------------------------------
    # Global
    --------------------------------------------------------------*/

    body {
        margin-bottom: 0 !important;

        & #page-wrap {
            width: auto;
            max-width: 960px;
            padding: 0 20px;
            box-sizing: border-box;
        }

        & blockquote {
            margin: 16px 10px 20px 0 !important;
            padding-bottom: 6px;
        }

        & p {
            font-size: 16px;
            line-height: 26px;
        }



        /*--------------------------------------------------------------
        # Header
        --------------------------------------------------------------*/

        & #header-page {
            height: auto;
            margin: 20px auto 30px !important;
            position: relative;

           & #identity {
               width: 100%;
               max-width: 230px;
               background-size: contain !important;
               height: 80px !important;
               float: none;
               margin: 85px 0 10px 0 !important;
               display: inline-block;
           }

           & #utility-nav {
               float: none;
               margin: 0 !important;
               position: absolute;
               top: 0;
               right: 0;
               text-align: right;

               & li {
                   float: none;
                   display: inline-block;
                   border-right: 0;
                   border-left: 1px solid #c4d3e0;
                   margin-right: 0;
                   margin-left: 13px;
                   padding-right: 0;
                   padding-left: 13px;
               }
           }

           & .search {
               float: none;
               margin: 0;
               width: 50%;
               display: inline-block;
               position: absolute;
               top: 50px;
               right: 0;

               & input {
                   width: 100%;
                   box-sizing: border-box;
               }
           }
        }


        /* Hamburger Icon */
        & #mobile-nav-trigger {
            display: block;
            position: relative;
            float: right;
            margin: 116px -10px 0 0px;
            display: inline-block;
            padding: 10px;
            box-sizing: border-box;

            & span {
                width: 30px;
                height: 5px;
                background-color: #155085;
                display: block;
                margin: 4px 0;
                transition: 0.4s;
                position: relative;
                z-index: 20;
            }

            &.open{
                &> span {

                    &:first-child {
                        -webkit-transform: rotate(-45deg) translate(-9px, 4px);
                        transform: rotate(-45deg) translate(-9px, 4px);
                    }

                    &:last-child {
                        -webkit-transform: rotate(45deg) translate(-8px, -4px);
                        transform: rotate(45deg) translate(-8px, -4px);
                    }

                    &:not(:first-child):not(:last-child) {
                        opacity: 0;
                    }
                }

                &:before {
                    content: "";
                    display: block;
                    background-color: #fff;
                    border: 1px solid #155085;
                    border-bottom-color: #fff;
                    position: absolute;
                    top: -6px;
                    left: -13px;
                    width: 71px;
                    //height: 56.5px;
                   height: 70px;
                    z-index: 10;
                }
            }
        }


        /* Mobile Menu */
        & #global-nav-wrap {
            position: inherit;

            & #section-nav, & #global-nav {
                display: none;
            }

            & #mobile-nav {
                display: none;
                position: absolute;
                background-color: rgba(255,255,255,.95);
                border: 1px solid #155085;
                //width: 100%;
                width: calc(100% + 40px);
                margin: 0 -20px;
                box-sizing: border-box;
                z-index:9;

                &.active {
                    display: block;
                }
            }
        }

        & #mobile-nav{
            ul {
                width: auto;
                padding: 20px;

                & li {
                    display: block;
                    float: none;
                    width: auto !important;
                    border: 0;
                    height: auto;

                    &:first-child,
                    &:first-child a {
                        width: auto;
                        border: 0;
                    }

                    & a {
                        text-align: left;
                        color: #155085;
                        padding: 5px 0;
                        border: 0;
                        display: block;
                        height: auto;
                        font-size: 18px;
                        line-height: 22px;
                        font-family: "ff-din-web", Helvetica, Arial, sans-serif;
                    }
                }

            }

            & .open a,
            & .open a:hover,
            & .open a:focus,
            & .open:first-child a,
            & .open:first-child a:hover,
            & .open:first-child a:focus,
            & .hovered a,
            & .hovered a:hover,
            & .hovered a:focus,
            & .hovered:first-child a,
            & .hovered:first-child a:hover,
            & .hovered:first-child a:focus {
                height: auto;
            }


            & .open + .trigger,
            & .open:first-child + .trigger,
            & .hovered + .trigger,
            & .hovered:first-child + .trigger {
                border-left: 0;
            }

            & li a:hover,
            & li a:focus {
                color: #155085;
            }

            & .active {
                background-color: transparent;
                border: 0;

                & a {
                    color: #155085;

                    &:hover,
                    &:focus {
                        color: #155085;
                    }

                }
            }

            /* Submenu */
            & > ul > li:not(.no-submenu) > span,
            & > ul li.has-submenu > span {
                position: relative;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items: center;

                & strong,
                & strong {
                    display: inline-block;
                    margin-left: auto;
                    font-size: 14px;
                    color: #155085;
                    padding: 5px 0 5px 15px;
                    cursor: pointer;
                }
            }

            & > ul > li:not(.no-submenu).active > span strong,
            & > ul li.has-submenu.active > span strong {
                transform: rotate(180deg);
                padding: 5px 15px 5px 0;
            }

            & > ul > li:not(.no-submenu).active > a,
            & > ul > li:not(.no-submenu).active > span,
            & > ul > li:not(.no-submenu).active > span a,
            & > ul > li:not(.no-submenu).active > span strong {
                color: #b42c33;
            }

            & > ul > li:not(.no-submenu).active > ul {
                display: block;
            }

            & ul ul {
                padding: 0 0 10px 20px;
                display: none;

                & a {
                    font-size: 16px;
                    line-height: 18px;
                    text-indent: -10px;
                    padding-left: 10px;
                }

                & li p {
                    margin-bottom: 0;
                    padding-right: 20px;

                    &:first-child {
                    }
                }
            }


            & > ul li.has-submenu ul {
                padding-bottom: 1px;
            }

            & > ul li.has-submenu.active ul {
                display: block;
            }

            & > ul li.has-submenu.active > a {
                color: #b42c33;
            }

        }

        /*--------------------------------------------------------------
          # Images
          --------------------------------------------------------------*/
        // @todo-fluid All of this ce-textpic code will need adjustment.  This is the bolted on responsive work, so its probably easiest to refactor
        & div.ce-textpic:not(.ce-textpic .ce-left .ce-intext .ce-nowrap):not(.ce-textpic-above):not(.ce-textpic-below):not(.ce-textpic .ce-right .ce-intext):not(.ce-textpic .ce-right .ce-intext .ce-nowrap):not(.ce-textpic .ce-left .ce-intext) {
            position: relative;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-items: center;
        }

        .ce-textpic .ce-right .ce-intext .ce-gallery,
        & .ce-textpic .ce-left .ce-intext .ce-gallery,
        & .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery,
        & .ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery {
            padding-top: 0;
        }

        & div.ce-textpic .ce-gallery figure,
        & div.ce-textpic figure.ce-gallery {
            display: block;
        }

        & div.ce-textpic .ce-gallery .image {
            margin-bottom: 20px;
        }

        & figcaption.ce-textpic-caption {
            display: block;
            padding-top: 12px;
            font-size: 13px;
            line-height: 18px;
        }

        & div.ce-textpic .ce-gallery img,
        & .single-image {
            height: auto;
            width: 100%;
            margin-bottom: 0;
        }

        & div.ce-textpic .ce-center .ce-textpic .ce-outer{
            float: none;
            width: auto;
            right: inherit;
        }

        & div.ce-textpic .ce-center .ce-textpic .ce-inner {
            float: none;
            right: inherit;
        }

        & div.ce-textpic .ce-right .ce-intext .ce-gallery {
            margin: 0;
            float: none;
        }

        & div.ce-textpic .ce-left .ce-intext .ce-gallery {
            margin-right: 20px;
            width: 100%;
        }

        & div.ce-textpic .ce-left .ce-intext p {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 20px;
        }

        & div.ce-textpic-left .ce-gallery {
            float: none;
            width: 100%;
        }

        & div.ce-textpic-right .ce-gallery {
            width: 100%;
        }

        & div.ce-textpic .ce-center .ce-gallery {
            width: 100%;
        }

        & div.ce-textpic .ce-right .ce-intext .ce-gallery,
        & div.ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
            margin-left: 0;
            float: none;
        }

        & .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-bodytext,
        & .ce-textpic .ce-right .ce-intext .ce-nowrap-266 .ce-bodytext {
            margin-right: 0;
        }


    }



    /*--------------------------------------------------------------
    # Layout
    --------------------------------------------------------------*/

    #generated-content-1 {
        display: none;
    }

    #f1d #page-wrap #content-wrap,
    #f2d #page-wrap #content-wrap {
        width: auto;
        float: none;
    }

    #f2d #page-wrap #content-block-1 {
        width: auto;
        float: none;
    }

    #f2d #page-wrap #content-block-2 {
        width: auto;
        float: none;
        margin-top: 40px;
    }

    #f3b #page-wrap #content-wrap {
        float: none;
        margin: 0;
    }

    #f3b #page-wrap #content-block-1,
    #f3b #page-wrap #content-block-2,
    #f3b #page-wrap #content-block-3 {
        width: auto;
        float: none;
        border: 0;
        padding: 0;
        margin: 0;
        min-height: inherit !important;
    }

    #f3b #page-wrap #content-block-1,
    #f3b #page-wrap #content-block-2 {
        border-bottom: 1px solid #CCCCCC;
        margin-bottom: 20px;
    }

    /* Columns */
    body{
        & .column-group .column:not(.column-1-c3204):not(.column-2-c3204),
        & .module-group .module {
            float: none;
            margin: 0;
            width: auto;
        }

        & .column-group .column img {
            width: 100%;
            height: auto;
            // margin: 15px auto 30px;
            margin: 0;
        }

        & .hover-image .hover-image {
            display: none !important;
        }

    }

    /*--------------------------------------------------------------
    # Feature
    --------------------------------------------------------------*/

    #f1d #page-wrap #feature,
    #f2d #page-wrap #feature {
        width: auto;
        float: none;
        margin-bottom: 30px;
    }

    body{
        & .interior-feature-promo {
            margin-bottom: 0;

            & > .main-image {
                float: none;
                width: 100%;
                height: auto;
                display: block;
            }

            & > .content {
                float: none;
                width: auto;
                padding: 10px 15px;
            }
        }


        & p.feature-text {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 20px;
        }

        & figcaption,
        & p.caption {
            margin-top: 0;
            font-size: 13px;
            line-height: 18px;
        }

        & .ce-textpic .ce-left .ce-intext .ce-nowrap .ce-bodytext {
            margin: 0;
        }
    }

    /*--------------------------------------------------------------
    # New Home
    --------------------------------------------------------------*/

    body#f1a #feature .flexslider {
        margin: 0 0 20px 0 !important;
        height: auto;

        & .slides {

            & a {
                display: block;
            }

            & img {
                margin: 0;
            }

            & .slide-text {
                padding: 20px 20px 0 20px;
                border: 0;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: column-reverse;

                & .discription span {
                    font-size: 14px !important;
                    line-height: 21px;
                }

            }

            & .more-btn {
                float: none;
                width: auto;
                margin-top: 14px;
                margin-left: 0;
                height: auto;

                & a {
                    font-size: 15px;
                    line-height: 22px;
                    text-align: center;
                    padding-bottom: 6px;
                    padding-top: 11px;
                }
            }

        }

        & .flex-control-nav {
            bottom: inherit !important;
            top: inherit !important;
            left: inherit !important;
            position: relative;
            padding: 14px;
            box-sizing: border-box;
            text-align: center !important;

            & li {
                margin: 0 6px !important;

                & a {
                    width: 14px !important;
                    height: 14px !important;
                    box-shadow: none;
                }
            }

            & .flex-prev, & .flex-next {
                top: 70px !important;
            }


        }

    }


    /*--------------------------------------------------------------
    # Home
    --------------------------------------------------------------*/

    body{
        & #find-physician h3,
        & .physician-app.search .search-wrap h3 {
            font-size: 15px;
            line-height: 25px;
        }

        & #find-physician .content,
        & .physician-app.search .search-wrap .content {
            height: auto;
            min-height: 107px;
            padding-bottom: 30px;
        }

        & #find-physician .hasCustomSelect,
        & .physician-app.search .search-wrap .hasCustomSelect,
        & #find-physician .roundedInput,
        & .physician-app.search .search-wrap .roundedInput {
            width: 100%;
            height: auto;
            padding: 10px 10px;
            box-sizing: border-box;
            box-shadow: none;
            border: 1px solid #ccc;
            font-size: 13px !important;
            margin-bottom: 10px;
            outline: none;
        }

        & #find-physician .content .button-red,
        & .physician-app.search .search-wrap .content .button-red {
            font-size: 15px;
            line-height: 20px;
            padding: 10px 20px 6px;
            margin-bottom: 0;
            margin-top: 5px;
        }

        & #content-wrap .home-promo {
            margin-bottom: 40px;

            & img {
                width: 100%;
                height: auto;
                margin: 0 auto;
            }

            & .home-promo-text{
                & h3 {
                    font-size: 21px;
                    line-height: 27px;
                }

                & p {
                    font-size: 17px;
                    margin-bottom: 0.6em;
                    line-height: 25px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                & .link {
                    font-size: 16px;
                    line-height: 20px;
                    background-position: 100% 4px;
                    padding-right: 20px;
                }
            }
        }


        & .promo-toggle-btn {
            background-size: 980px auto;
            background-position: 100% 50%;
        }
    }

    /*--------------------------------------------------------------
    # About Us
    --------------------------------------------------------------*/

    body{
        & .column-group .column-1-c3204,
        & .column-group .column-2-c3204 {
            width: 50%;
            margin: 0;
            box-sizing: border-box;
        }

        & .column-group .column-2-c3204 {
            padding-left: 20px;
        }

        /* History */
        & .history-timeline{
            & .date {
                margin-top: 3px;
                float: none;
            }
            & .description {
                margin-left: 0;
                margin-top: 5px;
            }
            & img {
                width: 100%;
                height: auto;
                display: block;
                padding-left: 0 !important;
            }
            & table th,
            & table td {
                display: block;
            }

            & table th:last-child,
            & table td:last-child {
                margin-top: 10px;
            }
        }
    }



    /* Make a Contribution */
    body{
        & #page-wrap .bronx-donations{
            & .form-section {
                margin-bottom: 20px;
            }

            & .form-section fieldset:not(.donation) .column,
            & .form-section fieldset:not(.donation) .column.last,
            & .row.two-column-row .column {
                width: 100% !important;
            }

            & .form-section fieldset.donation .amount-wrap .column.last {
                width: 100%;
            }

            & input[type=text],
            & input[type=email] {
                box-sizing: border-box;
                height: 35px;
            }

            & .submit-wrap {
                margin: 0 auto 30px;

                & button {
                    width: 100%;
                }
            }
        }
    }


    /*--------------------------------------------------------------
    # Physicians
    --------------------------------------------------------------*/

    /* Tabs */
    body{
        & .physician-app.search{
            & .tabs {
                height: auto;
                margin-top: 0;

                & li {
                    margin-top: 10px;
                }
            }

            & .search-wrap form input[type="submit"] {
                padding: 12px 29px 10px 16px;
                margin-left: 0;
                font-size: 16px;
            }

            /* List */
            & .results-wrap .results li > a:first-of-type {
                width: 25%;
                display: block;
                position: relative;
                float: left;
            }
        }
    }


    body {
        .physician-app.search {
            & .tabs {
                height: auto;
                margin-top: 0;

                & li {
                    margin-top: 10px;
                }
            }

            & .search-wrap form input[type="submit"] {
                padding: 12px 29px 10px 16px;
                margin-left: 0;
                font-size: 16px;
            }

            /* List */
            & .results-wrap .results li > a:first-of-type {
                width: 25%;
                display: block;
                position: relative;
                float: left;
            }

            & .results-wrap {
                & .results {
                    & li {
                        & img {
                            height: auto;
                            margin-right: 0;
                            width: 100%;
                        }
                        & .placeholder {
                            height: auto;
                            margin-right: 0;
                            width: 100%;
                        }
                        & .description {
                            width: 75%;
                            padding-left: 20px;
                            margin: 0;
                            box-sizing: border-box;
                            float: right;
                            a {
                                display: block;
                            }
                        }
                        & .location {
                            width: 75%;
                            padding-left: 20px;
                            margin: 0;
                            box-sizing: border-box;
                            float: right;
                        }
                        & .button-red {
                            float: right;
                            width: calc(75% - 20px);
                            box-sizing: border-box;
                            padding: 6px 13px 4px;
                        }
                    }
                }
            }
        }
    }


    /* Profile */
    body {
        & .physician-app.profile {
            & .header {
                float: none;
                margin-bottom: 20px;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: column-reverse;
                min-height: 130px;
                justify-content: flex-end;
                & .media {
                    width: 100%;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    flex-direction: column-reverse;
                    a.video {
                        float: none;
                        margin-top: 20px !important;
                    }
                }
                & .description {
                    width: 50%;
                    box-sizing: border-box;
                    padding-right: 0;
                    padding-top: 10px;
                }
            }
            & .location {
                height: auto !important;
                width: auto;
                float: none;
                border-left: 0;
                padding: 10px 20px;
                box-sizing: border-box;
            }
            & #map-canvas {
                display: none;
            }
            & .details {
                width: auto;
                float: none;
                li {
                    font-size: 16px;
                    line-height: 26px;
                }
            }
        }
    }


    /*--------------------------------------------------------------
    # Network
    --------------------------------------------------------------*/

    .location-network-link {
        display: none;
    }

    body {
        & #c1222 {
            & .content-wrap {
                & .column-wrap {
                    position: relative;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    flex-direction: column-reverse;
                    padding-bottom: 660px;
                    & .column {
                        float: none;
                        width: 100%;
                        margin-bottom: 0;
                        padding-bottom: 0;
                        &:last-child {
                            float: none;
                            width: 100%;
                            margin-bottom: 0;
                            padding-bottom: 0;
                            margin-bottom: 30px;
                            .location-category {
                                position: absolute;
                                bottom: 0;
                                padding-bottom: 30px;
                            }
                        }
                        & .map {
                            img {
                                height: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
    # News
    --------------------------------------------------------------*/

    body .news-list-view .news-list-date {
        margin-bottom: 4px;
        font-size: 13px;
        line-height: 16px;
    }

    /*--------------------------------------------------------------
    # Videos
    --------------------------------------------------------------*/

    body {
        & a.youtube-vimeo-lightbox {
            width: 100% !important;
            margin: 0 !important;
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        & a.local-video-lightbox {
            width: 100% !important;
            margin: 0 !important;
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        & .fancybox-wrap {
            width: 100% !important;
            left: 0 !important;
            padding: 20px;
            box-sizing: border-box;
            .fancybox-inner {
                width: auto !important;
            }
        }
        & .fancybox-overlay {
            iframe {
                width: 100%;
            }
        }
    }


    /*--------------------------------------------------------------
    # Footer
    --------------------------------------------------------------*/
    body {
        & #footer-page {
            margin: 0 -20px;
            padding: 30px 20px !important;
            & .bottom-cap {
                border: 0;
                padding-top: 0 !important;
                .copyright {
                    position: relative;
                    bottom: inherit;
                    right: inherit;
                    margin-top: 20px;
                    font-size: 13px;
                }
                & .footer-id {
                    background-size: contain;
                    span {
                        display: none;
                    }
                }
            }
        }
        & #footer-columns-wrap {
            display: none;
        }
    }
}

/*
  ##Device = iPhone X
  ##Screen = B/w 320px to 812px
*/
@media only screen and (min-device-width : 375px) and (max-device-width : 812px)  and (-webkit-device-pixel-ratio : 3) and (orientation : landscape) {

    /*--------------------------------------------------------------
    # Global
    --------------------------------------------------------------*/

    body {
        margin-bottom: 0 !important;
        & #page-wrap {
            width: auto;
            max-width: 960px;
            padding: 0 20px;
            box-sizing: border-box;
        }
        & blockquote {
            margin: 16px 10px 20px 0 !important;
            padding-bottom: 6px;
        }
        & p {
            font-size: 16px;
            line-height: 26px;
        }
    }


    /*--------------------------------------------------------------
    # Header
    --------------------------------------------------------------*/

    body {
        & #header-page {
            height: auto;
            margin: 20px auto 30px !important;
            position: relative;
            & #identity {
                width: 100%;
                max-width: 230px;
                background-size: contain !important;
                height: 80px !important;
                float: none;
                margin: 85px 0 10px 0 !important;
                display: inline-block;
            }
            & #utility-nav {
                float: none;
                margin: 0 !important;
                position: absolute;
                top: 0;
                right: 0;
                text-align: right;
                li {
                    float: none;
                    display: inline-block;
                    border-right: 0;
                    border-left: 1px solid #c4d3e0;
                    margin-right: 0;
                    margin-left: 13px;
                    padding-right: 0;
                    padding-left: 13px;
                }
            }
            & .search {
                float: none;
                margin: 0;
                width: 50%;
                display: inline-block;
                position: absolute;
                top: 50px;
                right: 0;
                input {
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }
    }


    /* Hamburger Icon */
    body {
        & #mobile-nav-trigger {
            display: block;
            position: relative;
            float: right;
            margin: 116px -10px 0 0px;
            display: inline-block;
            padding: 10px;
            box-sizing: border-box;
            & span {
                width: 30px;
                height: 5px;
                background-color: #155085;
                display: block;
                margin: 4px 0;
                transition: 0.4s;
                position: relative;
                z-index: 20;
            }
        }

        & #mobile-nav-trigger.open {
            &:before {
                content: "";
                display: block;
                background-color: #fff;
                border: 1px solid #155085;
                border-bottom-color: #fff;
                position: absolute;
                top: -6px;
                left: -13px;
                width: 71px;
                height: 70px;
                z-index: 10;
            }

            & span {
                &:first-child {
                    -webkit-transform: rotate(-45deg) translate(-9px, 4px);
                    transform: rotate(-45deg) translate(-9px, 4px);
                }
                &:last-child {
                    -webkit-transform: rotate(45deg) translate(-8px, -4px);
                    transform: rotate(45deg) translate(-8px, -4px);
                }
                &:not(:first-child) {
                    &:not(:last-child) {
                        opacity: 0;
                    }
                }
            }
        }
    }


    /* Mobile Menu */
    body {
        & #global-nav-wrap {
            position: inherit;
            & #section-nav {
                display: none;
            }
            & #global-nav {
                display: none;
            }
            & #mobile-nav {
                display: none;
                position: absolute;
                background-color: rgba(255,255,255,.95);
                border: 1px solid #155085;
                width: calc(100% + 40px);
                margin: 0 -20px;
                box-sizing: border-box;
            }
            & #mobile-nav.active {
                display: block;
            }
        }

        & #mobile-nav {
            & ul {
                width: auto;
                padding: 20px;
            }
            & li {
                display: block;
                float: none;
                width: auto !important;
                border: 0;
                height: auto;
                &:first-child {
                    width: auto;
                    border: 0;
                    a {
                        width: auto;
                        border: 0;
                    }
                }
                & a {
                    text-align: left;
                    color: #155085;
                    padding: 5px 0;
                    border: 0;
                    display: block;
                    height: auto;
                    font-size: 18px;
                    line-height: 22px;
                    font-family: "ff-din-web", Helvetica, Arial, sans-serif;
                    &:hover {
                        color: #155085;
                    }
                    &:focus {
                        color: #155085;
                    }
                }
            }
            & .open {
                & a {
                    height: auto;
                    &:hover {
                        height: auto;
                    }
                    &:focus {
                        height: auto;
                    }
                }
                &:first-child {
                    a {
                        height: auto;
                        &:hover {
                            height: auto;
                        }
                        &:focus {
                            height: auto;
                        }
                    }
                    + {
                        .trigger {
                            border-left: 0;
                        }
                    }
                }
                & + {
                    .trigger {
                        border-left: 0;
                    }
                }
            }
            & .hovered {
                & a {
                    height: auto;
                    &:hover {
                        height: auto;
                    }
                    &:focus {
                        height: auto;
                    }
                }
                &:first-child {
                    a {
                        height: auto;
                        &:hover {
                            height: auto;
                        }
                        &:focus {
                            height: auto;
                        }
                    }
                }
                & + {
                    .trigger {
                        border-left: 0;
                    }
                }
            }
            & .active {
                background-color: transparent;
                border: 0;
                & a {
                    color: #155085;
                    &:hover {
                        color: #155085;
                    }
                    &:focus {
                        color: #155085;
                    }
                }
            }
        }

        #mobilel-nav {
            & .hovered {
                &:first-child {
                    & + {
                        & .trigger {
                            border-left: 0;
                        }
                    }
                }
            }
        }
    }


    /* Submenu */
    body {
        & #mobile-nav {
            & >ul {
                & >li {
                    &:not(.no-submenu) {
                        & >a {
                            position: relative;
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: -webkit-flex;
                            display: flex;
                            &:after {
                                display: inline-block;
                                content: "▼";
                                margin-left: auto;
                                font-size: 14px;
                            }
                        }
                    }
                    &:not(.no-submenu).active {
                        & >a {
                            &:after {
                                content: "▲";
                            }
                            color: #b42c33;
                        }
                        & >ul {
                            display: block;
                        }
                    }
                }
                & li.has-submenu {
                    & >a {
                        position: relative;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        &:after {
                            display: inline-block;
                            content: "▼";
                            margin-left: auto;
                            font-size: 14px;
                        }
                    }
                    & ul {
                        padding-bottom: 1px;
                    }
                }
                & li.has-submenu.active {
                    & >a {
                        &:after {
                            content: "▲";
                        }
                        color: #b42c33;
                    }
                    & ul {
                        display: block;
                    }
                }
            }
            & ul {
                & ul {
                    padding: 0 0 10px 20px;
                    display: none;
                    & a {
                        font-size: 16px;
                        line-height: 18px;
                        text-indent: -10px;
                        padding-left: 10px;
                    }
                    & li {
                        & p {
                            margin-bottom: 0;
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }

    /*--------------------------------------------------------------
    # Images
    --------------------------------------------------------------*/
    body {
        & div.ce-textpic {
            &:not(.ce-textpic-above) {
                &:not(.ce-textpic-below) {
                    &:not(.ce-textpic .ce-right .ce-intext) {
                        &:not(.ce-textpic .ce-right .ce-intext .ce-nowrap) {
                            &:not(.ce-textpic .ce-left .ce-intext) {
                                position: relative;
                                display: -webkit-box;
                                display: -moz-box;
                                display: -ms-flexbox;
                                display: -webkit-flex;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
            & .ce-gallery {
                & figure {
                    display: block;
                }
                & .image {
                    margin-bottom: 20px;
                }
                & img {
                    height: auto;
                    width: 100%;
                    margin-bottom: 0;
                }
            }
            & .ce-gallery {
                display: block;
            }
        }
        & .ce-textpic .ce-right .ce-intext {
            & .ce-gallery {
                padding-top: 0;
            }
        }
        & .ce-textpic .ce-left .ce-intext {
            & .ce-gallery {
                padding-top: 0;
            }
        }
        & .ce-textpic .ce-right .ce-intext .ce-nowrap {
            & .ce-gallery {
                padding-top: 0;
            }
            & .ce-bodytext {
                margin-right: 0;
            }
        }
        & .ce-textpic .ce-left .ce-intext .ce-nowrap {
            .ce-gallery {
                padding-top: 0;
            }
        }
        & figcaption {
            display: block;
            padding-top: 12px;
            font-size: 13px;
            line-height: 18px;
        }
        & .single-image {
            height: auto;
            width: 100%;
            margin-bottom: 0;
        }
        & div.ce-textpic .ce-center {
            & .ce-outer{
                float: none;
                width: auto;
                right: inherit;
            }
            & .ce-inner {
                float: none;
                right: inherit;
            }
            & .ce-gallery {
                width: 100%;
            }
        }
        & div.ce-textpic .ce-right .ce-intext {
            & .ce-gallery {
                margin: 0;
                float: none;
                margin-left: 0;
                float: none;
            }
        }
        & div.ce-textpic .ce-left .ce-intext {
            & .ce-gallery {
                margin-right: 20px;
                width: 100%;
            }
            & p {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 20px;
            }
        }
        & div.ce-textpic .ce-left {
            & .ce-gallery {
                float: none;
                width: 100%;
            }
        }
        & div.ce-textpic .ce-right {
            & .ce-gallery {
                width: 100%;
            }
        }
        & div.ce-textpic .ce-right .ce-intext .ce-nowrap {
            & .ce-gallery {
                margin-left: 0;
                float: none;
            }
        }
        & .ce-textpic .ce-right .ce-intext .ce-nowrap-266 {
            & .ce-bodytext {
                margin-right: 0;
            }
        }
    }


    /*--------------------------------------------------------------
    # Layout
    --------------------------------------------------------------*/

    #generated-content-1 {
        display: none;
    }
    #f1d {
        & #page-wrap {
            #content-wrap {
                width: auto;
                float: none;
            }
        }
    }
    #f2d {
        & #page-wrap {
            #content-wrap {
                width: auto;
                float: none;
            }
            #content-block-1 {
                width: auto;
                float: none;
            }
            #content-block-2 {
                width: auto;
                float: none;
                margin-top: 40px;
            }
        }
    }
    #f3b {
        & #page-wrap {
            #content-wrap {
                float: none;
                margin: 0;
            }
            #content-block-1 {
                width: auto;
                float: none;
                border: 0;
                padding: 0;
                margin: 0;
                min-height: inherit !important;
                border-bottom: 1px solid #CCCCCC;
                margin-bottom: 20px;
            }
            #content-block-2 {
                width: auto;
                float: none;
                border: 0;
                padding: 0;
                margin: 0;
                min-height: inherit !important;
                border-bottom: 1px solid #CCCCCC;
                margin-bottom: 20px;
            }
            #content-block-3 {
                width: auto;
                float: none;
                border: 0;
                padding: 0;
                margin: 0;
                min-height: inherit !important;
            }
        }
    }


    /* Columns */
    body {
        & .column-group {
            & .column {
                &:not(.column-1-c3204) {
                    &:not(.column-2-c3204) {
                        float: none;
                        margin: 0;
                        width: auto;
                    }
                }
                & img {
                    width: 100%;
                    height: auto;
                    // margin: 15px auto 30px;
                    margin: 0;
                }
            }
        }
        & .module-group {
            & .module {
                float: none;
                margin: 0;
                width: auto;
            }
        }
        & .hover-image {
            & .hover-image {
                display: none !important;
            }
        }
    }


    /*--------------------------------------------------------------
    # Feature
    --------------------------------------------------------------*/
    #f1d {
        & #page-wrap {
            & #feature {
                width: auto;
                float: none;
                margin-bottom: 30px;
            }
        }
    }
    #f2d {
        & #page-wrap {
            & #feature {
                width: auto;
                float: none;
                margin-bottom: 30px;
            }
        }
    }
    body {
        & .interior-feature-promo {
            margin-bottom: 0;
            & >.main-image {
                float: none;
                width: 100%;
                height: auto;
                display: block;
            }
            & >.content {
                float: none;
                width: auto;
                padding: 10px 15px;
            }
        }
        & p.feature-text {
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 20px;
        }
        & figcaption {
            margin-top: 0;
            font-size: 11px;
            line-height: 17px;
        }
        & p.caption {
            margin-top: 0;
            font-size: 11px;
            line-height: 17px;
        }
        & .ce-textpic .ce-left .ce-intext .ce-nowrap {
            & .ce-bodytext {
                margin: 0;
            }
        }
    }


    /*--------------------------------------------------------------
    # New Home
    --------------------------------------------------------------*/

    body#f1a {
        & #feature {
            & .flexslider {
                margin: 0 0 20px 0 !important;
                padding-bottom: 70px;
                height: auto;
                & .slides {
                    & a {
                        display: block;
                    }
                    & img {
                        margin: 0;
                    }
                    & .slide-text {
                        padding: 20px 20px 0 20px;
                        border: 0;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        flex-direction: column-reverse;
                        .discription {
                            span {
                                font-size: 17px !important;
                                line-height: 25px;
                            }
                        }
                    }
                    & .more-btn {
                        float: none;
                        width: auto;
                        margin-top: 14px;
                        margin-left: 0;
                        height: auto;
                        & a {
                            font-size: 15px;
                            line-height: 22px;
                            text-align: center;
                            padding-bottom: 6px;
                            padding-top: 11px;
                        }
                    }
                }
                & .flex-control-nav {
                    bottom: inherit !important;
                    top: inherit !important;
                    left: inherit !important;
                    position: relative;
                    padding: 14px;
                    box-sizing: border-box;
                    text-align: center !important;
                    li {
                        margin: 0 6px !important;
                        a {
                            width: 14px !important;
                            height: 14px !important;
                            box-shadow: none;
                        }
                    }
                }

                & .flex-direction-nav {
                    .flex-prev {
                        top: 70px !important;
                    }
                    .flex-next {
                        top: 70px !important;
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
    # Home
    --------------------------------------------------------------*/
    body {
        & #find-physician {
            & h3 {
                font-size: 15px;
                line-height: 25px;
            }
            & .content {
                height: auto;
                min-height: 107px;
                padding-bottom: 30px;
                .button-red {
                    font-size: 15px;
                    line-height: 20px;
                    padding: 10px 20px 6px;
                    margin-bottom: 0;
                    margin-top: 5px;
                }
            }
            & .hasCustomSelect {
                width: 100%;
                height: auto;
                padding: 10px 10px;
                box-sizing: border-box;
                box-shadow: none;
                border: 1px solid #ccc;
                font-size: 13px !important;
                margin-bottom: 10px;
                outline: none;
            }
            & .roundedInput {
                width: 100%;
                height: auto;
                padding: 10px 10px;
                box-sizing: border-box;
                box-shadow: none;
                border: 1px solid #ccc;
                font-size: 13px !important;
                margin-bottom: 10px;
                outline: none;
            }
        }
        & .physician-app.search {
            & .search-wrap {
                & h3 {
                    font-size: 15px;
                    line-height: 25px;
                }
                & .content {
                    height: auto;
                    min-height: 107px;
                    padding-bottom: 30px;
                    .button-red {
                        font-size: 15px;
                        line-height: 20px;
                        padding: 10px 20px 6px;
                        margin-bottom: 0;
                        margin-top: 5px;
                    }
                }
                & .hasCustomSelect {
                    width: 100%;
                    height: auto;
                    padding: 10px 10px;
                    box-sizing: border-box;
                    box-shadow: none;
                    border: 1px solid #ccc;
                    font-size: 13px !important;
                    margin-bottom: 10px;
                    outline: none;
                }
                & .roundedInput {
                    width: 100%;
                    height: auto;
                    padding: 10px 10px;
                    box-sizing: border-box;
                    box-shadow: none;
                    border: 1px solid #ccc;
                    font-size: 13px !important;
                    margin-bottom: 10px;
                    outline: none;
                }
            }
        }
        & .home-promo {
            & img {
                width: 100%;
                height: auto;
            }
            & .home-promo-text {
                & h3 {
                    font-size: 21px;
                    line-height: 27px;
                }
                & p {
                    font-size: 17px;
                    margin-bottom: 1em;
                    line-height: 25px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                & .link {
                    font-size: 16px;
                    line-height: 20px;
                    background-position: 100% 4px;
                    padding-right: 20px;
                }
            }
        }
        & .promo-toggle-btn {
            background-size: 980px auto;
            background-position: 100% 50%;
        }
    }


    /*--------------------------------------------------------------
    # About Us
    --------------------------------------------------------------*/

    body {
        & .column-group {
            & .column-1-c3204 {
                width: 50%;
                margin: 0;
                box-sizing: border-box;
            }
            & .column-2-c3204 {
                width: 50%;
                margin: 0;
                box-sizing: border-box;
                padding-left: 20px;
            }
        }
    }

    /* History */

    body {
        & .history-timeline {
            & .date {
                margin-top: 3px;
                float: none;
            }
            & .description {
                margin-left: 0;
                margin-top: 5px;
            }
            & img {
                width: 100%;
                height: auto;
                display: block;
                padding-left: 0 !important;
            }
            & table {
                & th {
                    display: block;
                    &:last-child {
                        margin-top: 10px;
                    }
                }
                & td {
                    display: block;
                    &:last-child {
                        margin-top: 10px;
                    }
                }
            }
        }
    }


    /* Make a Contribution */
    body {
        & #page-wrap {
            & .bronx-donations {
                & .form-section {
                    margin-bottom: 20px;
                    & fieldset {
                        &:not(.donation) {
                            .column {
                                width: 100% !important;
                            }
                            .column.last {
                                width: 100% !important;
                            }
                        }
                    }
                    & fieldset.donation {
                        .amount-wrap {
                            .column.last {
                                width: 100%;
                            }
                        }
                    }
                }
                & input[type=text], & input[type=email] {
                    box-sizing: border-box;
                    height: 35px;
                }
                & .submit-wrap {
                    margin: 0 auto 30px;
                    & button {
                        width: 100%;
                    }
                }
            }

            & .bronx-donations {
                & .row.two-column-row {
                    & .column {
                        width: 100% !important;
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
    # Physicians
    --------------------------------------------------------------*/

    /* Tabs */
    body {
        & .physician-app.search {
            & .tabs {
                height: auto;
                margin-top: 0;
                li {
                    margin-top: 10px;
                }
            }
            & .search-wrap {
                form {
                    input[type="submit"] {
                        padding: 12px 29px 10px 16px;
                        margin-left: 0;
                        font-size: 16px;
                    }
                }
            }
        }
    }


    /* List */
    body {
        & .physician-app.search {
            & .results-wrap {
                & .results {
                    & li {
                        & >a {
                            &:first-of-type {
                                width: 25%;
                                display: block;
                                position: relative;
                                float: left;
                            }
                        }
                        & img {
                            height: auto;
                            margin-right: 0;
                            width: 100%;
                        }
                        & .placeholder {
                            height: auto;
                            margin-right: 0;
                            width: 100%;
                        }
                        & .description {
                            width: 75%;
                            padding-left: 20px;
                            margin: 0;
                            box-sizing: border-box;
                            float: right;
                            a {
                                display: block;
                            }
                        }
                        & .location {
                            width: 75%;
                            padding-left: 20px;
                            margin: 0;
                            box-sizing: border-box;
                            float: right;
                        }
                        & .button-red {
                            float: right;
                            width: calc(75% - 20px);
                            box-sizing: border-box;
                            padding: 6px 13px 4px;
                        }
                    }
                }
            }
        }
    }


    /* Profile */
    body {
        & .physician-app.profile {
            & .header {
                float: none;
                margin-bottom: 20px;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                flex-direction: column-reverse;
                min-height: 130px;
                justify-content: flex-end;
                & .media {
                    width: 100%;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    flex-direction: column-reverse;
                    a.video {
                        float: none;
                        margin-top: 20px !important;
                    }
                }
                & .description {
                    width: 50%;
                    box-sizing: border-box;
                    padding-right: 0;
                    padding-top: 10px;
                }
            }
            & .location {
                height: auto !important;
                width: auto;
                float: none;
                border-left: 0;
                padding: 10px 20px;
                box-sizing: border-box;
            }
            & #map-canvas {
                display: none;
            }
            & .details {
                width: auto;
                float: none;
            }
        }
    }


    /*--------------------------------------------------------------
    # Network
    --------------------------------------------------------------*/
    .location-network-link {
        display: none;
    }
    body {
        & #c1222 {
            & .content-wrap {
                & .column-wrap {
                    position: relative;
                    display: -webkit-box;
                    display: -moz-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex;
                    flex-direction: column-reverse;
                    padding-bottom: 660px;
                    & .column {
                        float: none;
                        width: 100%;
                        margin-bottom: 0;
                        padding-bottom: 0;
                        &:last-child {
                            float: none;
                            width: 100%;
                            margin-bottom: 0;
                            padding-bottom: 0;
                            margin-bottom: 30px;
                            .location-category {
                                position: absolute;
                                bottom: 0;
                                padding-bottom: 30px;
                            }
                        }
                        & .map {
                            img {
                                height: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
    # News
    --------------------------------------------------------------*/

    body {
        & .news-list-view {
            & .news-list-date {
                margin-bottom: 4px;
                font-size: 13px;
                line-height: 16px;
            }
        }
    }

    /*--------------------------------------------------------------
    # Videos
    --------------------------------------------------------------*/
    body {
        & a.youtube-vimeo-lightbox {
            width: 100% !important;
            margin: 0 !important;
            & img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
        & a.local-video-lightbox {
            width: 100% !important;
            margin: 0 !important;
            & img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }


    /*--------------------------------------------------------------
    # Footer
    --------------------------------------------------------------*/

    body {
        & #footer-page {
            margin: 0 -20px;
            padding: 30px 20px !important;
            & .bottom-cap {
                border: 0;
                padding-top: 0 !important;
                & .copyright {
                    position: relative;
                    bottom: inherit;
                    right: inherit;
                    margin-top: 20px;
                    font-size: 13px;
                }
                & .footer-id {
                    background-size: contain;
                    & span {
                        display: none;
                    }
                }
            }
        }
        & #footer-columns-wrap {
            display: none;
        }
    }

}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1203px
*/
@media only screen and (min-width: 768px) and (max-width: 1203px) and (orientation: landscape) {
    /* Mobile Menu */
    #mobile-nav-trigger,
    #mobile-nav {
        display: none;
    }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1203px
*/
@media only screen and (min-width: 768px) and (max-width: 1203px) and (orientation: portrait) {

    /*--------------------------------------------------------------
    # Global
    --------------------------------------------------------------*/

    body {
        margin-bottom: 0 !important;
        & #page-wrap {
            width: auto;
            max-width: 960px;
            padding: 0 20px;
            box-sizing: border-box;
        }
        & blockquote {
            margin: 16px 10px 20px 0 !important;
            padding-bottom: 6px;
        }
        & p {
            font-size: 16px;
            line-height: 26px;
        }
        & .first-header {
            width: 75%;
        }
        & .csc-firstHeader {
            width: 75%;
        }
    }


    /*--------------------------------------------------------------
    # Header
    --------------------------------------------------------------*/
    body {
        & #header-page {
            height: auto;
            margin: 20px auto 20px !important;
            position: relative;
            & #identity {
                width: 100%;
                max-width: 280px;
                background-size: contain !important;
                height: 96px !important;
                float: none;
                margin: 0;
                display: inline-block;
            }
            & #utility-nav {
                float: none;
                margin: 0;
                position: absolute;
                top: 0;
                right: 0;
                text-align: right;
                width: 50%;
                & li {
                    float: none;
                    display: inline-block;
                    border-right: 0;
                    border-left: 1px solid #c4d3e0;
                    margin-right: 0;
                    margin-left: 13px;
                    padding-right: 0;
                    padding-left: 13px;
                }
            }
            & .search {
                float: none;
                margin: 0;
                width: 100%;
                max-width: 200px;
                display: inline-block;
                position: absolute;
                top: 63px;
                right: 60px;
                & input {
                    width: 100%;
                    box-sizing: border-box;
                }
            }
        }
    }


    /* Hamburger Icon */
    body {
        & #mobile-nav-trigger {
            display: block;
            position: relative;
            float: right;
            margin: 50px -10px 0 0px;
            display: inline-block;
            padding: 10px;
            box-sizing: border-box;
            & span {
                width: 30px;
                height: 5px;
                background-color: #155085;
                display: block;
                margin: 4px 0;
                transition: 0.4s;
                z-index: 20;
                position: relative;
            }

            &.open {
                &:before {
                    content: "";
                    display: block;
                    background-color: #fff;
                    border: 1px solid #155085;
                    border-bottom-color: #fff;
                    position: absolute;
                    top: -6px;
                    left: -8px;
                    width: 58px;
                    height: 78px;
                    z-index: 10;
                }
                & span {
                    &:first-child {
                        -webkit-transform: rotate(-45deg) translate(-9px, 4px);
                        transform: rotate(-45deg) translate(-9px, 4px);
                    }
                    &:last-child {
                        -webkit-transform: rotate(45deg) translate(-8px, -4px);
                        transform: rotate(45deg) translate(-8px, -4px);
                    }
                    &:not(:first-child) {
                        &:not(:last-child) {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }


    /* Mobile Menu */
    body {
        & #global-nav-wrap {
            position: inherit;
            & #section-nav {
                display: none;
            }
            & #global-nav {
                display: none;
            }
            & #mobile-nav {
                display: none;
                position: absolute;
                right: 8px;
                top: 20px;
                background-color: rgba(255,255,255,.95);
                border: 1px solid #155085;
                width: calc(66.66% + 40px);
                margin: 0 -20px;
                box-sizing: border-box;
            }
            & #mobile-nav.active {
                display: block;
            }
        }
        & #mobile-nav {
            & ul {
                width: auto;
                padding: 20px;
            }
            & li {
                display: block;
                float: none;
                width: auto !important;
                border: 0;
                height: auto;
                &:first-child {
                    width: auto;
                    border: 0;
                    a {
                        width: auto;
                        border: 0;
                    }
                }
                & a {
                    text-align: left;
                    color: #155085;
                    padding: 5px 0;
                    border: 0;
                    display: block;
                    height: auto;
                    font-size: 18px;
                    line-height: 22px;
                    font-family: "ff-din-web", Helvetica, Arial, sans-serif;
                    &:hover {
                        color: #155085;
                    }
                    &:focus {
                        color: #155085;
                    }
                }
            }
            & .open {
                & a {
                    height: auto;
                    &:hover {
                        height: auto;
                    }
                    &:focus {
                        height: auto;
                    }
                }
                &:first-child {
                    & a {
                        height: auto;
                        &:hover {
                            height: auto;
                        }
                        &:focus {
                            height: auto;
                        }
                    }
                    & + {
                        .trigger {
                            border-left: 0;
                        }
                    }
                }
                & + {
                    .trigger {
                        border-left: 0;
                    }
                }
            }
            & .hovered {
                & a {
                    height: auto;
                    &:hover {
                        height: auto;
                    }
                    &:focus {
                        height: auto;
                    }
                }
                &:first-child {
                    & a {
                        height: auto;
                        &:hover {
                            height: auto;
                        }
                        &:focus {
                            height: auto;
                        }
                    }
                }
                & + {
                    .trigger {
                        border-left: 0;
                    }
                }
            }
            & .active {
                background-color: transparent;
                border: 0;
                & a {
                    color: #155085;
                    &:hover {
                        color: #155085;
                    }
                    &:focus {
                        color: #155085;
                    }
                }
            }
        }
        & #mobilel-nav {
            & .hovered {
                &:first-child {
                    & + {
                        .trigger {
                            border-left: 0;
                        }
                    }
                }
            }
        }
    }


    /* Submenu */
    body {
        & #mobile-nav {
            & >ul {
                & >li {
                    &:not(.no-submenu) {
                        & >a {
                            position: relative;
                            display: -webkit-box;
                            display: -moz-box;
                            display: -ms-flexbox;
                            display: -webkit-flex;
                            display: flex;
                            &:after {
                                display: inline-block;
                                content: "▼";
                                margin-left: auto;
                                font-size: 14px;
                            }
                        }
                    }
                    &:not(.no-submenu).active {
                        & >a {
                            &:after {
                                content: "▲";
                            }
                            color: #b42c33;
                        }
                        & >ul {
                            display: block;
                        }
                    }
                }
                & li.has-submenu {
                    & >a {
                        position: relative;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        &:after {
                            display: inline-block;
                            content: "▼";
                            margin-left: auto;
                            font-size: 14px;
                        }
                    }
                    & ul {
                        padding-bottom: 1px;
                    }
                }
                & li.has-submenu.active {
                    & >a {
                        &:after {
                            content: "▲";
                        }
                        color: #b42c33;
                    }
                    & ul {
                        display: block;
                    }
                }
            }
            & ul {
                & ul {
                    padding: 0 0 10px 20px;
                    display: none;
                    & a {
                        font-size: 16px;
                        line-height: 18px;
                        text-indent: -10px;
                        padding-left: 10px;
                    }
                    & li {
                        & p {
                            margin-bottom: 0;
                            padding-right: 20px;
                        }
                    }
                }
            }
        }
    }


    /*--------------------------------------------------------------
    # Images
    --------------------------------------------------------------*/

    body div.ce-textpic .ce-gallery img,
    body .single-image {
        height: auto;
        width: 100%;
    }

    /*body div.ce-textpic:not(.ce-textpic-above):not(.ce-textpic-below):not(.ce-textpic .ce-right .ce-intext):not(.ce-textpic .ce-right .ce-intext .ce-nowrap) {
        position: relative;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        align-items: center;
    }

    body .ce-textpic .ce-right .ce-intext .ce-gallery,
    body .ce-textpic .ce-left .ce-intext .ce-gallery,
    body .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery,
    body .ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery {
        padding-top: 0;
    }

    body div.ce-textpic .ce-gallery figure,
    body div.ce-textpic figure.ce-gallery {
        display: block;
    }

    body div.ce-textpic .ce-gallery .image {
        margin-bottom: 20px;
    }

    body figcaption.ce-textpic-caption {
        display: block;
        padding-top: 12px;
        font-size: 13px;
        line-height: 18px;
    }

    body div.ce-textpic .ce-gallery img,
    body .single-image {
        height: auto;
        width: 100%;
        margin-bottom: 0;
    }

    body div.ce-textpic .ce-center .ce-textpic .ce-outer{
        float: none;
        width: auto;
        right: inherit;
    }

    body div.ce-textpic .ce-center .ce-textpic .ce-inner {
        float: none;
        right: inherit;
    }

    body div.ce-textpic .ce-right .ce-intext .ce-gallery {
        margin: 0;
        float: none;
    }

    body div.ce-textpic .ce-left .ce-intext .ce-gallery {
        margin-right: 20px;
    }

    body div.ce-textpic .ce-left .ce-intext p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    body div.ce-textpic-left .ce-gallery {
        float: none;
        width: 100%;
    }

    body div.ce-textpic-right .ce-gallery {
        width: 100%;
    }

    body div.ce-textpic .ce-center .ce-gallery {
        width: 100%;
    }

    body div.ce-textpic .ce-right .ce-intext .ce-gallery,
    body div.ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
        margin-left: 0;
        float: none;
    }

    body .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-bodytext,
    body .ce-textpic .ce-right .ce-intext .ce-nowrap-266 .ce-bodytext {
        margin-right: 0;
    }*/

    /*--------------------------------------------------------------
    # Layout
    --------------------------------------------------------------*/

    #generated-content-1 {
        display: none;
    }

    /*--------------------------------------------------------------
    # New Home
    --------------------------------------------------------------*/

    body#f1a {
        & .column-1-c3172 {
            width: calc(75% - 15px);
            margin-right: 15px;
            & .flexslider {
                height: auto;
                & .slides {
                    & li {
                        & >a {
                            display: block;
                        }
                    }
                    & img {
                        width: 100%;
                        height: auto;
                    }
                    & .slide-text {
                        padding: 20px;
                        border: 0;
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        flex-direction: column-reverse;
                    }
                }
                & .slide-text {
                    & .discription {
                        margin-top: 13px;
                    }
                    & .more-btn {
                        float: none;
                        width: auto;
                        margin-top: 14px;
                        margin-left: 0;
                        height: auto;
                    }
                }
                & .flex-control-nav {
                    bottom: 106px !important;
                    left: 17px !important;
                    top: inherit !important;
                }
            }
        }
        & .column-2-c3172 {
            width: 25%;
        }
    }


    /* Columns */

    body {
        & .home-promo {
            & .home-promo-text {
                padding: 0;
                & h3 {
                    font-size: 15px;
                    line-height: 18px;
                }
                & p {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
        & .promo-toggle-btn {
            background-size: 980px auto;
            background-position: 100% 50%;
        }

        & #f1a {
            & #c4036 {
                & .column-group {
                    margin: 0 -10px;
                }
                & .column {
                    width: calc(25% - 20px);
                    margin: 0 10px;
                    margin-bottom: 20px;
                }
            }
            & #c816 {
                & .column-group {
                    margin: 0 -10px;
                }
                & .column {
                    width: calc(25% - 20px);
                    margin: 0 10px;
                    margin-bottom: 20px;
                }
            }
        }
    }


    /*--------------------------------------------------------------
    # Home
    --------------------------------------------------------------*/
    body {
        & #c811 {
            & .column {
                width: calc(25% - 12px);
            }
            & .column.column-4-c811 {
                margin-right: 0;
                width: 25%;
            }
        }
        & .home-promo {
            & img {
                width: 100%;
                height: auto;
            }
        }
        & #c823 {
            & img {
                width: 100%;
                height: auto;
            }
        }
        & #find-physician {
            & .content {
                height: auto;
            }
            & .hasCustomSelect {
                width: 100%;
                height: auto;
                padding: 6px;
                box-sizing: border-box;
                box-shadow: none;
                border: 1px solid #ccc;
                font-size: 13px !important;
                margin-bottom: 10px;
                outline: none;
            }
            & .roundedInput {
                width: 100%;
                height: auto;
                padding: 6px;
                box-sizing: border-box;
                box-shadow: none;
                border: 1px solid #ccc;
                font-size: 13px !important;
                margin-bottom: 10px;
                outline: none;
            }
        }
        & .physician-app.search {
            & .search-wrap {
                & .content {
                    height: auto;
                }
                & .hasCustomSelect {
                    width: 100%;
                    height: auto;
                    padding: 6px;
                    box-sizing: border-box;
                    box-shadow: none;
                    border: 1px solid #ccc;
                    font-size: 13px !important;
                    margin-bottom: 10px;
                    outline: none;
                }
                & .roundedInput {
                    width: 100%;
                    height: auto;
                    padding: 6px;
                    box-sizing: border-box;
                    box-shadow: none;
                    border: 1px solid #ccc;
                    font-size: 13px !important;
                    margin-bottom: 10px;
                    outline: none;
                }
            }
        }
    }


    /*body #find-physician h3,
    body .physician-app.search .search-wrap h3 {
        font-size: 15px;
        line-height: 25px;
    }


    body #find-physician .hasCustomSelect,
    body .physician-app.search .search-wrap .hasCustomSelect,
    body #find-physician .roundedInput,
    body .physician-app.search .search-wrap .roundedInput {
        width: 100%;
        height: auto;
        padding: 10px 10px;
        box-sizing: border-box;
        box-shadow: none;
        border: 1px solid #ccc;
        font-size: 13px !important;
        margin-bottom: 10px;
        outline: none;
    }

    body #find-physician .content .button-red,
    body .physician-app.search .search-wrap .content .button-red {
        font-size: 15px;
        line-height: 20px;
        padding: 10px 20px 6px;
        margin-bottom: 0;
        margin-top: 5px;
    }


    body .home-promo .home-promo-text h3 {
        font-size: 21px;
        line-height: 27px;
    }

    body .home-promo .home-promo-text p {
        font-size: 17px;
        margin-bottom: 1em;
        line-height: 25px;
    }

    body .home-promo .home-promo-text p:last-child {
        margin-bottom: 0;
    }

    body .home-promo .home-promo-text .link {
        font-size: 16px;
        line-height: 20px;
        background-position: 100% 4px;
        padding-right: 20px;
    }*/

    /*--------------------------------------------------------------
    # About Us
    --------------------------------------------------------------*/

    /* History */
    body {
        & .history-timeline {
            & .date {
                margin-top: 3px;
                float: none;
            }
            & .description {
                margin-left: 0;
                margin-top: 5px;
            }
            & img {
                width: 100%;
                height: auto;
                display: block;
                padding-left: 0 !important;
            }
            & table {
                & th {
                    display: block;
                    &:last-child {
                        margin-top: 10px;
                    }
                }
                & td {
                    display: block;
                    &:last-child {
                        margin-top: 10px;
                    }
                }
            }
        }
    }


    /* Make a Contribution */
    body {
        & #page-wrap {
            & .bronx-donations {
                & .form-section {
                    margin-bottom: 20px;
                    & fieldset {
                        &:not(.donation) {
                            .column {
                                width: 100% !important;
                            }
                            .column.last {
                                width: 100% !important;
                            }
                        }
                    }
                    & fieldset.donation {
                        & .amount-wrap {
                            & .column.last {
                                width: 100%;
                            }
                        }
                    }
                }
                & input[type=text] {
                    box-sizing: border-box;
                    height: 35px;
                }
                & input[type=email] {
                    box-sizing: border-box;
                    height: 35px;
                }
                & .submit-wrap {
                    margin: 0 auto 30px;
                    button {
                        width: 100%;
                    }
                }

                & .row.two-column-row {
                    .column {
                        width: 100% !important;
                    }
                }

            }
        }

    }


    /*--------------------------------------------------------------
    # Physicians
    --------------------------------------------------------------*/

    /* Profile */
    body .physician-app.profile .header .media img.photo {
        margin-top: 0;
    }

    /*--------------------------------------------------------------
    # Network
    --------------------------------------------------------------*/

    /*.location-network-link {
        display: none;
    }

    body #c1222 .content-wrap .column-wrap {
        position: relative;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column-reverse;
        padding-bottom: 660px;
    }

    body #c1222 .content-wrap .column-wrap .column,
    body #c1222 .content-wrap .column-wrap .column:last-child {
        float: none;
        width: 100%;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    body #c1222 .content-wrap .column-wrap .column:last-child {
        margin-bottom: 30px;
    }

    body #c1222 .content-wrap .column-wrap .column:last-child .location-category {
        position: absolute;
        bottom: 0;
        padding-bottom: 30px;
    }

    body #c1222 .content-wrap .column-wrap .column:first-child {
    }

    body #c1222 .content-wrap .column-wrap .column .map {
    }

    body #c1222 .content-wrap .column-wrap .column .map img {
        height: auto;
        width: 100%;
    }*/

    /*--------------------------------------------------------------
    # News
    --------------------------------------------------------------*/

    /*body .news-list-view .news-list-date {
        margin-bottom: 4px;
        font-size: 13px;
        line-height: 16px;
    }*/

    /*--------------------------------------------------------------
    # Videos
    --------------------------------------------------------------*/

    /*body a.youtube-vimeo-lightbox,
    body a.local-video-lightbox {
        width: 100% !important;
        margin: 0 !important;
    }

    body a.youtube-vimeo-lightbox img,
    body a.local-video-lightbox img {
        width: 100%;
        height: auto;
        display: block;
    }*/

    /*--------------------------------------------------------------
    # Footer
    --------------------------------------------------------------*/

    body {
        & #footer-page {
            margin: 0 -20px;
            & #footer-columns-inner-wrap {
                max-width: inherit;
                width: 75%;
            }
            & #footer-contact {
                width: calc(25% - 21px);
                margin-bottom: 40px;
            }
        }
        & .column-1-c59 {
            width: 25%;
            margin-right: 10px !important;
        }
        & .column-2-c59 {
            width: 20%;
            margin-right: 34px !important;
        }
        & .column-3-c59 {
            width: 15%;
            margin-right: 34px !important;
        }
        & .column-4-c59 {
            width: 15%;
            margin-right: 0 !important;
        }
    }


}
