		//  =========================================
		//	DO NOT EDIT THIS FILE!!!!.
		//	This contains base functions and mixins. Add site specific
		//	functions and mixins in the SiteFunctions.scss file
		//  =========================================


		//	------------------------------------------
		//	CALCUATES FONT SIZE
		//	$font-size-target is the size in pixels you want rendered
		//	$font-size-base is the size in pixels the item inherits
		//	$unit is the type of unit to assign to output. Possible values for are:
		//		em (default)
		//		percent
		//		0 (makes a pure number value without a unit used only in line-heights)
		//	------------------------------------------


@function font-calc($font-size-target, $font-size-base: $base-font-size, $unit: rem) {
	@if $unit == percent {
		@return $font-size-target/$font-size-base*100%;
	} @else {
		@return $font-size-target/$font-size-base + $unit;
	}
}


		//  ------------------------------------------
		//	CALCUATES WIDTH OF AN ITEM in percentage to its parent
		//	$item-width is the size in pixels of the item
		//	$container-width is the size in pixels of the element containing your item
		//  ------------------------------------------


@function item-width($item-width, $container-width) {
	@return $item-width/$container-width*100%;
}




		//	------------------------------------------
		//	USED FOR GRID SYSTEM DESIGNS. Calcuates width of
		//	an item in percentage to its parent based on the number
		//	of columns it takes up in the grid
		//	$item-columns is the number of columns the item spans
		//	$container-columns is the number of columns that the containing item spans
		//	$column-width is the width in pixels of one entire column including the gutter
		//	$gutter-width is the width in pixels of the gutter between columns
		//	------------------------------------------


@function item-width-grid ($item-columns, $container-columns) {
	@return ((($column-width + $gutter-width) * $item-columns) - $gutter-width)/((($column-width + $gutter-width) * $container-columns) - $gutter-width)*100%;
}

		//	------------------------------------------
		//	Following function and mixin is for creating properties
		//	using rem units with fallback for IE8 & 9
		//	Found at http://hugogiraudel.com/2013/03/18/ultimate-rem-mixin/
		//	------------------------------------------

@function parseInt($n) {
	@return $n / ($n * 0 + 1);
}

@mixin rem($property, $values) {
	$px : ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto {
			$px : append($px , $value);
			$rem: append($rem, $value);
		}

		@else {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == "px" {
				$px : append($px,  $value);
				$rem: append($rem, ($val / $base-rhythm-raw + rem));
			}

			@if $unit == "rem" {
				$px : append($px,  ($val * $base-rhythm-raw + px));
				$rem: append($rem, $value);
			}
		}

		@if $px == $rem {
			#{$property}: $px;
		} @else {
			#{$property}: $px;
			#{$property}: $rem;
		}
	}
}


@mixin block-typography ($font-size) {
	@include rem(font-size, map-get(map-get($font-levels, $font-size), font-size));
	@include rem(line-height, map-get(map-get($font-levels, $font-size), line-height));
	@include rem(margin-bottom, map-get(map-get($font-levels, $font-size), margin-bottom));
	@include rem(margin-top, map-get(map-get($font-levels, $font-size), margin-top));
}

@mixin headline-setup () {
	font-family: $headline-font;
	font-weight: $headline-font-weight;
}