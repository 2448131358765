.fancybox-content {
    width: 1280px;
    max-width: 95% !important;
    padding: 25px !important;
    overflow: hidden !important;
}

.fancybox-slide--html .fancybox-close-small {
    right: -7px !important;
    top: -6px !important;
  }
  

.video-teaser {

    .card-img-top {
        width: 100%;
    }

    a {
        cursor: pointer;
    }

    &__info {
        //background-color: var(--theme-color-2);
        padding: 10px 15px;
        color: white;
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    // &__overlay{
    //    /* background: linear-gradient(
    //             to top,
    //             #242424,
    //             rgba(255, 0, 0, 0)
    //     );
    //     position: absolute;
    //     top: 0;
    //     height: 100%;
    //     */
    //     background: linear-gradient(
    //             to top,
    //             #000000,
    //             rgba(0, 0, 0, 0)
    //     );
    //     position: absolute;
    //     bottom: 0;
    //     height: 25%;
    //     width: 100%;
    // }
    &__title {
        float: left;

    }
    &__time {
        float: right;
    }

    &__video {
        .video-teaser--lightbox & {
            display: none;
        }
        & > video,  
        & > iframe {
            max-width: 100%;
        }
    }

    &__video-wrap {
        position: relative;
    }

    &__play-arrow {
        background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/play_button.png) no-repeat scroll left top;
        height: 31px;
        left: 50%;
        margin-left: -16px;
        margin-top: -16px;
        position: absolute;
        top: 50%;
        width: 31px;

    }



}


#player-in-lightbox {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}

.fancybox-wrap .fancybox-outer,
.fancybox-wrap .fancybox-inner {
	position: relative !important;
}
