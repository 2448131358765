		//  =========================================
		//	DO NOT EDIT THIS FILE!!!!.
		//	This establishes base settings. Use SASS files in the
		//	skin to change these values by overriding them.
		//  =========================================


		//  =========================================
		//	Typoography helpers
		//  =========================================

.align-right,
p.align-right,
h1.align-right,
h2.align-right,
h3.align-right,
h4.align-right,
h5.align-right,
h6.align-right,
.text-right,
p.text-end,
h1.text-end,
h2.text-end,
h3.text-end,
h4.text-end,
h5.text-end,
h6.text-end {
	text-align: right;
}

.align-left,
p.align-left,
h1.align-left,
h2.align-left,
h3.align-left,
h4.align-left,
h5.align-left,
h6.align-left,
.text-left {
	text-align: left;
}


.align-center,
p.align-center,
h1.align-center,
h2.align-center,
h3.align-center,
h4.align-center,
h5.align-center,
h6.align-center,
.text-center,
p.text-center,
h1.text-center,
h2.text-center,
h3.text-center,
h4.text-center,
h5.text-center,
h6.text-center  {
	text-indent: 0;
	text-align: center;
}

.align-justify,
p.align-justify,
h1.align-justify,
h2.align-justify,
h3.align-justify,
h4.align-justify,
h5.align-justify,
h6.align-justify {
	text-align: justify;
}

.indent {
	margin-left: $indent-1;
}

.line-after,
p.line-after {
	@include rem(margin-bottom, $base-rhythm);
}

.line-after-half,
p.line-after-half {
	@include rem(margin-bottom, $base-rhythm * .5);
}

.line-before,
p.line-before {
	@include rem(margin-top, $base-rhythm);
}

.line-before-half,
p.line-before-half {
	@include rem(margin-top, $base-rhythm * .5);
}

// @todo-fluid The markup will change due to switching to Fluid.  We can probably lose the csc-firstHeader but we likely need some Fluid code for first-header generation
.first-header,
.csc-firstHeader {
	margin-top: 0;
}

p.first-paragraph {
	text-indent: 0;
}

p.caption {
	@extend figcaption;
}

		//  =========================================
		//	Other helpers
		//  =========================================

.clear {
	clear: both;
}

.clear-only {
	clear: both;
	height: 0px;
	margin: 0;
	padding: 0;
	overflow: hidden;
	line-height: 0;
	font-size: 0;
}

		//  Clearfix can be added to other style rules via:
		//  #foobar {
		//      @extend .clearfix;
		//  }

.clearfix:before,
.clearfix:after {
	content: '.';
	display: block;
	overflow: hidden;
	visibility: hidden;
	font-size: 0;
	line-height: 0;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both
}
		/* @todo-ron may be able to eliminate the clearfix zoom once ie7 drops off support */
.clearfix {
  zoom: 1
 }

		//  =========================================
		//	Additional helper classes
		//  =========================================

.out-of-sight {
	position: absolute;
	top: 0;
	left: -4000px;
}

.graphic-text-wrap {
	overflow: hidden;
}

.graphic-text {
	line-height:300px;
	display: block;
}

.bleed {
	padding: 0;
}
