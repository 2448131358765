
		//  ------------------------------------------
		//	Styling to override default TYPO3 styles
		//  ------------------------------------------

.frame-ruler-after {
	border-bottom: 1px solid $gray-20;
}

.frame-ruler-before {
	border-top: 1px solid $gray-20;
}

.textpic__image-wrap {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    display: table;
}

.ce-right .ce-intext .ce-gallery,
.ce-left .ce-intext .ce-gallery,
.ce-right .ce-intext .ce-nowrap .ce-gallery,
.ce-left .ce-intext .ce-nowrap .ce-gallery {
	padding-top: 4px;
	margin-bottom: 0px;
}

div.ce-textpic .ce-gallery .ce-textpic-image {
	margin-bottom: 10px;
}

div.ce-left .ce-intext .ce-gallery, div.ce-left .ce-intext .ce-nowrap .ce-gallery {
	margin-right: 10px;
}

.ce-textpic-above .ce-gallery {
	margin-bottom: 0px;
}

.textpic--image-size-small .textpic__image-wrap {
    width: 30%;
}

.textpic--image-size-medium .textpic__image-wrap {
    width: 60%;
}



@include phone-large {
    .textpic--image-in-text-right {
        .textpic__image-wrap {
            float: right;
            margin-left: 26px;
            width: 45%;
        }
        &.textpic--image-size-small {
            .textpic__image-wrap {
                width: 25%;
            }
        }
        &.textpic--image-size-medium {
            .textpic__image-wrap {
                width: 35%;
            }
        }
    }

    .textpic--image-in-text-left {
        .textpic__image-wrap {
            float: left;
            margin-right: 26px;
            width: 45%;
        }
        &.textpic--image-size-small {
            .textpic__image-wrap {
                width: 25%;
            }
        }
        &.textpic--image-size-medium {
            .textpic__image-wrap {
                width: 35%;
            } 
        }
    }
}

.textpic--image-above-center .textpic__image-wrap {
    width: 100%;
}

.textpic--image-above-center .textpic__image-wrap img {
    width: 100%;
}

@include tablet-small {
    .textpic--image-beside-text-left,
    .textpic--image-beside-text-right {
        display: flex;
        .textpic__image-wrap {
            min-width: 45%;
        }
        &.textpic--image-size-small {
            .textpic__image-wrap {
                min-width: 25%;
            }
        }
        &.textpic--image-size-medium {
            .textpic__image-wrap {
                min-width: 35%;
            }
        }
    }
    .textpic--image-beside-text-left {
        .common-ce__text-wrap {
            padding-left: 20px;
        }
    }
    .textpic--image-beside-text-right {
        flex-direction: row-reverse;
        .common-ce__text-wrap {
            flex-grow: 1;
            padding-right: 20px;
        }
    }
}

.common-image {
    img {
        width: 100%;
        max-width: 100%;
    }
}
