		//  =========================================
		//	DO NOT EDIT THIS FILE!!!!.
		//	This establishes base RTE styles. Use SASS files in the
		//	skin to change these values by overriding them.
		//  =========================================

th, td {
	&.width-10 {
		width: 10%;
	}

	&.width-20 {
		width: 20%;
	}

	&.width-30 {
		width: 30%;
	}

	&.width-40 {
		width: 40%;
	}

	&.width-50 {
		width: 50%;
	}

	&.width-60 {
		width: 60%;
	}

	&.width-70 {
		width: 70%;
	}

	&.width-80 {
		width: 80%;
	}

	&.width-90 {
		width: 90%;
	}
}

h1, h2, h3, h4, h5, h6, p, ul, ol {
	&.no-margin-bottom {
		margin-bottom: 0px;
	}

	&.highlight {
		color: $highlight-color;
	}

	&.feature-text {
		font-style: italic;
		font-size: 18px;
	}
}

span, b, strong, i, em {
	&.no-wrap {
		white-space: nowrap;
	}

	&.highlight {
		color: $highlight-color;
	}
}

