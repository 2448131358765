		//  ------------------------------------------
		//	Styles for custom content elements
		//  ------------------------------------------


.sidebar-container {
	padding: 15px;
	background-color: $color-1b;
}

.interior-feature-promo {

	@extend .clearfix;

	margin-bottom: 20px;
	background-color: $color-1b;
	@include border-top-right-radius(25px);

    display: flex;
    align-items: stretch;
    flex-direction: column;

    @media only screen and (min-width: 767px) {
        flex-direction: row;
    }

        & > .main-image {
		//float: left;
            width: 100%;
            @media only screen and (min-width: 767px) {
                width: calc(100% - 235px);
            }

        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
	}
	& > .content {
		//float: right;
		width: 205px;
		//padding: 15px 15px 15px 0;
		padding: 15px;
	}
}

.hover-image {
	position: relative;

	.base-image {
		display: block;
	}

	.hover-image {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
	}

	&:hover {
		.hover-image {
			display: block;
		}
	}
}

.history-timeline {

	padding: 17px 20px 4px;
	margin-bottom: 8px;
	background-color: #ecf1f5;

	.date {
		float: left;
		margin-top: -7px;
		font-family: $font-2;
		font-style: italic;
		color: $color-2;
		font-size: 30px;
		line-height: 30px;
	}

	.description {
		margin-left: 90px;
	}

}

.news-list-view {

	.teaser-text {
		padding-bottom: 15px;
		border-bottom: 1px solid $color-2b;
		margin-bottom: 15px;

		p {
			margin-bottom: 0px;
		}
	}

	.news-list-date {
		// font-family: $font-2;
		font-weight: 600;
		font-size: 11px;
		color: #4D4D4D;
		margin-bottom: -3px;
	}

	h3 {
		margin-bottom: 4px;

		a {
			font-family: $font-1;
			font-size: 15px;
			line-height: 20px;
		}
	}
}

.news-single {

	h3 {
		color: $color-1;
		font-size: 25px;
		line-height: 28px;
		font-weight: 600;
	}

	.news-list-author,
	.news-list-date {
		font-weight: bold;
	}

	.news-img-wrap {
		float: left;
		margin: 5px 15px 10px 0;
	}

	.news-text-wrap {
		margin-bottom: 15px;
	}

	.news-backlink-wrap {
		margin-bottom: 15px;
	}
}

.news-backlink-wrap {
	@extend .clearfix;
	clear: both;
}

.page-navigation {

	p {
		float: left;
		margin-right: 20px;
	}

	ul {
		list-style: none;
		float: left;

		li {
			font-size: 12px;
			line-height: 19px;
			float: left;
			margin-right: 10px;
		}
	}
}

.sidebar-container {
	border-radius: 6px;
	padding-bottom: 40px;
	@extend .clearfix;

	p {
		color: $color-2;
		font-weight: bold;
		font-size: 12px;
		line-height: 18px
	}

	.button-red {
		margin-top: 10px;
		display: block;
		float: left;
	}
}

#content-block-1 #tx-solr-search {
	width: auto;
	float: none;

	.results-teaser {
		position: relative;
	}

	.results-list {
		margin-left: 0px;
	}

	.relevance {
		position: absolute;
		right: 0px;
		top: -24px;

		.tx-solr-relevance {
			background-color: $color-2;
		}
	}
}
