// ====================================================================
// NEWS
// ====================================================================


.news-list-view {
    .f3-widget-paginator {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
    }
  
    .f3-widget-paginator li {
        margin-left: 10px;
    }
    
    .f3-widget-paginator {
        justify-content: flex-start;
    }
}