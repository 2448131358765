@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, p.caption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, p.caption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

@font-face {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/ProximaNova/2FEDD3_2_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/ProximaNova/2FEDD3_2_0.woff2") format("woff2"), url("../Fonts/ProximaNova/2FEDD3_2_0.woff") format("woff"), url("../Fonts/ProximaNova/2FEDD3_2_0.ttf") format("truetype");
}
@font-face {
  font-family: "proxima-nova";
  font-style: italic;
  font-weight: 400;
  src: url("../Fonts/ProximaNova/2FEDD3_3_0.eot");
  src: url("../Fonts/ProximaNova/2FEDD3_3_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/ProximaNova/2FEDD3_3_0.woff2") format("woff2"), url("../Fonts/ProximaNova/2FEDD3_3_0.woff") format("woff"), url("../Fonts/ProximaNova/2FEDD3_3_0.ttf") format("truetype");
}
@font-face {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 600;
  src: url("../Fonts/ProximaNova/2FEDD3_4_0.eot");
  src: url("../Fonts/ProximaNova/2FEDD3_4_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/ProximaNova/2FEDD3_4_0.woff2") format("woff2"), url("../Fonts/ProximaNova/2FEDD3_4_0.woff") format("woff"), url("../Fonts/ProximaNova/2FEDD3_4_0.ttf") format("truetype");
}
@font-face {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 700;
  src: url("../Fonts/ProximaNova/2FEDD3_0_0.eot");
  src: url("../Fonts/ProximaNova/2FEDD3_0_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/ProximaNova/2FEDD3_0_0.woff2") format("woff2"), url("../Fonts/ProximaNova/2FEDD3_0_0.woff") format("woff"), url("../Fonts/ProximaNova/2FEDD3_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "proxima-nova";
  font-style: italic;
  font-weight: 700;
  src: url("../Fonts/ProximaNova/2FEDD3_1_0.eot");
  src: url("../Fonts/ProximaNova/2FEDD3_1_0.eot?#iefix") format("embedded-opentype"), url("../Fonts/ProximaNova/2FEDD3_1_0.woff2") format("woff2"), url("../Fonts/ProximaNova/2FEDD3_1_0.woff") format("woff"), url("../Fonts/ProximaNova/2FEDD3_1_0.ttf") format("truetype");
  font-display: swap;
}
html {
  font-size: 162.5%;
}

body {
  background-color: white;
  color: #333333;
  font-family: "Source Sans 3", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-size: 0.5rem;
  line-height: 20px;
  line-height: 0.7692307692rem;
  margin-bottom: 9px;
  margin-bottom: 0.3461538462rem;
  margin-top: 0;
}

a {
  color: #155085;
  text-decoration: none;
}
a:hover, a:focus {
  color: #b42c33;
  border-bottom: 1px dotted #b42c33;
}

p {
  font-size: 13px;
  font-size: 0.5rem;
  line-height: 20px;
  line-height: 0.7692307692rem;
  margin-bottom: 9px;
  margin-bottom: 0.3461538462rem;
  margin-top: 0;
  text-indent: 30px;
}
p.small {
  font-size: 13px;
  font-size: 0.5rem;
  line-height: 26px;
  line-height: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  text-indent: 0;
}
p.x-small {
  font-size: 11px;
  font-size: 0.4230769231rem;
  line-height: 13px;
  line-height: 0.5rem;
  margin-bottom: 0;
  margin-top: 0;
  text-indent: 0;
}
p.large {
  font-size: 19px;
  font-size: 0.7307692308rem;
  line-height: 26px;
  line-height: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  text-indent: 0;
}
p.x-large {
  font-size: 21px;
  font-size: 0.8076923077rem;
  line-height: 26px;
  line-height: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  text-indent: 0;
}

h1 {
  font-size: 25px;
  font-size: 0.9615384615rem;
  line-height: 28px;
  line-height: 1.0769230769rem;
  margin-bottom: 9px;
  margin-bottom: 0.3461538462rem;
  margin-top: 0;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: bold;
}

h2 {
  font-size: 20px;
  font-size: 0.7692307692rem;
  line-height: 22px;
  line-height: 0.8461538462rem;
  margin-bottom: 9px;
  margin-bottom: 0.3461538462rem;
  margin-top: 0;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: bold;
}

h3 {
  font-size: 17px;
  font-size: 0.6538461538rem;
  line-height: 20px;
  line-height: 0.7692307692rem;
  margin-bottom: 9px;
  margin-bottom: 0.3461538462rem;
  margin-top: 0;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: bold;
}

h4 {
  font-size: 18px;
  font-size: 0.6923076923rem;
  line-height: 26px;
  line-height: 1rem;
  margin-bottom: 26px;
  margin-bottom: 1rem;
  margin-top: 26px;
  margin-top: 1rem;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: bold;
}

h5 {
  font-size: 18px;
  font-size: 0.6923076923rem;
  line-height: 26px;
  line-height: 1rem;
  margin-bottom: 26px;
  margin-bottom: 1rem;
  margin-top: 26px;
  margin-top: 1rem;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: bold;
}

h6 {
  font-size: 18px;
  font-size: 0.6923076923rem;
  line-height: 26px;
  line-height: 1rem;
  margin-bottom: 26px;
  margin-bottom: 1rem;
  margin-top: 26px;
  margin-top: 1rem;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: bold;
}

strong, b {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

ul, ol {
  margin: 0 0 26px 16px;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

dl {
  margin-bottom: 26px;
}

dl dt {
  font-weight: bold;
}

dl dd {
  margin-left: 16px;
}

blockquote {
  margin-right: 16px;
  margin-left: 16px;
  margin-top: 26px;
  margin-top: 1rem;
  margin-bottom: 26px;
  margin-bottom: 1rem;
}
blockquote p {
  text-indent: 0;
}

nav ul {
  margin: 0;
  list-style: none;
}

table {
  width: 100%;
}

code,
pre,
kbd,
samp {
  font-family: Monaco, Andale Mono, Courier New, monospace;
}

figcaption, p.caption {
  font-size: 11px;
  font-size: 0.4230769231rem;
  line-height: 13px;
  line-height: 0.5rem;
  margin-bottom: 0;
  margin-top: 0;
  font-style: italic;
  margin-top: 3px;
}

sup {
  font-size: 0.85em;
  vertical-align: super;
}

sub {
  font-size: 0.85em;
  vertical-align: sub;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before {
  content: open-quote;
}

q:after {
  content: close-quote;
}

cite,
var {
  font-style: italic;
}

ins {
  text-decoration: underline;
}

.responsive-image img {
  width: 100%;
}

a {
  font-weight: 700;
}
a:hover {
  border: none;
}

p {
  text-indent: 0;
  font-size: 16px;
  line-height: 26px;
}
p a:hover {
  text-decoration: underline;
}

li {
  font-size: 16px;
  line-height: 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #b42c33;
}

h1 + h2.alternate,
header + h2.alternate,
.main-headline + .frame-default header:first-child h2.alternate {
  margin-top: -4px;
}

blockquote {
  color: #b42c33;
  border-top: 1px solid #e1abad;
  border-bottom: 1px solid #e1abad;
  margin: 9px 10px 9px 0 !important;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
}
blockquote p {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 9px;
}
blockquote p:first-child {
  text-indent: -7px;
}
blockquote p.caption {
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 9px;
  color: #b42c33;
}

/* div.ce-textpic-above .ce-textpic-image,
div.ce-textpic-below .ce-textpic-image
*/
/*
div.ce-textpic .ce-gallery .ce-textpic-image {
	margin-top: $base-rhythm * .5;
	margin-bottom: $base-rhythm * .5;
}

div.ce-textpic-imagecolumn,
td.ce-textpic-imagecolumn .ce-textpic-image m{
	margin-right: $base-rhythm * .5;
}
*/
.ce-gallery {
  margin-bottom: 13px;
}

.ce-textpic-below .ce-gallery {
  margin-top: 26px;
}

.ce-textpic .ce-left .ce-intext .ce-gallery,
.ce-textpic .ce-right .ce-intext .ce-gallery,
div.ce-textpic .ce-right .ce-intext .ce-gallery .ce-textpic-last,
div.ce-textpic .ce-left .ce-intext .ce-gallery .ce-textpic-last {
  margin-top: 0;
  margin-bottom: 0;
}

div.ce-textpic .ce-left .ce-intext .ce-gallery, div.ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery {
  margin-right: 26px;
}

div.ce-textpic .ce-right .ce-intext .ce-gallery, div.ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
  margin-left: 26px;
}

.frame-ruler-after {
  border-bottom: 1px solid black;
  margin-bottom: 15px;
}

.frame-ruler-before {
  padding-top: 10px;
  border-top: 1px solid black;
}

.ce-textpic .ce-left .ce-intext .ce-nowrap, .ce-textpic .ce-right .ce-intext .ce-nowrap {
  display: flex;
}
.ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery, .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
  flex-basis: 45%;
  max-width: 45%;
  margin-right: 4%;
}
.ce-textpic .ce-left .ce-intext .ce-nowrap .ce-bodytext, .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-bodytext {
  flex-basis: 45%;
  max-width: 45%;
}

.align-right,
p.align-right,
h1.align-right,
h2.align-right,
h3.align-right,
h4.align-right,
h5.align-right,
h6.align-right,
.text-right,
p.text-end,
h1.text-end,
h2.text-end,
h3.text-end,
h4.text-end,
h5.text-end,
h6.text-end {
  text-align: right;
}

.align-left,
p.align-left,
h1.align-left,
h2.align-left,
h3.align-left,
h4.align-left,
h5.align-left,
h6.align-left,
.text-left {
  text-align: left;
}

.align-center,
p.align-center,
h1.align-center,
h2.align-center,
h3.align-center,
h4.align-center,
h5.align-center,
h6.align-center,
.text-center,
p.text-center,
h1.text-center,
h2.text-center,
h3.text-center,
h4.text-center,
h5.text-center,
h6.text-center {
  text-indent: 0;
  text-align: center;
}

.align-justify,
p.align-justify,
h1.align-justify,
h2.align-justify,
h3.align-justify,
h4.align-justify,
h5.align-justify,
h6.align-justify {
  text-align: justify;
}

.indent {
  margin-left: 16px;
}

.line-after,
p.line-after {
  margin-bottom: 26px;
  margin-bottom: 1rem;
}

.line-after-half,
p.line-after-half {
  margin-bottom: 13px;
  margin-bottom: 0.5rem;
}

.line-before,
p.line-before {
  margin-top: 26px;
  margin-top: 1rem;
}

.line-before-half,
p.line-before-half {
  margin-top: 13px;
  margin-top: 0.5rem;
}

.first-header,
.csc-firstHeader {
  margin-top: 0;
}

p.first-paragraph {
  text-indent: 0;
}

.clear {
  clear: both;
}

.clear-only {
  clear: both;
  height: 0px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
}

.clearfix:before, #global-nav:before, #footer-page:before, .sidebar-container:before, .news-backlink-wrap:before, .interior-feature-promo:before, .flexslider .slide-text:before, .physician-app.profile .header:before, .physician-app.search .results-wrap .results li:before, #find-physician .content:before, .physician-app.search .search-wrap .content:before, #f1d #feature:before,
#f2d #feature:before, #content-wrap:before, #content-outer-wrap:before,
.clearfix:after,
#global-nav:after,
#footer-page:after,
.sidebar-container:after,
.news-backlink-wrap:after,
.interior-feature-promo:after,
.flexslider .slide-text:after,
.physician-app.profile .header:after,
.physician-app.search .results-wrap .results li:after,
#find-physician .content:after,
.physician-app.search .search-wrap .content:after,
#f1d #feature:after,
#f2d #feature:after,
#content-wrap:after,
#content-outer-wrap:after {
  content: ".";
  display: block;
  overflow: hidden;
  visibility: hidden;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}

.clearfix:after, #global-nav:after, #footer-page:after, .sidebar-container:after, .news-backlink-wrap:after, .interior-feature-promo:after, .flexslider .slide-text:after, .physician-app.profile .header:after, .physician-app.search .results-wrap .results li:after, #find-physician .content:after, .physician-app.search .search-wrap .content:after, #f1d #feature:after,
#f2d #feature:after, #content-wrap:after, #content-outer-wrap:after {
  clear: both;
}

/* @todo-ron may be able to eliminate the clearfix zoom once ie7 drops off support */
.clearfix, #global-nav, #footer-page, .sidebar-container, .news-backlink-wrap, .interior-feature-promo, .flexslider .slide-text, .physician-app.profile .header, .physician-app.search .results-wrap .results li, #find-physician .content, .physician-app.search .search-wrap .content, #f1d #feature,
#f2d #feature, #content-wrap, #content-outer-wrap {
  zoom: 1;
}

.out-of-sight {
  position: absolute;
  top: 0;
  left: -4000px;
}

.graphic-text-wrap {
  overflow: hidden;
}

.graphic-text {
  line-height: 300px;
  display: block;
}

.bleed {
  padding: 0;
}

th.width-10, td.width-10 {
  width: 10%;
}
th.width-20, td.width-20 {
  width: 20%;
}
th.width-30, td.width-30 {
  width: 30%;
}
th.width-40, td.width-40 {
  width: 40%;
}
th.width-50, td.width-50 {
  width: 50%;
}
th.width-60, td.width-60 {
  width: 60%;
}
th.width-70, td.width-70 {
  width: 70%;
}
th.width-80, td.width-80 {
  width: 80%;
}
th.width-90, td.width-90 {
  width: 90%;
}

h1.no-margin-bottom, h2.no-margin-bottom, h3.no-margin-bottom, h4.no-margin-bottom, h5.no-margin-bottom, h6.no-margin-bottom, p.no-margin-bottom, ul.no-margin-bottom, ol.no-margin-bottom {
  margin-bottom: 0px;
}
h1.highlight, h2.highlight, h3.highlight, h4.highlight, h5.highlight, h6.highlight, p.highlight, ul.highlight, ol.highlight {
  color: #155085;
}
h1.feature-text, h2.feature-text, h3.feature-text, h4.feature-text, h5.feature-text, h6.feature-text, p.feature-text, ul.feature-text, ol.feature-text {
  font-style: italic;
  font-size: 18px;
}

span.no-wrap, b.no-wrap, strong.no-wrap, i.no-wrap, em.no-wrap {
  white-space: nowrap;
}
span.highlight, b.highlight, strong.highlight, i.highlight, em.highlight {
  color: #155085;
}

figcaption,
p.caption {
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  font-weight: 700;
  color: #155085;
}

a.button-red, input.button-red, .physician-app.search .search-wrap form input[type=submit] {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  text-align: left;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  padding: 2px 20px 3px 10px;
  text-decoration: none;
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: inline-block;
  *display: inline;
  zoom: 1;
  color: #ffffff;
  -webkit-box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 3px 1px 3px rgba(0, 0, 0, 0.25);
  background: #b42c33 url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/ArrowRightWhite.png) no-repeat right center;
}
a.button-red.no-dash-arrow, input.button-red.no-dash-arrow, .physician-app.search .search-wrap form input.no-dash-arrow[type=submit] {
  background: #b42c33 none;
  padding: 3px 18px;
  margin: 0;
}

a.fancybox {
  zoom: 1;
}

p.feature-text {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #757575;
  margin-bottom: 15px;
}

span.highlight-blue, b.highlight-blue, strong.highlight-blue, i.highlight-blue, em.highlight-blue {
  color: #155085;
}

ul.link-list-blue {
  list-style: none;
  margin: 0;
}
ul.link-list-blue a {
  color: #155085;
  line-height: 16px;
}
ul.link-list-blue a:hover {
  color: #b42c33;
}
ul.link-list-blue li {
  padding-left: 18px;
  text-indent: -12px;
  margin-bottom: 6px;
}

h1.line-below,
h2.line-below,
h3.line-below,
h4.line-below,
h5.line-below,
h6.line-below {
  padding-bottom: 4px;
  border-bottom: 1px solid #D1DCE6;
}

h1.alternate,
h2.alternate,
h3.alternate,
h4.alternate,
h5.alternate,
h6.alternate {
  color: #4d4d4d;
  font-weight: 400;
}

/*
    .foo-bar {
        width: 50%;
        @include media($from: "tablet-small", $until: 900px, $and:"orientation: landscape"){
            width: 100%;
        }
    }
*/
/*
    @mixin phone-large-until-desktop {
        @include media($from: "phone-large", $until: "desktop"){
            @content
        }
    }
*/
/* @todo-ron MAY NEED WORK ON THIS FOR RESPONSIVE OR GENERATED PIXEL STYLES */
.column-group.rule {
  border-bottom: 1px solid #D1DCE6;
}

/* @todo-ron STILL A LOT OF WORK TO DO ON THIS INCLUDING RESPONSIVE OR GENERATED PIXEL STYLES */
.module-group {
  /* @todo-ron still need to clean up border radius */
}
.module-group .module {
  margin-bottom: 15px;
}
.module-group .module .header {
  margin: 0;
  padding: 4px 15px 0 0;
  color: #B3B3B3;
  font-size: 14px;
  background-color: #f2f2f2;
  -webkit-border-top-left-radius: 7px;
  -webkit-border-top-right-radius: 7px;
  -moz-border-radius-topleft: 7px;
  -moz-border-radius-topright: 7px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.module-group .module .header span {
  display: block;
  padding: 5px 0 5px 10px;
}
.module-group .module .footer {
  height: 15px;
  margin: 0;
  padding-right: 15px;
  font-size: 1px;
  overflow: hidden;
  -webkit-border-bottom-left-radius: 7px;
  -webkit-border-bottom-right-radius: 7px;
  -moz-border-radius-bottomleft: 7px;
  -moz-border-radius-bottomright: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #f2f2f2;
}
.module-group .module .footer span {
  display: block;
  height: 15px;
}
.module-group .module .body {
  /* Make sure these two values match the TS constant moduleBodyBorderWidth */
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}
.module-group .module .body .inner-wrap {
  padding: 10px 10px 10px 10px;
}
.module-group .module .body .inner-wrap.bleed {
  padding: 0;
}

/* div.ce-textpic-above .ce-textpic-image,
div.ce-textpic-below .ce-textpic-image
*/
/*
div.ce-textpic .ce-gallery .ce-textpic-image {
	margin-top: $base-rhythm * .5;
	margin-bottom: $base-rhythm * .5;
}

div.ce-textpic-imagecolumn,
td.ce-textpic-imagecolumn .ce-textpic-image m{
	margin-right: $base-rhythm * .5;
}
*/
.ce-gallery {
  margin-bottom: 13px;
}

.ce-textpic-below .ce-gallery {
  margin-top: 26px;
}

.ce-textpic .ce-left .ce-intext .ce-gallery,
.ce-textpic .ce-right .ce-intext .ce-gallery,
div.ce-textpic .ce-right .ce-intext .ce-gallery .ce-textpic-last,
div.ce-textpic .ce-left .ce-intext .ce-gallery .ce-textpic-last {
  margin-top: 0;
  margin-bottom: 0;
}

div.ce-textpic .ce-left .ce-intext .ce-gallery, div.ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery {
  margin-right: 26px;
}

div.ce-textpic .ce-right .ce-intext .ce-gallery, div.ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
  margin-left: 26px;
}

.frame-ruler-after {
  border-bottom: 1px solid black;
  margin-bottom: 15px;
}

.frame-ruler-before {
  padding-top: 10px;
  border-top: 1px solid black;
}

.ce-textpic .ce-left .ce-intext .ce-nowrap, .ce-textpic .ce-right .ce-intext .ce-nowrap {
  display: flex;
}
.ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery, .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
  flex-basis: 45%;
  max-width: 45%;
  margin-right: 4%;
}
.ce-textpic .ce-left .ce-intext .ce-nowrap .ce-bodytext, .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-bodytext {
  flex-basis: 45%;
  max-width: 45%;
}

.frame-ruler-after {
  border-bottom: 1px solid #CCCCCC;
}

.frame-ruler-before {
  border-top: 1px solid #CCCCCC;
}

.textpic__image-wrap {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  display: table;
}

.ce-right .ce-intext .ce-gallery,
.ce-left .ce-intext .ce-gallery,
.ce-right .ce-intext .ce-nowrap .ce-gallery,
.ce-left .ce-intext .ce-nowrap .ce-gallery {
  padding-top: 4px;
  margin-bottom: 0px;
}

div.ce-textpic .ce-gallery .ce-textpic-image {
  margin-bottom: 10px;
}

div.ce-left .ce-intext .ce-gallery, div.ce-left .ce-intext .ce-nowrap .ce-gallery {
  margin-right: 10px;
}

.ce-textpic-above .ce-gallery {
  margin-bottom: 0px;
}

.textpic--image-size-small .textpic__image-wrap {
  width: 30%;
}

.textpic--image-size-medium .textpic__image-wrap {
  width: 60%;
}

@media (min-width: 480px) {
  .textpic--image-in-text-right .textpic__image-wrap {
    float: right;
    margin-left: 26px;
    width: 45%;
  }
  .textpic--image-in-text-right.textpic--image-size-small .textpic__image-wrap {
    width: 25%;
  }
  .textpic--image-in-text-right.textpic--image-size-medium .textpic__image-wrap {
    width: 35%;
  }
  .textpic--image-in-text-left .textpic__image-wrap {
    float: left;
    margin-right: 26px;
    width: 45%;
  }
  .textpic--image-in-text-left.textpic--image-size-small .textpic__image-wrap {
    width: 25%;
  }
  .textpic--image-in-text-left.textpic--image-size-medium .textpic__image-wrap {
    width: 35%;
  }
}
.textpic--image-above-center .textpic__image-wrap {
  width: 100%;
}

.textpic--image-above-center .textpic__image-wrap img {
  width: 100%;
}

@media (min-width: 600px) {
  .textpic--image-beside-text-left,
  .textpic--image-beside-text-right {
    display: flex;
  }
  .textpic--image-beside-text-left .textpic__image-wrap,
  .textpic--image-beside-text-right .textpic__image-wrap {
    min-width: 45%;
  }
  .textpic--image-beside-text-left.textpic--image-size-small .textpic__image-wrap,
  .textpic--image-beside-text-right.textpic--image-size-small .textpic__image-wrap {
    min-width: 25%;
  }
  .textpic--image-beside-text-left.textpic--image-size-medium .textpic__image-wrap,
  .textpic--image-beside-text-right.textpic--image-size-medium .textpic__image-wrap {
    min-width: 35%;
  }
  .textpic--image-beside-text-left .common-ce__text-wrap {
    padding-left: 20px;
  }
  .textpic--image-beside-text-right {
    flex-direction: row-reverse;
  }
  .textpic--image-beside-text-right .common-ce__text-wrap {
    flex-grow: 1;
    padding-right: 20px;
  }
}
.common-image img {
  width: 100%;
  max-width: 100%;
}

#page-wrap {
  margin-left: auto;
  margin-right: auto;
  width: 960px;
}

#content-outer-wrap {
  margin-bottom: 25px;
}

#f1d #feature,
#f2d #feature {
  width: 710px;
  float: right;
}
#f1d #content-wrap,
#f2d #content-wrap {
  width: 710px;
  float: right;
}

#f2d #content-block-1 {
  width: 455px;
  float: left;
}
#f2d #content-block-2 {
  width: 235px;
  float: right;
}

#f2a #feature {
  width: 708px;
  float: left;
  padding-right: 18px;
  border-right: 1px solid #e1abad;
  margin-bottom: 20px;
}
#f2a #content-block-1 {
  width: 215px;
  float: right;
  margin-bottom: 20px;
}
#f2a #content-block-2 {
  clear: both;
  width: 100%;
  border-top: 1px solid #e1abad;
  padding-top: 20px;
}

#f3b #feature {
  width: 710px;
  float: right;
}
#f3b #content-wrap {
  float: right;
  margin-top: 20px;
}
#f3b #content-block-1,
#f3b #content-block-2,
#f3b #content-block-3 {
  width: 210px;
  float: left;
  margin-right: 20px;
  padding-right: 19px;
  border-right: 1px solid #D1DCE6;
}
#f3b #content-block-3 {
  margin-right: 0;
  padding-right: 0;
  border: none;
}

#generated-content-1 {
  width: 217px;
  padding-right: 10px;
  border-right: 1px solid #D1DCE6;
  float: left;
}

#content-block-1 > :first-child, #content-block-1 > :first-child .headline {
  margin-top: 0;
}
#content-block-1 > :last-child {
  margin-bottom: 0;
}

.ce-margin-top-default, .ce-margin-before-default, .ce-space-top-default, .ce-space-before-default, .frame-margin-top-default, .frame-margin-before-default, .frame-space-top-default, .frame-space-before-default {
  margin-top: 0;
}
.ce-margin-top-none, .ce-margin-before-none, .ce-space-top-none, .ce-space-before-none, .frame-margin-top-none, .frame-margin-before-none, .frame-space-top-none, .frame-space-before-none {
  margin-top: 0 !important;
}
.ce-margin-top-0, .ce-margin-before-0, .ce-space-top-0, .ce-space-before-0, .frame-margin-top-0, .frame-margin-before-0, .frame-space-top-0, .frame-space-before-0 {
  margin-top: 0 !important;
}
.ce-margin-top-20, .ce-margin-before-20, .ce-space-top-20, .ce-space-before-20, .frame-margin-top-20, .frame-margin-before-20, .frame-space-top-20, .frame-space-before-20 {
  margin-top: 20px !important;
}
.ce-margin-top-30, .ce-margin-before-30, .ce-space-top-30, .ce-space-before-30, .frame-margin-top-30, .frame-margin-before-30, .frame-space-top-30, .frame-space-before-30 {
  margin-top: 30px !important;
}
.ce-margin-top-40, .ce-margin-before-40, .ce-space-top-40, .ce-space-before-40, .frame-margin-top-40, .frame-margin-before-40, .frame-space-top-40, .frame-space-before-40 {
  margin-top: 40px !important;
}
.ce-margin-top-50, .ce-margin-before-50, .ce-space-top-50, .ce-space-before-50, .frame-margin-top-50, .frame-margin-before-50, .frame-space-top-50, .frame-space-before-50 {
  margin-top: 50px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-50, .ce-margin-before-50, .ce-space-top-50, .ce-space-before-50, .frame-margin-top-50, .frame-margin-before-50, .frame-space-top-50, .frame-space-before-50 {
    margin-top: 25px !important;
  }
}
.ce-margin-top-60, .ce-margin-before-60, .ce-space-top-60, .ce-space-before-60, .frame-margin-top-60, .frame-margin-before-60, .frame-space-top-60, .frame-space-before-60 {
  margin-top: 60px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-60, .ce-margin-before-60, .ce-space-top-60, .ce-space-before-60, .frame-margin-top-60, .frame-margin-before-60, .frame-space-top-60, .frame-space-before-60 {
    margin-top: 30px !important;
  }
}
.ce-margin-top-70, .ce-margin-before-70, .ce-space-top-70, .ce-space-before-70, .frame-margin-top-70, .frame-margin-before-70, .frame-space-top-70, .frame-space-before-70 {
  margin-top: 70px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-70, .ce-margin-before-70, .ce-space-top-70, .ce-space-before-70, .frame-margin-top-70, .frame-margin-before-70, .frame-space-top-70, .frame-space-before-70 {
    margin-top: 35px !important;
  }
}
.ce-margin-top-80, .ce-margin-before-80, .ce-space-top-80, .ce-space-before-80, .frame-margin-top-80, .frame-margin-before-80, .frame-space-top-80, .frame-space-before-80 {
  margin-top: 80px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-80, .ce-margin-before-80, .ce-space-top-80, .ce-space-before-80, .frame-margin-top-80, .frame-margin-before-80, .frame-space-top-80, .frame-space-before-80 {
    margin-top: 40px !important;
  }
}
.ce-margin-top-90, .ce-margin-before-90, .ce-space-top-90, .ce-space-before-90, .frame-margin-top-90, .frame-margin-before-90, .frame-space-top-90, .frame-space-before-90 {
  margin-top: 90px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-90, .ce-margin-before-90, .ce-space-top-90, .ce-space-before-90, .frame-margin-top-90, .frame-margin-before-90, .frame-space-top-90, .frame-space-before-90 {
    margin-top: 45px !important;
  }
}
.ce-margin-top-100, .ce-margin-before-100, .ce-space-top-100, .ce-space-before-100, .frame-margin-top-100, .frame-margin-before-100, .frame-space-top-100, .frame-space-before-100 {
  margin-top: 100px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-100, .ce-margin-before-100, .ce-space-top-100, .ce-space-before-100, .frame-margin-top-100, .frame-margin-before-100, .frame-space-top-100, .frame-space-before-100 {
    margin-top: 50px !important;
  }
}
.ce-margin-top-120, .ce-margin-before-120, .ce-space-top-120, .ce-space-before-120, .frame-margin-top-120, .frame-margin-before-120, .frame-space-top-120, .frame-space-before-120 {
  margin-top: 120px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-120, .ce-margin-before-120, .ce-space-top-120, .ce-space-before-120, .frame-margin-top-120, .frame-margin-before-120, .frame-space-top-120, .frame-space-before-120 {
    margin-top: 60px !important;
  }
}
.ce-margin-top-140, .ce-margin-before-140, .ce-space-top-140, .ce-space-before-140, .frame-margin-top-140, .frame-margin-before-140, .frame-space-top-140, .frame-space-before-140 {
  margin-top: 140px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-140, .ce-margin-before-140, .ce-space-top-140, .ce-space-before-140, .frame-margin-top-140, .frame-margin-before-140, .frame-space-top-140, .frame-space-before-140 {
    margin-top: 70px !important;
  }
}
.ce-margin-top-160, .ce-margin-before-160, .ce-space-top-160, .ce-space-before-160, .frame-margin-top-160, .frame-margin-before-160, .frame-space-top-160, .frame-space-before-160 {
  margin-top: 160px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-160, .ce-margin-before-160, .ce-space-top-160, .ce-space-before-160, .frame-margin-top-160, .frame-margin-before-160, .frame-space-top-160, .frame-space-before-160 {
    margin-top: 80px !important;
  }
}
.ce-margin-top-180, .ce-margin-before-180, .ce-space-top-180, .ce-space-before-180, .frame-margin-top-180, .frame-margin-before-180, .frame-space-top-180, .frame-space-before-180 {
  margin-top: 180px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-180, .ce-margin-before-180, .ce-space-top-180, .ce-space-before-180, .frame-margin-top-180, .frame-margin-before-180, .frame-space-top-180, .frame-space-before-180 {
    margin-top: 90px !important;
  }
}
.ce-margin-top-200, .ce-margin-before-200, .ce-space-top-200, .ce-space-before-200, .frame-margin-top-200, .frame-margin-before-200, .frame-space-top-200, .frame-space-before-200 {
  margin-top: 200px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-top-200, .ce-margin-before-200, .ce-space-top-200, .ce-space-before-200, .frame-margin-top-200, .frame-margin-before-200, .frame-space-top-200, .frame-space-before-200 {
    margin-top: 100px !important;
  }
}
.ce-margin-bottom-default, .ce-margin-after-default, .ce-space-bottom-default, .ce-space-after-default, .frame-margin-bottom-default, .frame-margin-after-default, .frame-space-bottom-default, .frame-space-after-default {
  margin-bottom: 0;
}
.ce-margin-bottom-none, .ce-margin-after-none, .ce-space-bottom-none, .ce-space-after-none, .frame-margin-bottom-none, .frame-margin-after-none, .frame-space-bottom-none, .frame-space-after-none {
  margin-bottom: 0 !important;
}
.ce-margin-bottom-0, .ce-margin-after-0, .ce-space-bottom-0, .ce-space-after-0, .frame-margin-bottom-0, .frame-margin-after-0, .frame-space-bottom-0, .frame-space-after-0 {
  margin-bottom: 0 !important;
}
.ce-margin-bottom-20, .ce-margin-after-20, .ce-space-bottom-20, .ce-space-after-20, .frame-margin-bottom-20, .frame-margin-after-20, .frame-space-bottom-20, .frame-space-after-20 {
  margin-bottom: 20px !important;
}
.ce-margin-bottom-30, .ce-margin-after-30, .ce-space-bottom-30, .ce-space-after-30, .frame-margin-bottom-30, .frame-margin-after-30, .frame-space-bottom-30, .frame-space-after-30 {
  margin-bottom: 30px !important;
}
.ce-margin-bottom-40, .ce-margin-after-40, .ce-space-bottom-40, .ce-space-after-40, .frame-margin-bottom-40, .frame-margin-after-40, .frame-space-bottom-40, .frame-space-after-40 {
  margin-bottom: 40px !important;
}
.ce-margin-bottom-50, .ce-margin-after-50, .ce-space-bottom-50, .ce-space-after-50, .frame-margin-bottom-50, .frame-margin-after-50, .frame-space-bottom-50, .frame-space-after-50 {
  margin-bottom: 50px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-50, .ce-margin-after-50, .ce-space-bottom-50, .ce-space-after-50, .frame-margin-bottom-50, .frame-margin-after-50, .frame-space-bottom-50, .frame-space-after-50 {
    margin-bottom: 25px !important;
  }
}
.ce-margin-bottom-60, .ce-margin-after-60, .ce-space-bottom-60, .ce-space-after-60, .frame-margin-bottom-60, .frame-margin-after-60, .frame-space-bottom-60, .frame-space-after-60 {
  margin-bottom: 60px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-60, .ce-margin-after-60, .ce-space-bottom-60, .ce-space-after-60, .frame-margin-bottom-60, .frame-margin-after-60, .frame-space-bottom-60, .frame-space-after-60 {
    margin-bottom: 30px !important;
  }
}
.ce-margin-bottom-70, .ce-margin-after-70, .ce-space-bottom-70, .ce-space-after-70, .frame-margin-bottom-70, .frame-margin-after-70, .frame-space-bottom-70, .frame-space-after-70 {
  margin-bottom: 70px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-70, .ce-margin-after-70, .ce-space-bottom-70, .ce-space-after-70, .frame-margin-bottom-70, .frame-margin-after-70, .frame-space-bottom-70, .frame-space-after-70 {
    margin-bottom: 35px !important;
  }
}
.ce-margin-bottom-80, .ce-margin-after-80, .ce-space-bottom-80, .ce-space-after-80, .frame-margin-bottom-80, .frame-margin-after-80, .frame-space-bottom-80, .frame-space-after-80 {
  margin-bottom: 80px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-80, .ce-margin-after-80, .ce-space-bottom-80, .ce-space-after-80, .frame-margin-bottom-80, .frame-margin-after-80, .frame-space-bottom-80, .frame-space-after-80 {
    margin-bottom: 40px !important;
  }
}
.ce-margin-bottom-90, .ce-margin-after-90, .ce-space-bottom-90, .ce-space-after-90, .frame-margin-bottom-90, .frame-margin-after-90, .frame-space-bottom-90, .frame-space-after-90 {
  margin-bottom: 90px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-90, .ce-margin-after-90, .ce-space-bottom-90, .ce-space-after-90, .frame-margin-bottom-90, .frame-margin-after-90, .frame-space-bottom-90, .frame-space-after-90 {
    margin-bottom: 45px !important;
  }
}
.ce-margin-bottom-100, .ce-margin-after-100, .ce-space-bottom-100, .ce-space-after-100, .frame-margin-bottom-100, .frame-margin-after-100, .frame-space-bottom-100, .frame-space-after-100 {
  margin-bottom: 100px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-100, .ce-margin-after-100, .ce-space-bottom-100, .ce-space-after-100, .frame-margin-bottom-100, .frame-margin-after-100, .frame-space-bottom-100, .frame-space-after-100 {
    margin-bottom: 50px !important;
  }
}
.ce-margin-bottom-120, .ce-margin-after-120, .ce-space-bottom-120, .ce-space-after-120, .frame-margin-bottom-120, .frame-margin-after-120, .frame-space-bottom-120, .frame-space-after-120 {
  margin-bottom: 120px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-120, .ce-margin-after-120, .ce-space-bottom-120, .ce-space-after-120, .frame-margin-bottom-120, .frame-margin-after-120, .frame-space-bottom-120, .frame-space-after-120 {
    margin-bottom: 60px !important;
  }
}
.ce-margin-bottom-140, .ce-margin-after-140, .ce-space-bottom-140, .ce-space-after-140, .frame-margin-bottom-140, .frame-margin-after-140, .frame-space-bottom-140, .frame-space-after-140 {
  margin-bottom: 140px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-140, .ce-margin-after-140, .ce-space-bottom-140, .ce-space-after-140, .frame-margin-bottom-140, .frame-margin-after-140, .frame-space-bottom-140, .frame-space-after-140 {
    margin-bottom: 70px !important;
  }
}
.ce-margin-bottom-160, .ce-margin-after-160, .ce-space-bottom-160, .ce-space-after-160, .frame-margin-bottom-160, .frame-margin-after-160, .frame-space-bottom-160, .frame-space-after-160 {
  margin-bottom: 160px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-160, .ce-margin-after-160, .ce-space-bottom-160, .ce-space-after-160, .frame-margin-bottom-160, .frame-margin-after-160, .frame-space-bottom-160, .frame-space-after-160 {
    margin-bottom: 80px !important;
  }
}
.ce-margin-bottom-180, .ce-margin-after-180, .ce-space-bottom-180, .ce-space-after-180, .frame-margin-bottom-180, .frame-margin-after-180, .frame-space-bottom-180, .frame-space-after-180 {
  margin-bottom: 180px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-180, .ce-margin-after-180, .ce-space-bottom-180, .ce-space-after-180, .frame-margin-bottom-180, .frame-margin-after-180, .frame-space-bottom-180, .frame-space-after-180 {
    margin-bottom: 90px !important;
  }
}
.ce-margin-bottom-200, .ce-margin-after-200, .ce-space-bottom-200, .ce-space-after-200, .frame-margin-bottom-200, .frame-margin-after-200, .frame-space-bottom-200, .frame-space-after-200 {
  margin-bottom: 200px !important;
}
@media only screen and (max-width: 599px) {
  .ce-margin-bottom-200, .ce-margin-after-200, .ce-space-bottom-200, .ce-space-after-200, .frame-margin-bottom-200, .frame-margin-after-200, .frame-space-bottom-200, .frame-space-after-200 {
    margin-bottom: 100px !important;
  }
}

.ce-wrapper {
  margin-bottom: 0.5rem;
}
.ce-wrapper--ruler-before {
  padding-top: 15px;
  border-top: 1px solid #CCCCCC;
}
.ce-wrapper--ruler-after {
  padding-bottom: 15px;
  border-bottom: 1px solid #CCCCCC;
}
.ce-wrapper--menu_subpages {
  margin-bottom: 0;
}

.rich-text :last-child {
  margin-bottom: 0;
}

.rounded-corner.top.right {
  -moz-border-radius-topright: 25px;
  -webkit-border-top-right-radius: 25px;
  border-top-right-radius: 25px;
  overflow: hidden;
}

.fancybox-title-float-wrap {
  bottom: -40px;
}

.home-promo {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
.home-promo .home-promo-text {
  padding: 0 10px;
  text-align: left;
}
.home-promo .home-promo-text h3 {
  margin-top: 10px;
  padding-bottom: 0;
  border-bottom: none;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.3em;
}
.home-promo .home-promo-text h3 a {
  font-weight: 600;
}
.home-promo .home-promo-text p {
  margin-bottom: 0.2em;
  line-height: 1.4em;
  font-size: 16px;
}
.home-promo .home-promo-text .link {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 17px;
  font-weight: bold;
  padding: 0 15px 0 0;
  color: #b42c33;
  background: url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/link_arrow.png) no-repeat right 2px;
}

.promo-toggle-btn {
  clear: both;
  text-align: right;
  padding: 20px 0;
  text-decoration: none;
  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  background: url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/promo-accordion-btn-more.png) left center no-repeat;
}
.promo-toggle-btn span {
  cursor: pointer;
  padding: 6px 25px 4px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  font-size: 11px;
  letter-spacing: 1px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.promo-toggle-btn-less {
  background: url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/promo-accordion-btn-less.png) left center no-repeat;
}

.promo-accordion-wrap {
  display: none;
  width: 100%;
}

#find-physician h2, .physician-app.search .search-wrap h2 {
  font-size: 16px;
  color: #FFFFFF;
  background-color: #537A9D;
  font-weight: 700;
  height: 25px;
  vertical-align: bottom;
  text-align: left;
  margin: 0px;
  padding: 4px 7px 0;
}
#find-physician h3, .physician-app.search .search-wrap h3 {
  margin: 5px 0 0 0;
  padding: 4px 7px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  color: #b42c33;
  border-left: 1px solid #b8cada;
  border-right: 1px solid #b8cada;
  background-color: #D1DCE6;
}
#find-physician h3.first, .physician-app.search .search-wrap h3.first {
  margin-top: 0;
}
#find-physician .content, .physician-app.search .search-wrap .content {
  background-color: #D1DCE6;
  padding: 5px 7px;
  border: 1px solid #b8cada;
  border-top: none;
  height: 105px;
}
#find-physician .content .button-red, #find-physician .content .physician-app.search .search-wrap form input[type=submit], .physician-app.search .search-wrap form #find-physician .content input[type=submit], .physician-app.search .search-wrap .content .button-red, .physician-app.search .search-wrap .content form input[type=submit], .physician-app.search .search-wrap form .content input[type=submit] {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  text-align: left;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  padding: 4px 18px;
  text-decoration: none;
  font-size: 13px;
  margin-bottom: 10px;
  color: #ffffff;
  background: #b42c33;
  border: none;
  float: right;
  margin-top: 15px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}
#find-physician #Specialty, .physician-app.search .search-wrap #Specialty {
  font-size: 12px;
  padding: 4px;
  width: 175px;
}
#find-physician .roundedInput, .physician-app.search .search-wrap .roundedInput {
  height: 12px;
  padding: 6px 10px;
  width: 175px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  font-size: 12px !important;
}
#find-physician .hasCustomSelect, .physician-app.search .search-wrap .hasCustomSelect {
  -webkit-appearance: menulist-button;
  width: 200px;
  vertical-align: middle;
  height: 30px;
  font-size: 12px;
}
#find-physician #FirstName, .physician-app.search .search-wrap #FirstName {
  margin-top: 5px;
}

a.my-bronx-care-img {
  background: url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/my-bronx-care-image.jpg) 0 0 no-repeat;
  height: 76px;
  display: block;
}
a.my-bronx-care-img:hover {
  background-position: 0 -76px;
}

.ce-headline-right {
  text-align: right;
}

.ce-headline-left {
  text-align: left;
}

.ce-headline-center {
  text-indent: 0;
  text-align: center;
}

.physician-app h3 {
  color: #155085;
  margin-bottom: 3px;
}
.physician-app .dummy {
  border: solid 1px red !important;
  margin: -2px;
}
.physician-app.search span {
  font-weight: bold;
}
.physician-app.search .tabs {
  padding: 0 0 6px 14px;
  border-bottom: 1px solid #155085;
  margin-left: 0px;
  margin-top: 30px;
  margin-bottom: 20px;
  height: 35px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .physician-app.search .tabs {
    padding: 0 0 0 14px;
  }
}
.physician-app.search .tabs li {
  -moz-border-radius-topleft: 5px;
  -webkit-border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  -moz-border-radius-topright: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  display: inline-block;
  list-style-type: none;
  padding: 8px 20px 6px;
  margin-right: 8px;
  background-color: #D1DCE6;
  font-size: 15px;
}
.physician-app.search .tabs li.active {
  border-top: 1px solid #155085;
  border-left: 1px solid #155085;
  border-right: 1px solid #155085;
  background-color: white;
  color: #b42c33;
  padding-bottom: 7px;
}
.physician-app.search .search-wrap {
  border-bottom: 1px solid #D1DCE6;
  padding-bottom: 20px;
  overflow: auto;
  width: 100%;
}
.physician-app.search .search-wrap form {
  padding: 3px 0 3px 26px;
  margin-right: 16px;
  float: left;
}
.physician-app.search .search-wrap form input[type=submit] {
  padding: 2px 20px 2px 10px;
  border: none;
  margin-left: 10px;
}
.physician-app.search .results-wrap {
  padding-left: 10px;
}
.physician-app.search .results-wrap .search-meta {
  float: left;
  padding-top: 10px;
}
.physician-app.search .results-wrap .pagination {
  float: right;
  color: #aaa;
  text-transform: uppercase;
  padding-top: 10px;
  margin-bottom: 5px;
}
.physician-app.search .results-wrap .pagination li {
  list-style-type: none;
  display: inline;
  margin-left: 3px;
}
.physician-app.search .results-wrap .pagination li a {
  color: #404040;
  font-weight: 400;
}
.physician-app.search .results-wrap .pagination li:first-child.current, .physician-app.search .results-wrap .pagination li:first-child.current a {
  color: #404040;
  text-decoration: none;
}
.physician-app.search .results-wrap .pagination .current, .physician-app.search .results-wrap .pagination .current a {
  color: #155085;
  text-decoration: underline;
}
.physician-app.search .results-wrap .pagination .previous a,
.physician-app.search .results-wrap .pagination .next a {
  color: #155085;
}
.physician-app.search .results-wrap .results {
  margin: 0;
  list-style: none;
}
.physician-app.search .results-wrap .results:before {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.physician-app.search .results-wrap .results h3 {
  color: #155085;
  margin-bottom: 3px;
}
.physician-app.search .results-wrap .results span {
  font-weight: bold;
}
.physician-app.search .results-wrap .results li {
  border-bottom: 1px solid #D1DCE6;
  padding: 20px 0;
}
.physician-app.search .results-wrap .results li img, .physician-app.search .results-wrap .results li .placeholder {
  width: 80px;
  padding: 2px;
  border: 1px solid #999999;
  display: block;
  margin-right: 10px;
  float: left;
}
.physician-app.search .results-wrap .results li .description {
  width: 260px;
  float: left;
  margin-right: 20px;
}
.physician-app.search .results-wrap .results li .description h3 {
  font-family: "Source Sans 3", Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-bottom: 8px;
}
.physician-app.search .results-wrap .results li .description p {
  line-height: 17px;
}
.physician-app.search .results-wrap .results li .location {
  width: 177px;
  float: left;
}
.physician-app.search .results-wrap .results li .button-red, .physician-app.search .results-wrap .results li .search-wrap form input[type=submit], .physician-app.search .search-wrap form .results-wrap .results li input[type=submit] {
  float: right;
  display: block;
  margin: 0;
}
.physician-app.profile .header {
  background: #D1DCE6;
  padding: 20px;
  margin-bottom: 30px;
  float: left;
  -moz-border-radius-topright: 25px;
  -webkit-border-top-right-radius: 25px;
  border-top-right-radius: 25px;
  overflow: auto;
}
.physician-app.profile .header span {
  font-weight: bold;
  line-height: 17px;
}
.physician-app.profile .header .description {
  float: left;
  width: 278px;
  padding-right: 20px;
}
.physician-app.profile .header .description h3 {
  font-size: 19px;
  margin-bottom: 8px;
}
.physician-app.profile .header .description p {
  line-height: 16px;
}
.physician-app.profile .header .media {
  float: right;
}
.physician-app.profile .header .media a.video {
  position: relative;
  float: right;
  margin-left: 35px;
  margin-bottom: 0px !important;
}
.physician-app.profile .header .media a.video span {
  background: url("/_assets/663a5755088478c671ee2a99e75bcb32/Images/button_play.png") no-repeat left 3px;
  color: white;
  text-transform: uppercase;
  padding: 13px 0 13px 55px;
  position: relative;
  bottom: 30px;
  left: 155px;
  margin-left: -140px;
}
.physician-app.profile .header .media img.photo {
  float: right;
  padding: 2px;
  border: 1px solid #fff;
}
.physician-app.profile .details {
  float: left;
  width: 440px;
  list-style: none;
  overflow: auto;
}
.physician-app.profile .details li {
  border-bottom: 1px solid #D1DCE6;
  display: inline-block;
  padding: 8px 0;
}
.physician-app.profile .details li div:first-of-type {
  float: left;
  width: 140px;
}
.physician-app.profile .details li div:first-of-type span {
  font-weight: bold;
}
.physician-app.profile .details li div:last-of-type {
  float: left;
  width: 300px;
}
.physician-app.profile .location {
  float: right;
  border-left: 1px solid #D1DCE6;
  padding: 10px 0 20px 20px;
  width: 215px;
}
.physician-app.profile .location span {
  font-weight: bold;
}
.physician-app.profile .location iframe {
  margin-top: 20px;
}

.physicians-side-list {
  margin: 0;
  list-style: none;
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
}
.physicians-side-list li {
  margin-bottom: 9px;
}
.physicians-side-list a {
  color: #155085;
}

.fancybox-skin .fancybox-title {
  font-family: "Source Sans 3", Helvetica, Arial, sans-serif;
  font-size: 11px;
  font-weight: bold;
  font-style: italic;
  line-height: 12px;
  color: #155085;
  margin-bottom: 5px;
  padding-top: 0;
}

.flexslider {
  border: none !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  border-radius: 0 !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
  -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
  -moz-box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
  -o-box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
  margin-bottom: 0 !important;
  background-color: #205287 !important;
  height: 400px;
}
.flexslider .flex-control-nav {
  top: 307px !important;
  text-align: left !important;
  left: 35px !important;
  width: auto !important;
  z-index: 10 !important;
}
.flexslider .flex-control-nav li {
  margin: 0 3px !important;
}
.flexslider .flex-control-nav li a {
  background: none repeat scroll 0 0 rgb(96, 133, 170) !important;
  height: 9px !important;
  width: 9px !important;
}
.flexslider .flex-control-nav li a.flex-active {
  background: none repeat scroll 0 0 rgb(172, 47, 53) !important;
}
.flexslider .flex-direction-nav .flex-prev {
  top: 150px !important;
  left: 0 !important;
  width: 31px !important;
  height: 61px !important;
  background: url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/bg_direction_nav_prev.png) no-repeat 0 0 !important;
  font-size: 0;
}
.flexslider .flex-direction-nav .flex-prev:hover {
  opacity: 1 !important;
}
.flexslider .flex-direction-nav .flex-next {
  top: 150px !important;
  right: 0 !important;
  width: 31px !important;
  height: 61px !important;
  background: url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/bg_direction_nav_next.png) no-repeat 0 0 !important;
  font-size: 0;
}
.flexslider .flex-direction-nav .flex-next:hover {
  opacity: 1 !important;
}
.flexslider .flex-direction-nav a.flex-next:before,
.flexslider .flex-direction-nav a.flex-prev:before {
  content: "";
}
.flexslider .slide-text {
  padding: 30px 35px 15px;
  border-top: 1px solid #b42c33;
}
.flexslider .slide-text .description {
  color: white;
  font-size: 12.5px;
  line-height: 1.4em;
}
.flexslider .slide-text .more-btn {
  float: right;
  width: 120px;
  margin-top: 10px;
  margin-left: 20px;
  height: 50px;
}
.flexslider .slide-text .more-btn a {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  text-align: left;
  padding: 6px 20px;
  text-decoration: none;
  font-size: 13px;
  color: #ffffff;
  background: #b42c33;
}

.flexslider-interior {
  padding-bottom: 20px !important;
}
.flexslider-interior .flexslider {
  background-color: white !important;
  height: auto;
}
.flexslider-interior .flexslider .slides li {
  -moz-border-radius-topright: 25px;
  -webkit-border-top-right-radius: 25px;
  border-top-right-radius: 25px;
  overflow: hidden;
}
.flexslider-interior .flexslider .flex-control-nav {
  top: auto !important;
  bottom: -30px !important;
  text-align: center !important;
  left: 0 !important;
  width: 100% !important;
}
.flexslider-interior .flexslider .flex-control-nav li {
  margin: 0 3px !important;
}
.flexslider-interior .flexslider .flex-control-nav li a {
  background: none repeat scroll 0 0 rgb(96, 133, 170) !important;
  height: 9px !important;
  width: 9px !important;
}
.flexslider-interior .flexslider .flex-control-nav li a.flex-active {
  background: none repeat scroll 0 0 rgb(172, 47, 53) !important;
}
.flexslider-interior .flexslider .flex-direction-nav .flex-prev {
  top: 50% !important;
}
.flexslider-interior .flexslider .flex-direction-nav .flex-next {
  top: 50% !important;
}

.location-main-title {
  float: left;
  margin-left: 15px;
}

.location-network-link {
  font-weight: bold;
  padding: 0 15px 0 0;
  color: #265685;
  background: url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/link_arrow_blue.png) no-repeat right 6px;
  float: right;
  margin-top: 10px;
}

.content-wrap {
  background: #e1e8ed;
}
.content-wrap .filter-bar {
  padding: 15px 15px 15px 35px;
  background: url("/_assets/663a5755088478c671ee2a99e75bcb32/Images/VIEW-icon.png") no-repeat left 3px top 13px;
  margin-left: -16px;
}
.content-wrap .filter-bar ul {
  padding-left: 30px;
}
.content-wrap .filter-bar ul li {
  display: inline;
  list-style-type: none;
  margin-right: 10px;
}
.content-wrap .filter-bar ul li a {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #155085;
}
.content-wrap .filter-bar ul li a:hover {
  color: #155085;
  background: white;
}
.content-wrap .filter-bar ul li.active a {
  background-color: #b42c33;
  padding: 4px;
  color: white;
}
.content-wrap .column-wrap {
  width: 100%;
  overflow: hidden;
}
.content-wrap .column-wrap .column {
  width: 48%;
  float: left;
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}
.content-wrap .column-wrap .column .location-category {
  padding: 0 15px 45px 15px;
  clear: both;
}
.content-wrap .column-wrap .column .location-category li {
  list-style-type: none;
  margin-left: -16px;
  clear: both;
}
.content-wrap .column-wrap .column .legend-key {
  color: white;
  padding-top: 3px;
  margin-right: 5px;
  font-size: 90%;
  height: 24px;
  width: 24px;
  text-align: center;
  display: block;
  float: left;
  position: static;
}
.content-wrap .column-wrap .column .legend-description {
  float: left;
  width: 90%;
  margin-top: 4px;
  padding-bottom: 12px;
  line-height: 16px;
}
.content-wrap .column-wrap .column .legend-description .title {
  color: #155085;
  font-weight: bold;
}
.content-wrap .column-wrap .column .legend-description .photo {
  margin: 10px 0;
}
.content-wrap .column-wrap .column .new h3 {
  color: #faa41a;
}
.content-wrap .column-wrap .column .new .legend-key {
  background: url("/_assets/663a5755088478c671ee2a99e75bcb32/Images/yellow_dot.png") no-repeat 1px 1px;
}
.content-wrap .column-wrap .column .new .photo {
  border: 2px solid #faa41a;
}
.content-wrap .column-wrap .column .practices h3 {
  color: #ff3e00;
}
.content-wrap .column-wrap .column .practices .legend-key {
  background: url("/_assets/663a5755088478c671ee2a99e75bcb32/Images/orange_dot.png") no-repeat 1px 1px;
}
.content-wrap .column-wrap .column .mlk h3 {
  color: #009ddc;
}
.content-wrap .column-wrap .column .mlk .legend-key {
  background: url("/_assets/663a5755088478c671ee2a99e75bcb32/Images/blue_dot.png") no-repeat 1px 1px;
}
.content-wrap .column-wrap .column .mhs h3 {
  color: #85C441;
}
.content-wrap .column-wrap .column .mhs .legend-key {
  background: url("/_assets/663a5755088478c671ee2a99e75bcb32/Images/green_dot.png") no-repeat 1px 1px;
}
.content-wrap .column-wrap .column .map {
  margin-bottom: 15px;
  position: relative;
}
.content-wrap .column-wrap .column .map .legend-key {
  position: absolute;
  margin-top: -12px;
  margin-left: -12px;
}

.bronx-donations {
  border-top: 1px solid #b42c33;
  border-bottom: 1px solid #b42c33;
}
.bronx-donations #submission-wrap {
  text-indent: -9999px;
}
.bronx-donations h3 {
  color: #404040;
  font-size: 1.4em;
}
.bronx-donations h4 {
  color: #404040;
  font-size: 1.15em;
  margin: 0 0 4px 0;
}
.bronx-donations input[type=text], .bronx-donations input[type=email] {
  width: 100%;
  height: 26px;
  padding-left: 6px;
  border: 1px solid #d2d2d2;
  border-radius: 3px;
}
.bronx-donations select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d2d2d2;
  background-color: white;
  height: 32px;
  width: 100%;
  border-radius: 3px;
  padding-left: 6px;
}
.bronx-donations .select-field {
  position: relative;
  display: inline-block;
}
.bronx-donations .select-field:before {
  content: "▲";
  position: absolute;
  top: 3px;
  right: 0;
  bottom: 0;
  font-size: 60%;
  padding: 0 5px;
}
.bronx-donations .select-field:after {
  content: "▼";
  position: absolute;
  top: 12px;
  right: 0;
  bottom: 0;
  font-size: 60%;
  padding: 0 5px;
}
.bronx-donations .row {
  clear: both;
}
.bronx-donations .row .column {
  float: left;
  margin: 0 20px 10px 0;
  width: 689px;
}
.bronx-donations .row .column.last {
  margin-right: 0;
}
.bronx-donations .row.two-column-row .column {
  width: 375px !important;
  margin-right: 31px;
}
.bronx-donations .row.two-column-row .column.last {
  width: 284px !important;
  margin-right: 0;
}
.bronx-donations .row.two-column-row .column.last-select {
  width: 293px !important;
  margin-right: 0;
}
.bronx-donations .row.two-column-row.indent .column {
  width: 355px !important;
}
.bronx-donations .row.two-column-row.indent .column.last {
  width: 264px !important;
}
.bronx-donations .row.two-column-row.indent .column.last-select {
  width: 293px !important;
  margin-right: 0;
}
.bronx-donations .row.indent {
  margin-left: 20px;
}
.bronx-donations .row.indent .column {
  width: 669px;
}
.bronx-donations .form-section {
  margin-bottom: 35px;
}
.bronx-donations .form-section fieldset {
  margin-top: 15px;
}
.bronx-donations .form-section fieldset:first-of-type {
  margin-top: 0;
}
.bronx-donations .form-section fieldset.donation .amount-wrap .column {
  width: 65px;
}
.bronx-donations .form-section fieldset.donation .amount-wrap .column:nth-last-child(2) {
  margin: 0;
}
.bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
  margin-top: -5px;
  width: 200px;
}
.bronx-donations .form-section fieldset.credit-card .cc-number-wrap .column {
  width: 526px;
}
.bronx-donations .form-section fieldset.credit-card .cc-number-wrap .column.last {
  width: 154px;
  margin-top: 3px;
}
.bronx-donations .form-section fieldset.credit-card .cc-security-wrap .column {
  width: 162px;
}
.bronx-donations .form-section fieldset.credit-card .cc-security-wrap .column.last {
  width: 33px;
  margin-top: 3px;
}
.bronx-donations .form-section fieldset.dedication-details > .row:last-child .column {
  width: 180px;
}
.bronx-donations .form-section fieldset.dedication-details > .row:last-child .column.last {
  width: 320px;
}
.bronx-donations .submit-wrap {
  text-align: center;
  margin: 30px auto;
}
.bronx-donations .submit-wrap button {
  background-color: #b42c33;
  padding: 12px 50px;
  color: white;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  height: 30px;
  height: 54px;
  border: 0;
  font-family: "ff-din-web", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  width: 360px;
}
.bronx-donations .submit-wrap button .icon .spinner[style] {
  left: -10px !important;
  top: -8px !important;
}
.bronx-donations .payment-form-confirmation .label {
  font-weight: bold;
}

.sidebar-container {
  padding: 15px;
  background-color: #D1DCE6;
}

.interior-feature-promo {
  margin-bottom: 20px;
  background-color: #D1DCE6;
  -moz-border-radius-topright: 25px;
  -webkit-border-top-right-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
@media only screen and (min-width: 767px) {
  .interior-feature-promo {
    flex-direction: row;
  }
}
.interior-feature-promo > .main-image {
  width: 100%;
}
@media only screen and (min-width: 767px) {
  .interior-feature-promo > .main-image {
    width: calc(100% - 235px);
  }
}
.interior-feature-promo > .main-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.interior-feature-promo > .content {
  width: 205px;
  padding: 15px;
}

.hover-image {
  position: relative;
}
.hover-image .base-image {
  display: block;
}
.hover-image .hover-image {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}
.hover-image:hover .hover-image {
  display: block;
}

.history-timeline {
  padding: 17px 20px 4px;
  margin-bottom: 8px;
  background-color: #ecf1f5;
}
.history-timeline .date {
  float: left;
  margin-top: -7px;
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: italic;
  color: #b42c33;
  font-size: 30px;
  line-height: 30px;
}
.history-timeline .description {
  margin-left: 90px;
}

.news-list-view .teaser-text {
  padding-bottom: 15px;
  border-bottom: 1px solid #e1abad;
  margin-bottom: 15px;
}
.news-list-view .teaser-text p {
  margin-bottom: 0px;
}
.news-list-view .news-list-date {
  font-weight: 600;
  font-size: 11px;
  color: #4D4D4D;
  margin-bottom: -3px;
}
.news-list-view h3 {
  margin-bottom: 4px;
}
.news-list-view h3 a {
  font-family: "Source Sans 3", Helvetica, Arial, sans-serif;
  font-size: 15px;
  line-height: 20px;
}

.news-single h3 {
  color: #155085;
  font-size: 25px;
  line-height: 28px;
  font-weight: 600;
}
.news-single .news-list-author,
.news-single .news-list-date {
  font-weight: bold;
}
.news-single .news-img-wrap {
  float: left;
  margin: 5px 15px 10px 0;
}
.news-single .news-text-wrap {
  margin-bottom: 15px;
}
.news-single .news-backlink-wrap {
  margin-bottom: 15px;
}

.news-backlink-wrap {
  clear: both;
}

.page-navigation p {
  float: left;
  margin-right: 20px;
}
.page-navigation ul {
  list-style: none;
  float: left;
}
.page-navigation ul li {
  font-size: 12px;
  line-height: 19px;
  float: left;
  margin-right: 10px;
}

.sidebar-container {
  border-radius: 6px;
  padding-bottom: 40px;
}
.sidebar-container p {
  color: #b42c33;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
}
.sidebar-container .button-red, .sidebar-container .physician-app.search .search-wrap form input[type=submit], .physician-app.search .search-wrap form .sidebar-container input[type=submit] {
  margin-top: 10px;
  display: block;
  float: left;
}

#content-block-1 #tx-solr-search {
  width: auto;
  float: none;
}
#content-block-1 #tx-solr-search .results-teaser {
  position: relative;
}
#content-block-1 #tx-solr-search .results-list {
  margin-left: 0px;
}
#content-block-1 #tx-solr-search .relevance {
  position: absolute;
  right: 0px;
  top: -24px;
}
#content-block-1 #tx-solr-search .relevance .tx-solr-relevance {
  background-color: #b42c33;
}

/*
  ##Device = Desktop
  ##Screen = 1204px and above
*/
@media only screen and (min-width: 1204px) {
  /* Mobile Menu */
  #mobile-nav-trigger,
  #mobile-nav {
    display: none;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles
  ##Screen = B/w 320px to 812px
*/
@media only screen and (min-width: 320px) and (max-width: 767px) {
  /*--------------------------------------------------------------
  # Global
  --------------------------------------------------------------*/
  body {
    margin-bottom: 0 !important;
    /*--------------------------------------------------------------
    # Header
    --------------------------------------------------------------*/
    /* Hamburger Icon */
    /* Mobile Menu */
    /*--------------------------------------------------------------
      # Images
      --------------------------------------------------------------*/
  }
  body #page-wrap {
    width: auto;
    max-width: 960px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  body blockquote {
    margin: 16px 10px 20px 0 !important;
    padding-bottom: 6px;
  }
  body p {
    font-size: 16px;
    line-height: 26px;
  }
  body #header-page {
    height: auto;
    margin: 20px auto 30px !important;
    position: relative;
  }
  body #header-page #identity {
    width: 100%;
    max-width: 230px;
    background-size: contain !important;
    height: 80px !important;
    float: none;
    margin: 85px 0 10px 0 !important;
    display: inline-block;
  }
  body #header-page #utility-nav {
    float: none;
    margin: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
  }
  body #header-page #utility-nav li {
    float: none;
    display: inline-block;
    border-right: 0;
    border-left: 1px solid #c4d3e0;
    margin-right: 0;
    margin-left: 13px;
    padding-right: 0;
    padding-left: 13px;
  }
  body #header-page .search {
    float: none;
    margin: 0;
    width: 50%;
    display: inline-block;
    position: absolute;
    top: 50px;
    right: 0;
  }
  body #header-page .search input {
    width: 100%;
    box-sizing: border-box;
  }
  body #mobile-nav-trigger {
    display: block;
    position: relative;
    float: right;
    margin: 116px -10px 0 0px;
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  body #mobile-nav-trigger span {
    width: 30px;
    height: 5px;
    background-color: #155085;
    display: block;
    margin: 4px 0;
    transition: 0.4s;
    position: relative;
    z-index: 20;
  }
  body #mobile-nav-trigger.open > span:first-child {
    -webkit-transform: rotate(-45deg) translate(-9px, 4px);
    transform: rotate(-45deg) translate(-9px, 4px);
  }
  body #mobile-nav-trigger.open > span:last-child {
    -webkit-transform: rotate(45deg) translate(-8px, -4px);
    transform: rotate(45deg) translate(-8px, -4px);
  }
  body #mobile-nav-trigger.open > span:not(:first-child):not(:last-child) {
    opacity: 0;
  }
  body #mobile-nav-trigger.open:before {
    content: "";
    display: block;
    background-color: #fff;
    border: 1px solid #155085;
    border-bottom-color: #fff;
    position: absolute;
    top: -6px;
    left: -13px;
    width: 71px;
    height: 70px;
    z-index: 10;
  }
  body #global-nav-wrap {
    position: inherit;
  }
  body #global-nav-wrap #section-nav, body #global-nav-wrap #global-nav {
    display: none;
  }
  body #global-nav-wrap #mobile-nav {
    display: none;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #155085;
    width: calc(100% + 40px);
    margin: 0 -20px;
    box-sizing: border-box;
    z-index: 9;
  }
  body #global-nav-wrap #mobile-nav.active {
    display: block;
  }
  body #mobile-nav {
    /* Submenu */
  }
  body #mobile-nav ul {
    width: auto;
    padding: 20px;
  }
  body #mobile-nav ul li {
    display: block;
    float: none;
    width: auto !important;
    border: 0;
    height: auto;
  }
  body #mobile-nav ul li:first-child, body #mobile-nav ul li:first-child a {
    width: auto;
    border: 0;
  }
  body #mobile-nav ul li a {
    text-align: left;
    color: #155085;
    padding: 5px 0;
    border: 0;
    display: block;
    height: auto;
    font-size: 18px;
    line-height: 22px;
    font-family: "ff-din-web", Helvetica, Arial, sans-serif;
  }
  body #mobile-nav .open a, body #mobile-nav .open a:hover, body #mobile-nav .open a:focus, body #mobile-nav .open:first-child a, body #mobile-nav .open:first-child a:hover, body #mobile-nav .open:first-child a:focus, body #mobile-nav .hovered a, body #mobile-nav .hovered a:hover, body #mobile-nav .hovered a:focus, body #mobile-nav .hovered:first-child a, body #mobile-nav .hovered:first-child a:hover, body #mobile-nav .hovered:first-child a:focus {
    height: auto;
  }
  body #mobile-nav .open + .trigger, body #mobile-nav .open:first-child + .trigger, body #mobile-nav .hovered + .trigger, body #mobile-nav .hovered:first-child + .trigger {
    border-left: 0;
  }
  body #mobile-nav li a:hover, body #mobile-nav li a:focus {
    color: #155085;
  }
  body #mobile-nav .active {
    background-color: transparent;
    border: 0;
  }
  body #mobile-nav .active a {
    color: #155085;
  }
  body #mobile-nav .active a:hover, body #mobile-nav .active a:focus {
    color: #155085;
  }
  body #mobile-nav > ul > li:not(.no-submenu) > span, body #mobile-nav > ul li.has-submenu > span {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
  }
  body #mobile-nav > ul > li:not(.no-submenu) > span strong, body #mobile-nav > ul > li:not(.no-submenu) > span strong, body #mobile-nav > ul li.has-submenu > span strong, body #mobile-nav > ul li.has-submenu > span strong {
    display: inline-block;
    margin-left: auto;
    font-size: 14px;
    color: #155085;
    padding: 5px 0 5px 15px;
    cursor: pointer;
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > span strong, body #mobile-nav > ul li.has-submenu.active > span strong {
    transform: rotate(180deg);
    padding: 5px 15px 5px 0;
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > a, body #mobile-nav > ul > li:not(.no-submenu).active > span, body #mobile-nav > ul > li:not(.no-submenu).active > span a, body #mobile-nav > ul > li:not(.no-submenu).active > span strong {
    color: #b42c33;
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > ul {
    display: block;
  }
  body #mobile-nav ul ul {
    padding: 0 0 10px 20px;
    display: none;
  }
  body #mobile-nav ul ul a {
    font-size: 16px;
    line-height: 18px;
    text-indent: -10px;
    padding-left: 10px;
  }
  body #mobile-nav ul ul li p {
    margin-bottom: 0;
    padding-right: 20px;
  }
  body #mobile-nav > ul li.has-submenu ul {
    padding-bottom: 1px;
  }
  body #mobile-nav > ul li.has-submenu.active ul {
    display: block;
  }
  body #mobile-nav > ul li.has-submenu.active > a {
    color: #b42c33;
  }
  body div.ce-textpic:not(.ce-textpic .ce-left .ce-intext .ce-nowrap):not(.ce-textpic-above):not(.ce-textpic-below):not(.ce-textpic .ce-right .ce-intext):not(.ce-textpic .ce-right .ce-intext .ce-nowrap):not(.ce-textpic .ce-left .ce-intext) {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
  }
  body .ce-textpic .ce-right .ce-intext .ce-gallery, body .ce-textpic .ce-left .ce-intext .ce-gallery, body .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery, body .ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery {
    padding-top: 0;
  }
  body div.ce-textpic .ce-gallery figure, body div.ce-textpic figure.ce-gallery {
    display: block;
  }
  body div.ce-textpic .ce-gallery .image {
    margin-bottom: 20px;
  }
  body figcaption.ce-textpic-caption, body p.ce-textpic-caption.caption {
    display: block;
    padding-top: 12px;
    font-size: 13px;
    line-height: 18px;
  }
  body div.ce-textpic .ce-gallery img, body .single-image {
    height: auto;
    width: 100%;
    margin-bottom: 0;
  }
  body div.ce-textpic .ce-center .ce-textpic .ce-outer {
    float: none;
    width: auto;
    right: inherit;
  }
  body div.ce-textpic .ce-center .ce-textpic .ce-inner {
    float: none;
    right: inherit;
  }
  body div.ce-textpic .ce-right .ce-intext .ce-gallery {
    margin: 0;
    float: none;
  }
  body div.ce-textpic .ce-left .ce-intext .ce-gallery {
    margin-right: 20px;
    width: 100%;
  }
  body div.ce-textpic .ce-left .ce-intext p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  body div.ce-textpic-left .ce-gallery {
    float: none;
    width: 100%;
  }
  body div.ce-textpic-right .ce-gallery {
    width: 100%;
  }
  body div.ce-textpic .ce-center .ce-gallery {
    width: 100%;
  }
  body div.ce-textpic .ce-right .ce-intext .ce-gallery, body div.ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
    margin-left: 0;
    float: none;
  }
  body .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-bodytext, body .ce-textpic .ce-right .ce-intext .ce-nowrap-266 .ce-bodytext {
    margin-right: 0;
  }
  /*--------------------------------------------------------------
  # Layout
  --------------------------------------------------------------*/
  #generated-content-1 {
    display: none;
  }
  #f1d #page-wrap #content-wrap,
  #f2d #page-wrap #content-wrap {
    width: auto;
    float: none;
  }
  #f2d #page-wrap #content-block-1 {
    width: auto;
    float: none;
  }
  #f2d #page-wrap #content-block-2 {
    width: auto;
    float: none;
    margin-top: 40px;
  }
  #f3b #page-wrap #content-wrap {
    float: none;
    margin: 0;
  }
  #f3b #page-wrap #content-block-1,
  #f3b #page-wrap #content-block-2,
  #f3b #page-wrap #content-block-3 {
    width: auto;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    min-height: inherit !important;
  }
  #f3b #page-wrap #content-block-1,
  #f3b #page-wrap #content-block-2 {
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 20px;
  }
  /* Columns */
  body .column-group .column:not(.column-1-c3204):not(.column-2-c3204), body .module-group .module {
    float: none;
    margin: 0;
    width: auto;
  }
  body .column-group .column img {
    width: 100%;
    height: auto;
    margin: 0;
  }
  body .hover-image .hover-image {
    display: none !important;
  }
  /*--------------------------------------------------------------
  # Feature
  --------------------------------------------------------------*/
  #f1d #page-wrap #feature,
  #f2d #page-wrap #feature {
    width: auto;
    float: none;
    margin-bottom: 30px;
  }
  body .interior-feature-promo {
    margin-bottom: 0;
  }
  body .interior-feature-promo > .main-image {
    float: none;
    width: 100%;
    height: auto;
    display: block;
  }
  body .interior-feature-promo > .content {
    float: none;
    width: auto;
    padding: 10px 15px;
  }
  body p.feature-text {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  body figcaption, body p.caption {
    margin-top: 0;
    font-size: 13px;
    line-height: 18px;
  }
  body .ce-textpic .ce-left .ce-intext .ce-nowrap .ce-bodytext {
    margin: 0;
  }
  /*--------------------------------------------------------------
  # New Home
  --------------------------------------------------------------*/
  body#f1a #feature .flexslider {
    margin: 0 0 20px 0 !important;
    height: auto;
  }
  body#f1a #feature .flexslider .slides a {
    display: block;
  }
  body#f1a #feature .flexslider .slides img {
    margin: 0;
  }
  body#f1a #feature .flexslider .slides .slide-text {
    padding: 20px 20px 0 20px;
    border: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
  }
  body#f1a #feature .flexslider .slides .slide-text .discription span {
    font-size: 14px !important;
    line-height: 21px;
  }
  body#f1a #feature .flexslider .slides .more-btn {
    float: none;
    width: auto;
    margin-top: 14px;
    margin-left: 0;
    height: auto;
  }
  body#f1a #feature .flexslider .slides .more-btn a {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    padding-bottom: 6px;
    padding-top: 11px;
  }
  body#f1a #feature .flexslider .flex-control-nav {
    bottom: inherit !important;
    top: inherit !important;
    left: inherit !important;
    position: relative;
    padding: 14px;
    box-sizing: border-box;
    text-align: center !important;
  }
  body#f1a #feature .flexslider .flex-control-nav li {
    margin: 0 6px !important;
  }
  body#f1a #feature .flexslider .flex-control-nav li a {
    width: 14px !important;
    height: 14px !important;
    box-shadow: none;
  }
  body#f1a #feature .flexslider .flex-control-nav .flex-prev, body#f1a #feature .flexslider .flex-control-nav .flex-next {
    top: 70px !important;
  }
  /*--------------------------------------------------------------
  # Home
  --------------------------------------------------------------*/
  body #find-physician h3, body .physician-app.search .search-wrap h3 {
    font-size: 15px;
    line-height: 25px;
  }
  body #find-physician .content, body .physician-app.search .search-wrap .content {
    height: auto;
    min-height: 107px;
    padding-bottom: 30px;
  }
  body #find-physician .hasCustomSelect, body .physician-app.search .search-wrap .hasCustomSelect, body #find-physician .roundedInput, body .physician-app.search .search-wrap .roundedInput {
    width: 100%;
    height: auto;
    padding: 10px 10px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  body #find-physician .content .button-red, body #find-physician .content .physician-app.search .search-wrap form input[type=submit], .physician-app.search .search-wrap form body #find-physician .content input[type=submit], body .physician-app.search .search-wrap .content .button-red, body .physician-app.search .search-wrap .content form input[type=submit], body .physician-app.search .search-wrap form .content input[type=submit] {
    font-size: 15px;
    line-height: 20px;
    padding: 10px 20px 6px;
    margin-bottom: 0;
    margin-top: 5px;
  }
  body #content-wrap .home-promo {
    margin-bottom: 40px;
  }
  body #content-wrap .home-promo img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  body #content-wrap .home-promo .home-promo-text h3 {
    font-size: 21px;
    line-height: 27px;
  }
  body #content-wrap .home-promo .home-promo-text p {
    font-size: 17px;
    margin-bottom: 0.6em;
    line-height: 25px;
  }
  body #content-wrap .home-promo .home-promo-text p:last-child {
    margin-bottom: 0;
  }
  body #content-wrap .home-promo .home-promo-text .link {
    font-size: 16px;
    line-height: 20px;
    background-position: 100% 4px;
    padding-right: 20px;
  }
  body .promo-toggle-btn {
    background-size: 980px auto;
    background-position: 100% 50%;
  }
  /*--------------------------------------------------------------
  # About Us
  --------------------------------------------------------------*/
  body {
    /* History */
  }
  body .column-group .column-1-c3204, body .column-group .column-2-c3204 {
    width: 50%;
    margin: 0;
    box-sizing: border-box;
  }
  body .column-group .column-2-c3204 {
    padding-left: 20px;
  }
  body .history-timeline .date {
    margin-top: 3px;
    float: none;
  }
  body .history-timeline .description {
    margin-left: 0;
    margin-top: 5px;
  }
  body .history-timeline img {
    width: 100%;
    height: auto;
    display: block;
    padding-left: 0 !important;
  }
  body .history-timeline table th, body .history-timeline table td {
    display: block;
  }
  body .history-timeline table th:last-child, body .history-timeline table td:last-child {
    margin-top: 10px;
  }
  /* Make a Contribution */
  body #page-wrap .bronx-donations .form-section {
    margin-bottom: 20px;
  }
  body #page-wrap .bronx-donations .form-section fieldset:not(.donation) .column, body #page-wrap .bronx-donations .form-section fieldset:not(.donation) .column.last, body #page-wrap .bronx-donations .row.two-column-row .column {
    width: 100% !important;
  }
  body #page-wrap .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
    width: 100%;
  }
  body #page-wrap .bronx-donations input[type=text], body #page-wrap .bronx-donations input[type=email] {
    box-sizing: border-box;
    height: 35px;
  }
  body #page-wrap .bronx-donations .submit-wrap {
    margin: 0 auto 30px;
  }
  body #page-wrap .bronx-donations .submit-wrap button {
    width: 100%;
  }
  /*--------------------------------------------------------------
  # Physicians
  --------------------------------------------------------------*/
  /* Tabs */
  body .physician-app.search {
    /* List */
  }
  body .physician-app.search .tabs {
    height: auto;
    margin-top: 0;
  }
  body .physician-app.search .tabs li {
    margin-top: 10px;
  }
  body .physician-app.search .search-wrap form input[type=submit] {
    padding: 12px 29px 10px 16px;
    margin-left: 0;
    font-size: 16px;
  }
  body .physician-app.search .results-wrap .results li > a:first-of-type {
    width: 25%;
    display: block;
    position: relative;
    float: left;
  }
  body .physician-app.search {
    /* List */
  }
  body .physician-app.search .tabs {
    height: auto;
    margin-top: 0;
  }
  body .physician-app.search .tabs li {
    margin-top: 10px;
  }
  body .physician-app.search .search-wrap form input[type=submit] {
    padding: 12px 29px 10px 16px;
    margin-left: 0;
    font-size: 16px;
  }
  body .physician-app.search .results-wrap .results li > a:first-of-type {
    width: 25%;
    display: block;
    position: relative;
    float: left;
  }
  body .physician-app.search .results-wrap .results li img {
    height: auto;
    margin-right: 0;
    width: 100%;
  }
  body .physician-app.search .results-wrap .results li .placeholder {
    height: auto;
    margin-right: 0;
    width: 100%;
  }
  body .physician-app.search .results-wrap .results li .description {
    width: 75%;
    padding-left: 20px;
    margin: 0;
    box-sizing: border-box;
    float: right;
  }
  body .physician-app.search .results-wrap .results li .description a {
    display: block;
  }
  body .physician-app.search .results-wrap .results li .location {
    width: 75%;
    padding-left: 20px;
    margin: 0;
    box-sizing: border-box;
    float: right;
  }
  body .physician-app.search .results-wrap .results li .button-red, body .physician-app.search .results-wrap .results li .search-wrap form input[type=submit], body .physician-app.search .search-wrap form .results-wrap .results li input[type=submit] {
    float: right;
    width: calc(75% - 20px);
    box-sizing: border-box;
    padding: 6px 13px 4px;
  }
  /* Profile */
  body .physician-app.profile .header {
    float: none;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    min-height: 130px;
    justify-content: flex-end;
  }
  body .physician-app.profile .header .media {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
  }
  body .physician-app.profile .header .media a.video {
    float: none;
    margin-top: 20px !important;
  }
  body .physician-app.profile .header .description {
    width: 50%;
    box-sizing: border-box;
    padding-right: 0;
    padding-top: 10px;
  }
  body .physician-app.profile .location {
    height: auto !important;
    width: auto;
    float: none;
    border-left: 0;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  body .physician-app.profile #map-canvas {
    display: none;
  }
  body .physician-app.profile .details {
    width: auto;
    float: none;
  }
  body .physician-app.profile .details li {
    font-size: 16px;
    line-height: 26px;
  }
  /*--------------------------------------------------------------
  # Network
  --------------------------------------------------------------*/
  .location-network-link {
    display: none;
  }
  body #c1222 .content-wrap .column-wrap {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 660px;
  }
  body #c1222 .content-wrap .column-wrap .column {
    float: none;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  body #c1222 .content-wrap .column-wrap .column:last-child {
    float: none;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
  }
  body #c1222 .content-wrap .column-wrap .column:last-child .location-category {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
  }
  body #c1222 .content-wrap .column-wrap .column .map img {
    height: auto;
    width: 100%;
  }
  /*--------------------------------------------------------------
  # News
  --------------------------------------------------------------*/
  body .news-list-view .news-list-date {
    margin-bottom: 4px;
    font-size: 13px;
    line-height: 16px;
  }
  /*--------------------------------------------------------------
  # Videos
  --------------------------------------------------------------*/
  body a.youtube-vimeo-lightbox {
    width: 100% !important;
    margin: 0 !important;
  }
  body a.youtube-vimeo-lightbox img {
    width: 100%;
    height: auto;
    display: block;
  }
  body a.local-video-lightbox {
    width: 100% !important;
    margin: 0 !important;
  }
  body a.local-video-lightbox img {
    width: 100%;
    height: auto;
    display: block;
  }
  body .fancybox-wrap {
    width: 100% !important;
    left: 0 !important;
    padding: 20px;
    box-sizing: border-box;
  }
  body .fancybox-wrap .fancybox-inner {
    width: auto !important;
  }
  body .fancybox-overlay iframe {
    width: 100%;
  }
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  body #footer-page {
    margin: 0 -20px;
    padding: 30px 20px !important;
  }
  body #footer-page .bottom-cap {
    border: 0;
    padding-top: 0 !important;
  }
  body #footer-page .bottom-cap .copyright {
    position: relative;
    bottom: inherit;
    right: inherit;
    margin-top: 20px;
    font-size: 13px;
  }
  body #footer-page .bottom-cap .footer-id {
    background-size: contain;
  }
  body #footer-page .bottom-cap .footer-id span {
    display: none;
  }
  body #footer-columns-wrap {
    display: none;
  }
}
/*
  ##Device = iPhone X
  ##Screen = B/w 320px to 812px
*/
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  /*--------------------------------------------------------------
  # Global
  --------------------------------------------------------------*/
  body {
    margin-bottom: 0 !important;
  }
  body #page-wrap {
    width: auto;
    max-width: 960px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  body blockquote {
    margin: 16px 10px 20px 0 !important;
    padding-bottom: 6px;
  }
  body p {
    font-size: 16px;
    line-height: 26px;
  }
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  body #header-page {
    height: auto;
    margin: 20px auto 30px !important;
    position: relative;
  }
  body #header-page #identity {
    width: 100%;
    max-width: 230px;
    background-size: contain !important;
    height: 80px !important;
    float: none;
    margin: 85px 0 10px 0 !important;
    display: inline-block;
  }
  body #header-page #utility-nav {
    float: none;
    margin: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
  }
  body #header-page #utility-nav li {
    float: none;
    display: inline-block;
    border-right: 0;
    border-left: 1px solid #c4d3e0;
    margin-right: 0;
    margin-left: 13px;
    padding-right: 0;
    padding-left: 13px;
  }
  body #header-page .search {
    float: none;
    margin: 0;
    width: 50%;
    display: inline-block;
    position: absolute;
    top: 50px;
    right: 0;
  }
  body #header-page .search input {
    width: 100%;
    box-sizing: border-box;
  }
  /* Hamburger Icon */
  body #mobile-nav-trigger {
    display: block;
    position: relative;
    float: right;
    margin: 116px -10px 0 0px;
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  body #mobile-nav-trigger span {
    width: 30px;
    height: 5px;
    background-color: #155085;
    display: block;
    margin: 4px 0;
    transition: 0.4s;
    position: relative;
    z-index: 20;
  }
  body #mobile-nav-trigger.open:before {
    content: "";
    display: block;
    background-color: #fff;
    border: 1px solid #155085;
    border-bottom-color: #fff;
    position: absolute;
    top: -6px;
    left: -13px;
    width: 71px;
    height: 70px;
    z-index: 10;
  }
  body #mobile-nav-trigger.open span:first-child {
    -webkit-transform: rotate(-45deg) translate(-9px, 4px);
    transform: rotate(-45deg) translate(-9px, 4px);
  }
  body #mobile-nav-trigger.open span:last-child {
    -webkit-transform: rotate(45deg) translate(-8px, -4px);
    transform: rotate(45deg) translate(-8px, -4px);
  }
  body #mobile-nav-trigger.open span:not(:first-child):not(:last-child) {
    opacity: 0;
  }
  /* Mobile Menu */
  body #global-nav-wrap {
    position: inherit;
  }
  body #global-nav-wrap #section-nav {
    display: none;
  }
  body #global-nav-wrap #global-nav {
    display: none;
  }
  body #global-nav-wrap #mobile-nav {
    display: none;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #155085;
    width: calc(100% + 40px);
    margin: 0 -20px;
    box-sizing: border-box;
  }
  body #global-nav-wrap #mobile-nav.active {
    display: block;
  }
  body #mobile-nav ul {
    width: auto;
    padding: 20px;
  }
  body #mobile-nav li {
    display: block;
    float: none;
    width: auto !important;
    border: 0;
    height: auto;
  }
  body #mobile-nav li:first-child {
    width: auto;
    border: 0;
  }
  body #mobile-nav li:first-child a {
    width: auto;
    border: 0;
  }
  body #mobile-nav li a {
    text-align: left;
    color: #155085;
    padding: 5px 0;
    border: 0;
    display: block;
    height: auto;
    font-size: 18px;
    line-height: 22px;
    font-family: "ff-din-web", Helvetica, Arial, sans-serif;
  }
  body #mobile-nav li a:hover {
    color: #155085;
  }
  body #mobile-nav li a:focus {
    color: #155085;
  }
  body #mobile-nav .open a {
    height: auto;
  }
  body #mobile-nav .open a:hover {
    height: auto;
  }
  body #mobile-nav .open a:focus {
    height: auto;
  }
  body #mobile-nav .open:first-child a {
    height: auto;
  }
  body #mobile-nav .open:first-child a:hover {
    height: auto;
  }
  body #mobile-nav .open:first-child a:focus {
    height: auto;
  }
  body #mobile-nav .open:first-child + .trigger {
    border-left: 0;
  }
  body #mobile-nav .open + .trigger {
    border-left: 0;
  }
  body #mobile-nav .hovered a {
    height: auto;
  }
  body #mobile-nav .hovered a:hover {
    height: auto;
  }
  body #mobile-nav .hovered a:focus {
    height: auto;
  }
  body #mobile-nav .hovered:first-child a {
    height: auto;
  }
  body #mobile-nav .hovered:first-child a:hover {
    height: auto;
  }
  body #mobile-nav .hovered:first-child a:focus {
    height: auto;
  }
  body #mobile-nav .hovered + .trigger {
    border-left: 0;
  }
  body #mobile-nav .active {
    background-color: transparent;
    border: 0;
  }
  body #mobile-nav .active a {
    color: #155085;
  }
  body #mobile-nav .active a:hover {
    color: #155085;
  }
  body #mobile-nav .active a:focus {
    color: #155085;
  }
  body #mobilel-nav .hovered:first-child + .trigger {
    border-left: 0;
  }
  /* Submenu */
  body #mobile-nav > ul > li:not(.no-submenu) > a {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  body #mobile-nav > ul > li:not(.no-submenu) > a:after {
    display: inline-block;
    content: "▼";
    margin-left: auto;
    font-size: 14px;
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > a {
    color: #b42c33;
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > a:after {
    content: "▲";
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > ul {
    display: block;
  }
  body #mobile-nav > ul li.has-submenu > a {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  body #mobile-nav > ul li.has-submenu > a:after {
    display: inline-block;
    content: "▼";
    margin-left: auto;
    font-size: 14px;
  }
  body #mobile-nav > ul li.has-submenu ul {
    padding-bottom: 1px;
  }
  body #mobile-nav > ul li.has-submenu.active > a {
    color: #b42c33;
  }
  body #mobile-nav > ul li.has-submenu.active > a:after {
    content: "▲";
  }
  body #mobile-nav > ul li.has-submenu.active ul {
    display: block;
  }
  body #mobile-nav ul ul {
    padding: 0 0 10px 20px;
    display: none;
  }
  body #mobile-nav ul ul a {
    font-size: 16px;
    line-height: 18px;
    text-indent: -10px;
    padding-left: 10px;
  }
  body #mobile-nav ul ul li p {
    margin-bottom: 0;
    padding-right: 20px;
  }
  /*--------------------------------------------------------------
  # Images
  --------------------------------------------------------------*/
  body div.ce-textpic:not(.ce-textpic-above):not(.ce-textpic-below):not(.ce-textpic .ce-right .ce-intext):not(.ce-textpic .ce-right .ce-intext .ce-nowrap):not(.ce-textpic .ce-left .ce-intext) {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
  }
  body div.ce-textpic .ce-gallery figure {
    display: block;
  }
  body div.ce-textpic .ce-gallery .image {
    margin-bottom: 20px;
  }
  body div.ce-textpic .ce-gallery img {
    height: auto;
    width: 100%;
    margin-bottom: 0;
  }
  body div.ce-textpic .ce-gallery {
    display: block;
  }
  body .ce-textpic .ce-right .ce-intext .ce-gallery {
    padding-top: 0;
  }
  body .ce-textpic .ce-left .ce-intext .ce-gallery {
    padding-top: 0;
  }
  body .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
    padding-top: 0;
  }
  body .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-bodytext {
    margin-right: 0;
  }
  body .ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery {
    padding-top: 0;
  }
  body figcaption, body p.caption {
    display: block;
    padding-top: 12px;
    font-size: 13px;
    line-height: 18px;
  }
  body .single-image {
    height: auto;
    width: 100%;
    margin-bottom: 0;
  }
  body div.ce-textpic .ce-center .ce-outer {
    float: none;
    width: auto;
    right: inherit;
  }
  body div.ce-textpic .ce-center .ce-inner {
    float: none;
    right: inherit;
  }
  body div.ce-textpic .ce-center .ce-gallery {
    width: 100%;
  }
  body div.ce-textpic .ce-right .ce-intext .ce-gallery {
    margin: 0;
    float: none;
    margin-left: 0;
    float: none;
  }
  body div.ce-textpic .ce-left .ce-intext .ce-gallery {
    margin-right: 20px;
    width: 100%;
  }
  body div.ce-textpic .ce-left .ce-intext p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  body div.ce-textpic .ce-left .ce-gallery {
    float: none;
    width: 100%;
  }
  body div.ce-textpic .ce-right .ce-gallery {
    width: 100%;
  }
  body div.ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
    margin-left: 0;
    float: none;
  }
  body .ce-textpic .ce-right .ce-intext .ce-nowrap-266 .ce-bodytext {
    margin-right: 0;
  }
  /*--------------------------------------------------------------
  # Layout
  --------------------------------------------------------------*/
  #generated-content-1 {
    display: none;
  }
  #f1d #page-wrap #content-wrap {
    width: auto;
    float: none;
  }
  #f2d #page-wrap #content-wrap {
    width: auto;
    float: none;
  }
  #f2d #page-wrap #content-block-1 {
    width: auto;
    float: none;
  }
  #f2d #page-wrap #content-block-2 {
    width: auto;
    float: none;
    margin-top: 40px;
  }
  #f3b #page-wrap #content-wrap {
    float: none;
    margin: 0;
  }
  #f3b #page-wrap #content-block-1 {
    width: auto;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    min-height: inherit !important;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 20px;
  }
  #f3b #page-wrap #content-block-2 {
    width: auto;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    min-height: inherit !important;
    border-bottom: 1px solid #CCCCCC;
    margin-bottom: 20px;
  }
  #f3b #page-wrap #content-block-3 {
    width: auto;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    min-height: inherit !important;
  }
  /* Columns */
  body .column-group .column:not(.column-1-c3204):not(.column-2-c3204) {
    float: none;
    margin: 0;
    width: auto;
  }
  body .column-group .column img {
    width: 100%;
    height: auto;
    margin: 0;
  }
  body .module-group .module {
    float: none;
    margin: 0;
    width: auto;
  }
  body .hover-image .hover-image {
    display: none !important;
  }
  /*--------------------------------------------------------------
  # Feature
  --------------------------------------------------------------*/
  #f1d #page-wrap #feature {
    width: auto;
    float: none;
    margin-bottom: 30px;
  }
  #f2d #page-wrap #feature {
    width: auto;
    float: none;
    margin-bottom: 30px;
  }
  body .interior-feature-promo {
    margin-bottom: 0;
  }
  body .interior-feature-promo > .main-image {
    float: none;
    width: 100%;
    height: auto;
    display: block;
  }
  body .interior-feature-promo > .content {
    float: none;
    width: auto;
    padding: 10px 15px;
  }
  body p.feature-text {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  body figcaption, body p.caption {
    margin-top: 0;
    font-size: 11px;
    line-height: 17px;
  }
  body p.caption {
    margin-top: 0;
    font-size: 11px;
    line-height: 17px;
  }
  body .ce-textpic .ce-left .ce-intext .ce-nowrap .ce-bodytext {
    margin: 0;
  }
  /*--------------------------------------------------------------
  # New Home
  --------------------------------------------------------------*/
  body#f1a #feature .flexslider {
    margin: 0 0 20px 0 !important;
    padding-bottom: 70px;
    height: auto;
  }
  body#f1a #feature .flexslider .slides a {
    display: block;
  }
  body#f1a #feature .flexslider .slides img {
    margin: 0;
  }
  body#f1a #feature .flexslider .slides .slide-text {
    padding: 20px 20px 0 20px;
    border: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
  }
  body#f1a #feature .flexslider .slides .slide-text .discription span {
    font-size: 17px !important;
    line-height: 25px;
  }
  body#f1a #feature .flexslider .slides .more-btn {
    float: none;
    width: auto;
    margin-top: 14px;
    margin-left: 0;
    height: auto;
  }
  body#f1a #feature .flexslider .slides .more-btn a {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    padding-bottom: 6px;
    padding-top: 11px;
  }
  body#f1a #feature .flexslider .flex-control-nav {
    bottom: inherit !important;
    top: inherit !important;
    left: inherit !important;
    position: relative;
    padding: 14px;
    box-sizing: border-box;
    text-align: center !important;
  }
  body#f1a #feature .flexslider .flex-control-nav li {
    margin: 0 6px !important;
  }
  body#f1a #feature .flexslider .flex-control-nav li a {
    width: 14px !important;
    height: 14px !important;
    box-shadow: none;
  }
  body#f1a #feature .flexslider .flex-direction-nav .flex-prev {
    top: 70px !important;
  }
  body#f1a #feature .flexslider .flex-direction-nav .flex-next {
    top: 70px !important;
  }
  /*--------------------------------------------------------------
  # Home
  --------------------------------------------------------------*/
  body #find-physician h3 {
    font-size: 15px;
    line-height: 25px;
  }
  body #find-physician .content {
    height: auto;
    min-height: 107px;
    padding-bottom: 30px;
  }
  body #find-physician .content .button-red, body #find-physician .content .physician-app.search .search-wrap form input[type=submit], .physician-app.search .search-wrap form body #find-physician .content input[type=submit] {
    font-size: 15px;
    line-height: 20px;
    padding: 10px 20px 6px;
    margin-bottom: 0;
    margin-top: 5px;
  }
  body #find-physician .hasCustomSelect {
    width: 100%;
    height: auto;
    padding: 10px 10px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  body #find-physician .roundedInput {
    width: 100%;
    height: auto;
    padding: 10px 10px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  body .physician-app.search .search-wrap h3 {
    font-size: 15px;
    line-height: 25px;
  }
  body .physician-app.search .search-wrap .content {
    height: auto;
    min-height: 107px;
    padding-bottom: 30px;
  }
  body .physician-app.search .search-wrap .content .button-red, body .physician-app.search .search-wrap .content form input[type=submit], body .physician-app.search .search-wrap form .content input[type=submit] {
    font-size: 15px;
    line-height: 20px;
    padding: 10px 20px 6px;
    margin-bottom: 0;
    margin-top: 5px;
  }
  body .physician-app.search .search-wrap .hasCustomSelect {
    width: 100%;
    height: auto;
    padding: 10px 10px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  body .physician-app.search .search-wrap .roundedInput {
    width: 100%;
    height: auto;
    padding: 10px 10px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  body .home-promo img {
    width: 100%;
    height: auto;
  }
  body .home-promo .home-promo-text h3 {
    font-size: 21px;
    line-height: 27px;
  }
  body .home-promo .home-promo-text p {
    font-size: 17px;
    margin-bottom: 1em;
    line-height: 25px;
  }
  body .home-promo .home-promo-text p:last-child {
    margin-bottom: 0;
  }
  body .home-promo .home-promo-text .link {
    font-size: 16px;
    line-height: 20px;
    background-position: 100% 4px;
    padding-right: 20px;
  }
  body .promo-toggle-btn {
    background-size: 980px auto;
    background-position: 100% 50%;
  }
  /*--------------------------------------------------------------
  # About Us
  --------------------------------------------------------------*/
  body .column-group .column-1-c3204 {
    width: 50%;
    margin: 0;
    box-sizing: border-box;
  }
  body .column-group .column-2-c3204 {
    width: 50%;
    margin: 0;
    box-sizing: border-box;
    padding-left: 20px;
  }
  /* History */
  body .history-timeline .date {
    margin-top: 3px;
    float: none;
  }
  body .history-timeline .description {
    margin-left: 0;
    margin-top: 5px;
  }
  body .history-timeline img {
    width: 100%;
    height: auto;
    display: block;
    padding-left: 0 !important;
  }
  body .history-timeline table th {
    display: block;
  }
  body .history-timeline table th:last-child {
    margin-top: 10px;
  }
  body .history-timeline table td {
    display: block;
  }
  body .history-timeline table td:last-child {
    margin-top: 10px;
  }
  /* Make a Contribution */
  body #page-wrap .bronx-donations .form-section {
    margin-bottom: 20px;
  }
  body #page-wrap .bronx-donations .form-section fieldset:not(.donation) .column {
    width: 100% !important;
  }
  body #page-wrap .bronx-donations .form-section fieldset:not(.donation) .column.last {
    width: 100% !important;
  }
  body #page-wrap .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
    width: 100%;
  }
  body #page-wrap .bronx-donations input[type=text], body #page-wrap .bronx-donations input[type=email] {
    box-sizing: border-box;
    height: 35px;
  }
  body #page-wrap .bronx-donations .submit-wrap {
    margin: 0 auto 30px;
  }
  body #page-wrap .bronx-donations .submit-wrap button {
    width: 100%;
  }
  body #page-wrap .bronx-donations .row.two-column-row .column {
    width: 100% !important;
  }
  /*--------------------------------------------------------------
  # Physicians
  --------------------------------------------------------------*/
  /* Tabs */
  body .physician-app.search .tabs {
    height: auto;
    margin-top: 0;
  }
  body .physician-app.search .tabs li {
    margin-top: 10px;
  }
  body .physician-app.search .search-wrap form input[type=submit] {
    padding: 12px 29px 10px 16px;
    margin-left: 0;
    font-size: 16px;
  }
  /* List */
  body .physician-app.search .results-wrap .results li > a:first-of-type {
    width: 25%;
    display: block;
    position: relative;
    float: left;
  }
  body .physician-app.search .results-wrap .results li img {
    height: auto;
    margin-right: 0;
    width: 100%;
  }
  body .physician-app.search .results-wrap .results li .placeholder {
    height: auto;
    margin-right: 0;
    width: 100%;
  }
  body .physician-app.search .results-wrap .results li .description {
    width: 75%;
    padding-left: 20px;
    margin: 0;
    box-sizing: border-box;
    float: right;
  }
  body .physician-app.search .results-wrap .results li .description a {
    display: block;
  }
  body .physician-app.search .results-wrap .results li .location {
    width: 75%;
    padding-left: 20px;
    margin: 0;
    box-sizing: border-box;
    float: right;
  }
  body .physician-app.search .results-wrap .results li .button-red, body .physician-app.search .results-wrap .results li .search-wrap form input[type=submit], body .physician-app.search .search-wrap form .results-wrap .results li input[type=submit] {
    float: right;
    width: calc(75% - 20px);
    box-sizing: border-box;
    padding: 6px 13px 4px;
  }
  /* Profile */
  body .physician-app.profile .header {
    float: none;
    margin-bottom: 20px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    min-height: 130px;
    justify-content: flex-end;
  }
  body .physician-app.profile .header .media {
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
  }
  body .physician-app.profile .header .media a.video {
    float: none;
    margin-top: 20px !important;
  }
  body .physician-app.profile .header .description {
    width: 50%;
    box-sizing: border-box;
    padding-right: 0;
    padding-top: 10px;
  }
  body .physician-app.profile .location {
    height: auto !important;
    width: auto;
    float: none;
    border-left: 0;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  body .physician-app.profile #map-canvas {
    display: none;
  }
  body .physician-app.profile .details {
    width: auto;
    float: none;
  }
  /*--------------------------------------------------------------
  # Network
  --------------------------------------------------------------*/
  .location-network-link {
    display: none;
  }
  body #c1222 .content-wrap .column-wrap {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 660px;
  }
  body #c1222 .content-wrap .column-wrap .column {
    float: none;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  body #c1222 .content-wrap .column-wrap .column:last-child {
    float: none;
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 30px;
  }
  body #c1222 .content-wrap .column-wrap .column:last-child .location-category {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
  }
  body #c1222 .content-wrap .column-wrap .column .map img {
    height: auto;
    width: 100%;
  }
  /*--------------------------------------------------------------
  # News
  --------------------------------------------------------------*/
  body .news-list-view .news-list-date {
    margin-bottom: 4px;
    font-size: 13px;
    line-height: 16px;
  }
  /*--------------------------------------------------------------
  # Videos
  --------------------------------------------------------------*/
  body a.youtube-vimeo-lightbox {
    width: 100% !important;
    margin: 0 !important;
  }
  body a.youtube-vimeo-lightbox img {
    width: 100%;
    height: auto;
    display: block;
  }
  body a.local-video-lightbox {
    width: 100% !important;
    margin: 0 !important;
  }
  body a.local-video-lightbox img {
    width: 100%;
    height: auto;
    display: block;
  }
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  body #footer-page {
    margin: 0 -20px;
    padding: 30px 20px !important;
  }
  body #footer-page .bottom-cap {
    border: 0;
    padding-top: 0 !important;
  }
  body #footer-page .bottom-cap .copyright {
    position: relative;
    bottom: inherit;
    right: inherit;
    margin-top: 20px;
    font-size: 13px;
  }
  body #footer-page .bottom-cap .footer-id {
    background-size: contain;
  }
  body #footer-page .bottom-cap .footer-id span {
    display: none;
  }
  body #footer-columns-wrap {
    display: none;
  }
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1203px
*/
@media only screen and (min-width: 768px) and (max-width: 1203px) and (orientation: landscape) {
  /* Mobile Menu */
  #mobile-nav-trigger,
  #mobile-nav {
    display: none;
  }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1203px
*/
@media only screen and (min-width: 768px) and (max-width: 1203px) and (orientation: portrait) {
  /*--------------------------------------------------------------
  # Global
  --------------------------------------------------------------*/
  body {
    margin-bottom: 0 !important;
  }
  body #page-wrap {
    width: auto;
    max-width: 960px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  body blockquote {
    margin: 16px 10px 20px 0 !important;
    padding-bottom: 6px;
  }
  body p {
    font-size: 16px;
    line-height: 26px;
  }
  body .first-header {
    width: 75%;
  }
  body .csc-firstHeader {
    width: 75%;
  }
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  body #header-page {
    height: auto;
    margin: 20px auto 20px !important;
    position: relative;
  }
  body #header-page #identity {
    width: 100%;
    max-width: 280px;
    background-size: contain !important;
    height: 96px !important;
    float: none;
    margin: 0;
    display: inline-block;
  }
  body #header-page #utility-nav {
    float: none;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    width: 50%;
  }
  body #header-page #utility-nav li {
    float: none;
    display: inline-block;
    border-right: 0;
    border-left: 1px solid #c4d3e0;
    margin-right: 0;
    margin-left: 13px;
    padding-right: 0;
    padding-left: 13px;
  }
  body #header-page .search {
    float: none;
    margin: 0;
    width: 100%;
    max-width: 200px;
    display: inline-block;
    position: absolute;
    top: 63px;
    right: 60px;
  }
  body #header-page .search input {
    width: 100%;
    box-sizing: border-box;
  }
  /* Hamburger Icon */
  body #mobile-nav-trigger {
    display: block;
    position: relative;
    float: right;
    margin: 50px -10px 0 0px;
    display: inline-block;
    padding: 10px;
    box-sizing: border-box;
  }
  body #mobile-nav-trigger span {
    width: 30px;
    height: 5px;
    background-color: #155085;
    display: block;
    margin: 4px 0;
    transition: 0.4s;
    z-index: 20;
    position: relative;
  }
  body #mobile-nav-trigger.open:before {
    content: "";
    display: block;
    background-color: #fff;
    border: 1px solid #155085;
    border-bottom-color: #fff;
    position: absolute;
    top: -6px;
    left: -8px;
    width: 58px;
    height: 78px;
    z-index: 10;
  }
  body #mobile-nav-trigger.open span:first-child {
    -webkit-transform: rotate(-45deg) translate(-9px, 4px);
    transform: rotate(-45deg) translate(-9px, 4px);
  }
  body #mobile-nav-trigger.open span:last-child {
    -webkit-transform: rotate(45deg) translate(-8px, -4px);
    transform: rotate(45deg) translate(-8px, -4px);
  }
  body #mobile-nav-trigger.open span:not(:first-child):not(:last-child) {
    opacity: 0;
  }
  /* Mobile Menu */
  body #global-nav-wrap {
    position: inherit;
  }
  body #global-nav-wrap #section-nav {
    display: none;
  }
  body #global-nav-wrap #global-nav {
    display: none;
  }
  body #global-nav-wrap #mobile-nav {
    display: none;
    position: absolute;
    right: 8px;
    top: 20px;
    background-color: rgba(255, 255, 255, 0.95);
    border: 1px solid #155085;
    width: calc(66.66% + 40px);
    margin: 0 -20px;
    box-sizing: border-box;
  }
  body #global-nav-wrap #mobile-nav.active {
    display: block;
  }
  body #mobile-nav ul {
    width: auto;
    padding: 20px;
  }
  body #mobile-nav li {
    display: block;
    float: none;
    width: auto !important;
    border: 0;
    height: auto;
  }
  body #mobile-nav li:first-child {
    width: auto;
    border: 0;
  }
  body #mobile-nav li:first-child a {
    width: auto;
    border: 0;
  }
  body #mobile-nav li a {
    text-align: left;
    color: #155085;
    padding: 5px 0;
    border: 0;
    display: block;
    height: auto;
    font-size: 18px;
    line-height: 22px;
    font-family: "ff-din-web", Helvetica, Arial, sans-serif;
  }
  body #mobile-nav li a:hover {
    color: #155085;
  }
  body #mobile-nav li a:focus {
    color: #155085;
  }
  body #mobile-nav .open a {
    height: auto;
  }
  body #mobile-nav .open a:hover {
    height: auto;
  }
  body #mobile-nav .open a:focus {
    height: auto;
  }
  body #mobile-nav .open:first-child a {
    height: auto;
  }
  body #mobile-nav .open:first-child a:hover {
    height: auto;
  }
  body #mobile-nav .open:first-child a:focus {
    height: auto;
  }
  body #mobile-nav .open:first-child + .trigger {
    border-left: 0;
  }
  body #mobile-nav .open + .trigger {
    border-left: 0;
  }
  body #mobile-nav .hovered a {
    height: auto;
  }
  body #mobile-nav .hovered a:hover {
    height: auto;
  }
  body #mobile-nav .hovered a:focus {
    height: auto;
  }
  body #mobile-nav .hovered:first-child a {
    height: auto;
  }
  body #mobile-nav .hovered:first-child a:hover {
    height: auto;
  }
  body #mobile-nav .hovered:first-child a:focus {
    height: auto;
  }
  body #mobile-nav .hovered + .trigger {
    border-left: 0;
  }
  body #mobile-nav .active {
    background-color: transparent;
    border: 0;
  }
  body #mobile-nav .active a {
    color: #155085;
  }
  body #mobile-nav .active a:hover {
    color: #155085;
  }
  body #mobile-nav .active a:focus {
    color: #155085;
  }
  body #mobilel-nav .hovered:first-child + .trigger {
    border-left: 0;
  }
  /* Submenu */
  body #mobile-nav > ul > li:not(.no-submenu) > a {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  body #mobile-nav > ul > li:not(.no-submenu) > a:after {
    display: inline-block;
    content: "▼";
    margin-left: auto;
    font-size: 14px;
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > a {
    color: #b42c33;
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > a:after {
    content: "▲";
  }
  body #mobile-nav > ul > li:not(.no-submenu).active > ul {
    display: block;
  }
  body #mobile-nav > ul li.has-submenu > a {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  body #mobile-nav > ul li.has-submenu > a:after {
    display: inline-block;
    content: "▼";
    margin-left: auto;
    font-size: 14px;
  }
  body #mobile-nav > ul li.has-submenu ul {
    padding-bottom: 1px;
  }
  body #mobile-nav > ul li.has-submenu.active > a {
    color: #b42c33;
  }
  body #mobile-nav > ul li.has-submenu.active > a:after {
    content: "▲";
  }
  body #mobile-nav > ul li.has-submenu.active ul {
    display: block;
  }
  body #mobile-nav ul ul {
    padding: 0 0 10px 20px;
    display: none;
  }
  body #mobile-nav ul ul a {
    font-size: 16px;
    line-height: 18px;
    text-indent: -10px;
    padding-left: 10px;
  }
  body #mobile-nav ul ul li p {
    margin-bottom: 0;
    padding-right: 20px;
  }
  /*--------------------------------------------------------------
  # Images
  --------------------------------------------------------------*/
  body div.ce-textpic .ce-gallery img,
  body .single-image {
    height: auto;
    width: 100%;
  }
  /*body div.ce-textpic:not(.ce-textpic-above):not(.ce-textpic-below):not(.ce-textpic .ce-right .ce-intext):not(.ce-textpic .ce-right .ce-intext .ce-nowrap) {
      position: relative;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
  }

  body .ce-textpic .ce-right .ce-intext .ce-gallery,
  body .ce-textpic .ce-left .ce-intext .ce-gallery,
  body .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery,
  body .ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery {
      padding-top: 0;
  }

  body div.ce-textpic .ce-gallery figure,
  body div.ce-textpic figure.ce-gallery {
      display: block;
  }

  body div.ce-textpic .ce-gallery .image {
      margin-bottom: 20px;
  }

  body figcaption.ce-textpic-caption {
      display: block;
      padding-top: 12px;
      font-size: 13px;
      line-height: 18px;
  }

  body div.ce-textpic .ce-gallery img,
  body .single-image {
      height: auto;
      width: 100%;
      margin-bottom: 0;
  }

  body div.ce-textpic .ce-center .ce-textpic .ce-outer{
      float: none;
      width: auto;
      right: inherit;
  }

  body div.ce-textpic .ce-center .ce-textpic .ce-inner {
      float: none;
      right: inherit;
  }

  body div.ce-textpic .ce-right .ce-intext .ce-gallery {
      margin: 0;
      float: none;
  }

  body div.ce-textpic .ce-left .ce-intext .ce-gallery {
      margin-right: 20px;
  }

  body div.ce-textpic .ce-left .ce-intext p {
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 20px;
  }

  body div.ce-textpic-left .ce-gallery {
      float: none;
      width: 100%;
  }

  body div.ce-textpic-right .ce-gallery {
      width: 100%;
  }

  body div.ce-textpic .ce-center .ce-gallery {
      width: 100%;
  }

  body div.ce-textpic .ce-right .ce-intext .ce-gallery,
  body div.ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
      margin-left: 0;
      float: none;
  }

  body .ce-textpic .ce-right .ce-intext .ce-nowrap .ce-bodytext,
  body .ce-textpic .ce-right .ce-intext .ce-nowrap-266 .ce-bodytext {
      margin-right: 0;
  }*/
  /*--------------------------------------------------------------
  # Layout
  --------------------------------------------------------------*/
  #generated-content-1 {
    display: none;
  }
  /*--------------------------------------------------------------
  # New Home
  --------------------------------------------------------------*/
  body#f1a .column-1-c3172 {
    width: calc(75% - 15px);
    margin-right: 15px;
  }
  body#f1a .column-1-c3172 .flexslider {
    height: auto;
  }
  body#f1a .column-1-c3172 .flexslider .slides li > a {
    display: block;
  }
  body#f1a .column-1-c3172 .flexslider .slides img {
    width: 100%;
    height: auto;
  }
  body#f1a .column-1-c3172 .flexslider .slides .slide-text {
    padding: 20px;
    border: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
  }
  body#f1a .column-1-c3172 .flexslider .slide-text .discription {
    margin-top: 13px;
  }
  body#f1a .column-1-c3172 .flexslider .slide-text .more-btn {
    float: none;
    width: auto;
    margin-top: 14px;
    margin-left: 0;
    height: auto;
  }
  body#f1a .column-1-c3172 .flexslider .flex-control-nav {
    bottom: 106px !important;
    left: 17px !important;
    top: inherit !important;
  }
  body#f1a .column-2-c3172 {
    width: 25%;
  }
  /* Columns */
  body .home-promo .home-promo-text {
    padding: 0;
  }
  body .home-promo .home-promo-text h3 {
    font-size: 15px;
    line-height: 18px;
  }
  body .home-promo .home-promo-text p {
    font-size: 12px;
    line-height: 16px;
  }
  body .promo-toggle-btn {
    background-size: 980px auto;
    background-position: 100% 50%;
  }
  body #f1a #c4036 .column-group {
    margin: 0 -10px;
  }
  body #f1a #c4036 .column {
    width: calc(25% - 20px);
    margin: 0 10px;
    margin-bottom: 20px;
  }
  body #f1a #c816 .column-group {
    margin: 0 -10px;
  }
  body #f1a #c816 .column {
    width: calc(25% - 20px);
    margin: 0 10px;
    margin-bottom: 20px;
  }
  /*--------------------------------------------------------------
  # Home
  --------------------------------------------------------------*/
  body #c811 .column {
    width: calc(25% - 12px);
  }
  body #c811 .column.column-4-c811 {
    margin-right: 0;
    width: 25%;
  }
  body .home-promo img {
    width: 100%;
    height: auto;
  }
  body #c823 img {
    width: 100%;
    height: auto;
  }
  body #find-physician .content {
    height: auto;
  }
  body #find-physician .hasCustomSelect {
    width: 100%;
    height: auto;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  body #find-physician .roundedInput {
    width: 100%;
    height: auto;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  body .physician-app.search .search-wrap .content {
    height: auto;
  }
  body .physician-app.search .search-wrap .hasCustomSelect {
    width: 100%;
    height: auto;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  body .physician-app.search .search-wrap .roundedInput {
    width: 100%;
    height: auto;
    padding: 6px;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid #ccc;
    font-size: 13px !important;
    margin-bottom: 10px;
    outline: none;
  }
  /*body #find-physician h3,
  body .physician-app.search .search-wrap h3 {
      font-size: 15px;
      line-height: 25px;
  }


  body #find-physician .hasCustomSelect,
  body .physician-app.search .search-wrap .hasCustomSelect,
  body #find-physician .roundedInput,
  body .physician-app.search .search-wrap .roundedInput {
      width: 100%;
      height: auto;
      padding: 10px 10px;
      box-sizing: border-box;
      box-shadow: none;
      border: 1px solid #ccc;
      font-size: 13px !important;
      margin-bottom: 10px;
      outline: none;
  }

  body #find-physician .content .button-red,
  body .physician-app.search .search-wrap .content .button-red {
      font-size: 15px;
      line-height: 20px;
      padding: 10px 20px 6px;
      margin-bottom: 0;
      margin-top: 5px;
  }


  body .home-promo .home-promo-text h3 {
      font-size: 21px;
      line-height: 27px;
  }

  body .home-promo .home-promo-text p {
      font-size: 17px;
      margin-bottom: 1em;
      line-height: 25px;
  }

  body .home-promo .home-promo-text p:last-child {
      margin-bottom: 0;
  }

  body .home-promo .home-promo-text .link {
      font-size: 16px;
      line-height: 20px;
      background-position: 100% 4px;
      padding-right: 20px;
  }*/
  /*--------------------------------------------------------------
  # About Us
  --------------------------------------------------------------*/
  /* History */
  body .history-timeline .date {
    margin-top: 3px;
    float: none;
  }
  body .history-timeline .description {
    margin-left: 0;
    margin-top: 5px;
  }
  body .history-timeline img {
    width: 100%;
    height: auto;
    display: block;
    padding-left: 0 !important;
  }
  body .history-timeline table th {
    display: block;
  }
  body .history-timeline table th:last-child {
    margin-top: 10px;
  }
  body .history-timeline table td {
    display: block;
  }
  body .history-timeline table td:last-child {
    margin-top: 10px;
  }
  /* Make a Contribution */
  body #page-wrap .bronx-donations .form-section {
    margin-bottom: 20px;
  }
  body #page-wrap .bronx-donations .form-section fieldset:not(.donation) .column {
    width: 100% !important;
  }
  body #page-wrap .bronx-donations .form-section fieldset:not(.donation) .column.last {
    width: 100% !important;
  }
  body #page-wrap .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
    width: 100%;
  }
  body #page-wrap .bronx-donations input[type=text] {
    box-sizing: border-box;
    height: 35px;
  }
  body #page-wrap .bronx-donations input[type=email] {
    box-sizing: border-box;
    height: 35px;
  }
  body #page-wrap .bronx-donations .submit-wrap {
    margin: 0 auto 30px;
  }
  body #page-wrap .bronx-donations .submit-wrap button {
    width: 100%;
  }
  body #page-wrap .bronx-donations .row.two-column-row .column {
    width: 100% !important;
  }
  /*--------------------------------------------------------------
  # Physicians
  --------------------------------------------------------------*/
  /* Profile */
  body .physician-app.profile .header .media img.photo {
    margin-top: 0;
  }
  /*--------------------------------------------------------------
  # Network
  --------------------------------------------------------------*/
  /*.location-network-link {
      display: none;
  }

  body #c1222 .content-wrap .column-wrap {
      position: relative;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 660px;
  }

  body #c1222 .content-wrap .column-wrap .column,
  body #c1222 .content-wrap .column-wrap .column:last-child {
      float: none;
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 0;
  }

  body #c1222 .content-wrap .column-wrap .column:last-child {
      margin-bottom: 30px;
  }

  body #c1222 .content-wrap .column-wrap .column:last-child .location-category {
      position: absolute;
      bottom: 0;
      padding-bottom: 30px;
  }

  body #c1222 .content-wrap .column-wrap .column:first-child {
  }

  body #c1222 .content-wrap .column-wrap .column .map {
  }

  body #c1222 .content-wrap .column-wrap .column .map img {
      height: auto;
      width: 100%;
  }*/
  /*--------------------------------------------------------------
  # News
  --------------------------------------------------------------*/
  /*body .news-list-view .news-list-date {
      margin-bottom: 4px;
      font-size: 13px;
      line-height: 16px;
  }*/
  /*--------------------------------------------------------------
  # Videos
  --------------------------------------------------------------*/
  /*body a.youtube-vimeo-lightbox,
  body a.local-video-lightbox {
      width: 100% !important;
      margin: 0 !important;
  }

  body a.youtube-vimeo-lightbox img,
  body a.local-video-lightbox img {
      width: 100%;
      height: auto;
      display: block;
  }*/
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  body #footer-page {
    margin: 0 -20px;
  }
  body #footer-page #footer-columns-inner-wrap {
    max-width: inherit;
    width: 75%;
  }
  body #footer-page #footer-contact {
    width: calc(25% - 21px);
    margin-bottom: 40px;
  }
  body .column-1-c59 {
    width: 25%;
    margin-right: 10px !important;
  }
  body .column-2-c59 {
    width: 20%;
    margin-right: 34px !important;
  }
  body .column-3-c59 {
    width: 15%;
    margin-right: 34px !important;
  }
  body .column-4-c59 {
    width: 15%;
    margin-right: 0 !important;
  }
}
.column-group {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: 30px;
}
@media only screen and (max-width: 767px) {
  .column-group {
    flex-direction: column;
  }
}
.column-group .column {
  padding: 0;
  width: auto;
}
.column-group .column > :first-child {
  margin-top: 0;
}
.column-group .column > :last-child {
  margin-bottom: 0;
}
.column-group .three-column-group, .column-group .four-column-group {
  flex-wrap: wrap;
}
.column-group .quarter {
  flex-basis: 25%;
  margin-bottom: 0;
}
.column-group .quarter:last-child, .column-group .quarter:nth-child(3) {
  margin-bottom: 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .column-group .quarter {
    flex-basis: 22%;
  }
}
@media only screen and (max-width: 767px) {
  .column-group .quarter {
    flex-basis: 100%;
  }
}
.column-group .half {
  flex-basis: 50%;
  margin-bottom: 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .column-group .half {
    flex-basis: 46%;
  }
}
@media only screen and (max-width: 767px) {
  .column-group .half {
    flex-basis: 100%;
  }
}
.column-group .third {
  flex-basis: 32%;
  flex-wrap: nowrap;
  margin-bottom: 0;
}
.column-group .third--1 {
  min-width: initial;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .column-group .third {
    flex-basis: 29%;
    flex-wrap: nowrap;
  }
}
@media only screen and (max-width: 767px) {
  .column-group .third {
    flex-basis: 100%;
  }
}
.column-group .two-third {
  flex-basis: 69%;
  flex-wrap: nowrap;
  margin-bottom: 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .column-group .two-third {
    flex-basis: 68%;
    flex-wrap: nowrap;
  }
}
@media only screen and (max-width: 767px) {
  .column-group .two-third {
    flex-basis: 100%;
  }
}
.column-group .three-quarter {
  flex-basis: 77.5%;
  flex-wrap: nowrap;
  margin-bottom: 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .column-group .three-quarter {
    flex-basis: 75%;
    flex-wrap: nowrap;
  }
}
@media only screen and (max-width: 767px) {
  .column-group .three-quarter {
    flex-basis: 100%;
  }
}

.text-images {
  *zoom: 1;
}
.text-images:before, .text-images:after {
  content: " ";
  display: table;
}
.text-images:after {
  clear: both;
}
.text-images__body-text .headline {
  margin-top: 0;
}
.text-images__image-group-wrap {
  box-sizing: border-box;
  margin: 0 auto 10px;
}
.text-images__image-group-wrap--medium {
  width: 60%;
}
.text-images__image-group-wrap--small {
  width: 30%;
}
.text-images__image-intext .text-images__image-group-wrap {
  width: 50%;
}
.text-images__image-intext .text-images__image-group-wrap--medium {
  width: 30%;
}
.text-images__image-intext .text-images__image-group-wrap--small {
  width: 20%;
}
.text-images__image-intext.text-images__image-left .text-images__image-group-wrap {
  float: left;
  margin: 0;
  padding: 0 40px 30px 0;
}
.text-images__image-intext.text-images__image-right .text-images__image-group-wrap {
  float: right;
  margin: 0;
  padding: 0 0 30px 35px;
}
.text-images__image-beside-text.text-images__image-left .text-images__body-text {
  margin: 0 0 0 50%;
}
.text-images__image-beside-text.text-images__image-left.text-images__image-size--medium .text-images__body-text {
  margin: 0 0 0 30%;
}
.text-images__image-beside-text.text-images__image-left.text-images__image-size--small .text-images__body-text {
  margin: 0 0 0 20%;
}
.text-images__image-beside-text.text-images__image-right .text-images__body-text {
  margin: 0 50% 0 0;
}
.text-images__image-beside-text.text-images__image-right.text-images__image-size--medium .text-images__body-text {
  margin: 0 30% 0 0;
}
.text-images__image-beside-text.text-images__image-right.text-images__image-size--small .text-images__body-text {
  margin: 0 20% 0 0;
}
.text-images__image {
  width: 100%;
}
.text-images__image-size--original.text-images__image-center .text-images__image-group img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.text-images__image-size--original.text-images__image-intext.text-images__image-in-text .text-images__image-group-wrap {
  width: auto;
}
.text-images__image-size--original.text-images__image-intext.text-images__image-beside-text.text-images__image-right .text-images__image-group {
  float: right;
}

.textpic__image-wrap {
  width: 100%;
  margin-bottom: 5px;
  margin-inline: auto;
}
.textpic__image-wrap img {
  width: 100%;
}

.ce-wrapper--textpic::after {
  content: "";
  display: block;
  clear: both;
}

.ce-image.ce-center .common-image {
  margin-left: auto;
  margin-right: auto;
}
.ce-image.ce-left .common-image {
  margin-left: 0;
  margin-right: auto;
}
.ce-image.ce-right .common-image {
  margin-left: auto;
  margin-right: 0;
}

.ce-gallery[data-ce-columns="2"] .ce-row {
  display: flex;
  gap: 20px;
}
.ce-gallery[data-ce-columns="2"] .ce-column {
  flex-basis: 50%;
}

.textpic--image-below-center {
  display: flex;
  flex-direction: column-reverse;
}

.fancybox-content {
  width: 1280px;
  max-width: 95% !important;
  padding: 25px !important;
  overflow: hidden !important;
}

.fancybox-slide--html .fancybox-close-small {
  right: -7px !important;
  top: -6px !important;
}

.video-teaser .card-img-top {
  width: 100%;
}
.video-teaser a {
  cursor: pointer;
}
.video-teaser__info {
  padding: 10px 15px;
  color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.video-teaser__title {
  float: left;
}
.video-teaser__time {
  float: right;
}
.video-teaser--lightbox .video-teaser__video {
  display: none;
}
.video-teaser__video > video, .video-teaser__video > iframe {
  max-width: 100%;
}
.video-teaser__video-wrap {
  position: relative;
}
.video-teaser__play-arrow {
  background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/play_button.png) no-repeat scroll left top;
  height: 31px;
  left: 50%;
  margin-left: -16px;
  margin-top: -16px;
  position: absolute;
  top: 50%;
  width: 31px;
}

#player-in-lightbox {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.fancybox-wrap .fancybox-outer,
.fancybox-wrap .fancybox-inner {
  position: relative !important;
}

/* END SELECTOR PLUGIN ONLY */
/* .bronx-donations .row .column {
  margin: 0 20px 0 0 !important;
} */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  body #page-wrap .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
    /* width: 100%; */
    width: 101px;
    float: left !important;
    /* margin-left: 20px; */
  }
  .bronx-donations .row .column {
    margin-bottom: 0 !important;
    margin-right: 20px !important;
  }
  #programs_wrap .input-combo:last-child {
    display: block;
    margin-top: 10px;
  }
}
#powermail_fieldwrap_24 label, #powermail_fieldwrap_25 label {
  font-size: 19px !important;
}

.bronx-donations .form-section--payment-form-amount {
  margin-bottom: 0;
}

.bronx-donations {
  border-top: none;
  border-bottom: none;
}

#payment_form {
  border-top: 1px solid #b42c33;
  border-bottom: 1px solid #b42c33;
}

/* END SELECTOR PLUGIN ONLY */
.form-section--payment-form-amount .last.active label,
.form-section--payment-form-amount .last.active .donation_select {
  display: none;
}

.parsley-error-list {
  color: red;
  font-size: 13px;
}

.form-section--donation-select h3 {
  display: none !important;
}

.bronx-donations .form-section .credit-card {
  margin-top: 0 !important;
}

/* .bronx-donations .form-section--donation-select fieldset.donation .amount-wrap .column:nth-last-child(1) {
  width: 101px !important;
  margin-top: 0px !important;
  margin-right: 0 !important;
  float: left !important;
}
*/
/* .bronx-donations .form-section--donation-select fieldset.donation .amount-wrap .column:nth-last-child(2) {
  margin-right: 20px !important;
  margin-bottom: 15px;
} */
.bronx-donations .row .column {
  margin: 0 20px 15px 0;
}

.bronx-donations h3 {
  font-size: 19px !important;
  margin-bottom: 4px;
}

.donation {
  position: relative;
}

.donation label {
  position: relative;
  background-color: white;
  width: 66px;
  display: block;
  top: -30px;
  border: 1px solid #336796;
  border-radius: 5px;
  text-align: center;
  padding: 7px 0;
  font-weight: bold;
  color: #009ddc;
  cursor: pointer;
}

.donation label:hover, .input-combo.column.last:hover .donation-other-prefix {
  background-color: #3ac2f9;
  color: #ffffff;
}

.input-combo.column.last.active:hover .donation-other-prefix {
  background-color: #009ddc;
}

.donation .active label {
  background-color: #009ddc;
  color: white;
}

.donation label {
  width: 100px;
  padding: 14px 0;
  font-size: 17px;
}

.bronx-donations .form-section fieldset.donation .amount-wrap .column {
  /* width: 65px; */
  width: 101px;
}

.bronx-donations .form-section fieldset:first-of-type {
  /* margin-top: 0; */
  margin-top: 18px;
}

.bronx-donations h4 {
  font-size: 19px;
}

.bronx-donations input[type=text], .bronx-donations input[type=email] {
  height: 45px;
}

.bronx-donations select {
  height: 45px;
}

.bronx-donations .submit-wrap button {
  font-size: 22px;
}

.bronx-donations input[type=text], .bronx-donations input[type=email] {
  font-size: 17px;
}

.bronx-donations select {
  font-size: 17px;
}

.bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
  margin-top: 0;
  float: right;
  /* margin-right: 10px; */
}

#donationOther:disabled {
  display: none;
}

/* .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
  margin-top: -27px;
  float: right;
  margin-right: 10px;
} */
.bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
  /* width: 200px; */
  /* width: 216px; */
  width: 101px;
  position: relative;
}

#donationOther {
  display: block;
  border: 1px solid #336796;
  border-radius: 5px;
  display: block;
  font-size: 17px;
  font-weight: 700;
  margin: 0;
  padding: 14px 0 14px 30px;
  position: relative;
  top: -10px;
  vertical-align: baseline;
  width: 70px !important;
  height: auto;
  color: #009ddc;
}

@media only screen and (min-width: 320px) and (max-width: 1199px) and (orientation: portrait) {
  #donationOther {
    width: 101px !important;
    height: 50px !important;
  }
}
.active #donationOther {
  background-color: #009ddc;
  color: white;
}

#donationOther::placeholder {
  opacity: 1;
}

/* .active #donationOther::placeholder {
  opacity: 0;
} */
.donation-other-prefix {
  position: absolute;
  top: 6px;
  left: 18px;
  font-size: 17px;
  font-weight: 700;
  color: #009ddc;
}

.active .donation-other-prefix {
  color: white;
}

@media only screen and (min-width: 320px) and (max-width: 1199px) and (orientation: portrait) {
  .bronx-donations input[type=text], .bronx-donations input[type=email] {
    height: 45px !important;
  }
  /* .bronx-donations .form-section fieldset.donation .amount-wrap .column.last {
    margin-right: 0;
  } */
}
.bronx-donations .form-section--billing {
  margin-bottom: 10px;
}

.pay-check {
  font-size: 18px;
  font-weight: bold;
  line-height: 30px;
  color: #b42c33;
  margin-bottom: 40px;
}

.pay-check--normal-text {
  font-weight: normal;
}

#programs_wrap {
  font-size: 18px;
}

#programs_wrap input {
  width: auto;
}

#programs_wrap input:disabled {
  background-color: white;
  visibility: hidden;
}

#programs_wrap .input-combo {
  margin-right: 15px;
}

.searchinfo {
  margin-bottom: 25px;
}

#tx-solr-search-form-pi-results .input-group {
  background-color: #f2f2f2;
  border: 1px solid #cfcecf;
  padding: 10px;
  margin-bottom: 10px;
}

.tx-solr-q {
  width: 80%;
  border: 1px solid #cfcecf;
  padding: 5px;
  width: 80%;
}

.tx-solr-submit {
  background-color: #cfcecf;
  border: none;
  padding: 6px;
  color: #494949;
  font-weight: bold;
  width: 15%;
}

#tx-solr-search .list-group-item {
  margin-bottom: 20px;
  border-bottom: 1px solid gray;
}

.pagination {
  display: flex;
  gap: 8px;
  float: right;
}
#tx-solr-pagination-bottom .pagination {
  float: none;
  justify-content: center;
}

.solr-pagination {
  margin-top: 15px;
}

.ce-wrapper--bronxcontentelements_homefeatureslider .flexslider .slides img {
  height: auto !important;
}
.ce-wrapper--bronxcontentelements_homefeatureslider .flexslider .slide-text {
  padding: 20px 35px 15px;
}
.ce-wrapper--bronxcontentelements_homefeatureslider .flex-control-nav.flex-control-paging {
  top: auto !important;
  bottom: 0;
}

.news-list-view .f3-widget-paginator {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
.news-list-view .f3-widget-paginator li {
  margin-left: 10px;
}
.news-list-view .f3-widget-paginator {
  justify-content: flex-start;
}

/* @todo-ron clean up following */
#header-page {
  height: 165px;
  margin-bottom: 37px;
  z-index: 100;
  position: relative;
}
#header-page #identity {
  width: 394px;
  height: 97px;
  margin: 20px 0 19px;
  float: left;
  background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/BronxLebanonLogo.png) no-repeat left top;
  overflow: hidden;
  border: none;
}
#header-page #identity a {
  display: block;
  padding-top: 100px;
}
#header-page #utility-nav {
  list-style: none;
  float: right;
  margin: 20px 0 23px 16px;
}
#header-page #utility-nav li {
  float: left;
  font-size: 13px;
  line-height: 13px;
  margin-right: 13px;
  padding-right: 13px;
  border-right: 1px solid #D1DCE6;
}
#header-page #utility-nav li:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
}
#header-page #utility-nav li a {
  color: #155085;
  font-weight: 400;
}
#header-page .search {
  float: right;
  clear: right;
  position: relative;
  overflow: hidden;
  margin-top: 27px;
}
#header-page .search label {
  position: absolute;
  left: -9999px;
}
#header-page .search input {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
  width: 160px;
  padding: 6px 30px 6px 10px;
  background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/SearchGlass.png) no-repeat right 3px;
  border: 1px solid #D1DCE6;
}

#footer-page {
  clear: both;
  background-color: #D1DCE6;
  padding: 30px 20px 10px;
}
#footer-page a {
  font-weight: 400;
}
#footer-page h5 {
  font-size: 12px;
  line-height: 20px;
  margin: 0 0 9px;
  color: #155085;
  text-transform: uppercase;
}
#footer-page #footer-columns-inner-wrap {
  width: 680px;
  float: left;
  padding-bottom: 10px;
}
#footer-page #footer-columns-inner-wrap p {
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 7px;
}
#footer-page #footer-columns-inner-wrap p a {
  color: #333333;
  font-weight: 400;
}
#footer-page #footer-contact {
  width: 215px;
  float: right;
  padding-left: 20px;
  border-left: 1px solid #a1b1c4;
  margin-bottom: 20px;
}
#footer-page #footer-contact p {
  font-weight: bold;
  color: #155085;
  font-size: 12px;
}
#footer-page .bottom-cap {
  clear: both;
  position: relative;
  border-top: 1px solid #a1b1c4;
  padding-top: 10px;
}
#footer-page .bottom-cap .footer-id {
  display: block;
  width: 276px;
  height: 81px;
  background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/BronxLebanonFooterLogo.png) no-repeat left top;
  overflow: hidden;
  margin: 0;
}
#footer-page .bottom-cap .footer-id:hover {
  border-bottom: none;
}
#footer-page .bottom-cap .footer-id span {
  display: block;
  padding-top: 100px;
}
#footer-page .bottom-cap .copyright {
  position: absolute;
  bottom: 9px;
  right: 0;
  font-size: 11px;
}
#footer-page .bottom-cap .copyright a {
  color: #333333;
}

#copyright {
  float: left;
}

#creator-link {
  float: right;
}

#global-nav-wrap {
  clear: both;
  position: relative;
}
#global-nav-wrap #global-nav {
  position: absolute;
  top: 100%;
  z-index: 200;
}
#global-nav-wrap #section-nav {
  position: absolute;
  top: 34px;
  width: 100%;
  z-index: 100;
}

#global-nav {
  clear: both;
  background-color: #155085;
}
#global-nav ul {
  margin: 0;
  width: 960px;
}
#global-nav li {
  float: left;
  height: 33px;
  border-left: 1px solid white;
  border-top: 1px solid #155085;
}
#global-nav li:first-child {
  border-left: 1px solid #155085;
}
#global-nav li a {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 15px;
  line-height: 15px;
  font-weight: 600;
  color: #D1DCE6;
  display: block;
  text-align: center;
  padding: 9px 0px;
}
#global-nav li a:hover {
  color: white;
}
#global-nav li:first-child, #global-nav li:first-child a {
  width: 90px;
}
#global-nav li:nth-child(2), #global-nav li:nth-child(2) a {
  width: 112px;
}
#global-nav li:nth-child(3), #global-nav li:nth-child(3) a {
  width: 120px;
}
#global-nav li:nth-child(4), #global-nav li:nth-child(4) a {
  width: 136px;
}
#global-nav li:nth-child(5), #global-nav li:nth-child(5) a {
  width: 120px;
}
#global-nav li:nth-child(6), #global-nav li:nth-child(6) a {
  width: 106px;
}
#global-nav li:nth-child(7), #global-nav li:nth-child(7) a {
  width: 184px;
}
#global-nav li:last-child, #global-nav li:last-child a {
  width: 83px;
}
#global-nav .active {
  background-color: #D1DCE6;
  border-top: 1px solid #D1DCE6;
  border-left: 1px solid white;
}
#global-nav .active a,
#global-nav .active a:hover,
#global-nav .active a:focus {
  color: #155085;
}
#global-nav .open,
#global-nav .open:first-child,
#global-nav .hovered,
#global-nav .hovered:first-child {
  border-left: 1px solid #b42c33;
  border-top: 1px solid #b42c33;
}
#global-nav .open + .trigger,
#global-nav .open:first-child + .trigger,
#global-nav .hovered + .trigger,
#global-nav .hovered:first-child + .trigger {
  border-left: 1px solid #b42c33;
}
#global-nav .open a,
#global-nav .open a:hover,
#global-nav .open a:focus,
#global-nav .open:first-child a,
#global-nav .open:first-child a:hover,
#global-nav .open:first-child a:focus,
#global-nav .hovered a,
#global-nav .hovered a:hover,
#global-nav .hovered a:focus,
#global-nav .hovered:first-child a,
#global-nav .hovered:first-child a:hover,
#global-nav .hovered:first-child a:focus {
  color: #155085;
  background-color: white;
  height: 30px;
}
#global-nav .open:last-child,
#global-nav .hovered:last-child {
  border-right: 1px solid #b42c33;
}
#section-nav a {
  color: #155085;
  font-size: 15px;
  line-height: 23px;
}
#section-nav a:hover {
  color: #b42c33;
}
#section-nav p {
  margin-bottom: 3px;
  margin-left: 10px;
  text-indent: -10px;
}
#section-nav p.indent {
  margin-left: 20px;
  margin-bottom: 0;
}
#section-nav p.line-after {
  margin-bottom: 5px;
}
#section-nav p, #section-nav p a {
  font-size: 15px;
  line-height: 23px;
}
#section-nav .column-group .column {
  padding-right: 14px;
  border-right: 1px solid #D1DCE6;
  margin-right: 15px;
}
#section-nav .column-group .column:last-child {
  margin-right: 0;
  padding-right: 0;
  border: none;
}
#section-nav .mega-menu-container {
  display: none;
}
#section-nav .mega-menu-container, #section-nav #c71 {
  padding: 20px;
  background-color: white;
  border: 1px solid #b42c33;
}
#section-nav .mega-menu-container--single {
  width: 200px;
}

.local-nav {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 15px;
  line-height: 17px;
}
.local-nav__title {
  font-family: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
  padding-bottom: 7px;
  border-bottom: 1px solid #D1DCE6;
}
.local-nav__title a {
  color: black !important;
}
.local-nav a {
  color: #155085;
}
.local-nav li {
  padding: 7px 0 7px 20px;
  border-bottom: 1px solid #D1DCE6;
  text-indent: -10px;
}
.local-nav li a {
  font-weight: 600;
}
.local-nav li.current {
  background: transparent url(/_assets/663a5755088478c671ee2a99e75bcb32/Images/MenuDot.png) no-repeat left 16px;
}
.local-nav li.current > a {
  color: #b42c33;
}
.local-nav li li {
  border: none;
  font-size: 13px;
  line-height: 15px;
  padding: 4px 0 0 20px;
  margin-left: -10px;
}
.local-nav li li.current {
  background-position: left 8px;
}

#c71 {
  display: none;
}