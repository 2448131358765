// ====================================================================
// Home Feature Slider
// ====================================================================


.ce-wrapper--bronxcontentelements_homefeatureslider {
    .flexslider .slides img {
        height: auto !important;
    }
    .flexslider .slide-text {
        padding: 20px 35px 15px;
    }
    .flex-control-nav.flex-control-paging {
        top: auto !important;
        bottom: 0;
    }    
}


