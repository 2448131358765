		//  ------------------------------------------
		//	SET UP LAYOUT
		//	Put your styles for the general page layout
		//  ------------------------------------------

#page-wrap {
	margin-left: auto;
	margin-right: auto;
	width: $page-width + px;
}

#content-outer-wrap {
	@extend .clearfix;
	margin-bottom: 25px;
}

#content-wrap {
	@extend .clearfix;
}

#f1d,
#f2d {
	#feature {
		width: 710px;
		float: right;
		@extend .clearfix;
	}

	#content-wrap {
		width: 710px;
		float: right;
	}
}

#f2d {
	#content-block-1 {
		width: 455px;
		float: left;
	}
	#content-block-2 {
		width: 235px;
		float: right;
	}
}

#f2a {
	#feature {
		width: 708px;
		float: left;
		padding-right: 18px;
		border-right: 1px solid $color-2b;
		margin-bottom: 20px;
	}
	#content-block-1 {
		width: 215px;
		float: right;
		margin-bottom: 20px;
	}
	#content-block-2 {
		clear: both;
		width: 100%;
		border-top: 1px solid $color-2b;
		padding-top: 20px;
	}
}

#f3b {
	#feature {
		width: 710px;
		float: right;
	}

	#content-wrap {
		float: right;
		margin-top: 20px;
	}

	#content-block-1,
	#content-block-2,
	#content-block-3 {
		width: 210px;
		float: left;
		margin-right: 20px;
		padding-right: 19px;
		border-right: 1px solid $color-1b;
	}
	#content-block-3 {
		margin-right: 0;
		padding-right: 0;
		border: none;
	}
}

#generated-content-1 {
	width: 217px;
	padding-right: 10px;
	border-right: $border-1;
	float: left;
}


#content-block-1{

    & > :first-child , & > :first-child .headline {
        margin-top: 0;
    }

    & > :last-child {
        margin-bottom: 0;
    }
}

// Space before and after
.ce, .frame {
    &-margin, &-space {
        &-top, &-before{

            &-default {
                margin-top: 0;
            }

            &-none {
                margin-top: 0 !important;
            }
            &-0 {
                margin-top: 0 !important;
            }

            &-20 {
                margin-top: 20px !important;
            }


            &-30 {
                margin-top: 30px !important;
            }

            &-40 {
                margin-top: 40px !important;
            }


            &-50 {
                margin-top: 50px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 25px !important;
                }
            }

            &-60 {
                margin-top: 60px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 30px !important;
                }
            }

            &-70 {
                margin-top: 70px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 35px !important;
                }
            }

            &-80 {
                margin-top: 80px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 40px !important;
                }
            }

            &-90 {
                margin-top: 90px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 45px !important;
                }
            }

            &-100 {
                margin-top: 100px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 50px !important;
                }
            }

            &-120 {
                margin-top: 120px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 60px !important;
                }
            }

            &-140 {
                margin-top: 140px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 70px !important;
                }
            }

            &-160 {
                margin-top: 160px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 80px !important;
                }
            }

            &-180 {
                margin-top: 180px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 90px !important;
                }
            }

            &-200 {
                margin-top: 200px !important;
                @media only screen and (max-width: 599px) {
                    margin-top: 100px !important;
                }
            }
        }

        &-bottom, &-after{

            &-default {
                margin-bottom: 0;
            }


            &-none {
                margin-bottom: 0 !important;
            }
            &-0 {
                margin-bottom: 0 !important;
            }

            &-20 {
                margin-bottom: 20px !important;
            }


            &-30 {
                margin-bottom: 30px !important;
            }

            &-40 {
                margin-bottom: 40px !important;
            }

            &-50 {
                margin-bottom: 50px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 25px !important;
                }
            }

            &-60 {
                margin-bottom: 60px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 30px !important;
                }
            }

            &-70 {
                margin-bottom: 70px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 35px !important;
                }
            }

            &-80 {
                margin-bottom: 80px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 40px !important;
                }
            }

            &-90 {
                margin-bottom: 90px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 45px !important;
                }
            }

            &-100 {
                margin-bottom: 100px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 50px !important;
                }
            }

            &-120 {
                margin-bottom: 120px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 60px !important;
                }
            }

            &-140 {
                margin-bottom: 140px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 70px !important;
                }
            }

            &-160 {
                margin-bottom: 160px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 80px !important;
                }
            }

            &-180 {
                margin-bottom: 180px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 90px !important;
                }
            }

            &-200 {
                margin-bottom: 200px !important;
                @media only screen and (max-width: 599px) {
                    margin-bottom: 100px !important;
                }
            }
        }
    }
}
