//  ------------------------------------------
//	CE Structure
//  ------------------------------------------

.ce-wrapper {
    margin-bottom: .5rem;
    &--ruler-before {
        padding-top: 15px;
        border-top: 1px solid $gray-20;
    } 
    &--ruler-after {
        padding-bottom: 15px;
        border-bottom: 1px solid $gray-20;
    } 
    &--menu_subpages {
        margin-bottom: 0;
    }
}


.rich-text {
    :last-child {
        margin-bottom: 0;
    }
}
