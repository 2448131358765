		//  =========================================
		//	DO NOT EDIT THIS FILE!!!!.
		//	If needed, override the styles in the skin.
		//  =========================================


/* @todo-ron STILL A LOT OF WORK TO DO ON THIS INCLUDING RESPONSIVE OR GENERATED PIXEL STYLES */

.module-group {

	/* @todo-ron still need to clean up border radius */

	.module {

		margin-bottom: 15px;

		.header {
			margin: 0;
			padding: 4px 15px 0 0;
			color: #B3B3B3;
			font-size: 14px;
			background-color: #f2f2f2;
			-webkit-border-top-left-radius: 7px;
			-webkit-border-top-right-radius: 7px;
			-moz-border-radius-topleft: 7px;
			-moz-border-radius-topright: 7px;
			border-top-left-radius: 7px;
			border-top-right-radius: 7px;

			span {
				display: block;
				padding: 5px 0 5px 10px;
			}
		}

		.footer {
			height: 15px;
			margin: 0;
			padding-right: 15px;
			font-size: 1px;
			overflow: hidden;
			-webkit-border-bottom-left-radius: 7px;
			-webkit-border-bottom-right-radius: 7px;
			-moz-border-radius-bottomleft: 7px;
			-moz-border-radius-bottomright: 7px;
			border-bottom-left-radius: 7px;
			border-bottom-right-radius: 7px;
			background-color: #f2f2f2;

			span {
				display: block;
				height: 15px;
			}
		}

		.body {
			/* Make sure these two values match the TS constant moduleBodyBorderWidth */
			border-left: 1px solid #e5e5e5;
			border-right: 1px solid #e5e5e5;

			.inner-wrap {
				padding: 10px 10px 10px 10px;
				&.bleed {
					padding: 0;
				}
			}
		}

	}
}