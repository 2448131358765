		//  ------------------------------------------
		//	Styling to override default TYPO3 styles
		//  ------------------------------------------

/* div.ce-textpic-above .ce-textpic-image,
div.ce-textpic-below .ce-textpic-image
*/

/*
div.ce-textpic .ce-gallery .ce-textpic-image {
	margin-top: $base-rhythm * .5;
	margin-bottom: $base-rhythm * .5;
}

div.ce-textpic-imagecolumn,
td.ce-textpic-imagecolumn .ce-textpic-image m{
	margin-right: $base-rhythm * .5;
}
*/

// @todo-fluid All the ce-textpic rules below will have to change for Fluid.
.ce-gallery {
	margin-bottom: $base-rhythm * .5;
}

.ce-textpic-below .ce-gallery {
	margin-top: $base-rhythm;
}


.ce-textpic .ce-left .ce-intext .ce-gallery,
.ce-textpic .ce-right .ce-intext .ce-gallery,
div.ce-textpic .ce-right .ce-intext .ce-gallery .ce-textpic-last,
div.ce-textpic .ce-left .ce-intext .ce-gallery .ce-textpic-last {
	margin-top: 0;
	margin-bottom: 0;
}

div.ce-textpic .ce-left .ce-intext .ce-gallery, div.ce-textpic .ce-left .ce-intext .ce-nowrap .ce-gallery {
	margin-right: $base-rhythm;
}

div.ce-textpic .ce-right .ce-intext .ce-gallery, div.ce-textpic .ce-right .ce-intext .ce-nowrap .ce-gallery {
	margin-left: $base-rhythm;
}

// @todo-fluid This will likely have a simple change to the selector
.frame-ruler-after {
	border-bottom: 1px solid black;
	margin-bottom: 15px;
}

// @todo-fluid This will likely have a simple change to the selector
.frame-ruler-before {
	padding-top: 10px;
	border-top: 1px solid black;
}


.ce-textpic .ce-left .ce-intext .ce-nowrap, .ce-textpic .ce-right .ce-intext .ce-nowrap{
    display: flex;

    & .ce-gallery{
        flex-basis: 45%;
        max-width: 45%;
        margin-right: 4%;
    }

    & .ce-bodytext{
        flex-basis: 45%;
        max-width: 45%;
    }
}
