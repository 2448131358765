.column-group {
    display: flex;
    justify-content: space-between;
    flex-direction:row;
    gap: 30px;

    @media only screen and (max-width: 767px) {
        flex-direction:column;
    }

    & .column {
        padding: 0;
        width: auto;
        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }

    }

    & .three-column-group, & .four-column-group {
        flex-wrap: wrap;
    }

    & .quarter {
        flex-basis: 25%;
        margin-bottom: 0;


        &:last-child, &:nth-child(3) {
            margin-bottom: 0;
        }

        //IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 22%;
        }

        @media only screen and (max-width: 767px) {
            flex-basis: 100%;
        }
    }

    & .half{
        flex-basis: 50%;
        margin-bottom: 0;

        //IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 46%;
        }

        @media only screen and (max-width: 767px) {
            flex-basis: 100%;
        }
    }

    & .third{
        flex-basis: 32%;
        flex-wrap: nowrap;
        margin-bottom: 0;

        &--1 {
            min-width: initial;
        }

        //IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 29%;
            flex-wrap: nowrap;
        }

        @media only screen and (max-width: 767px) {
            flex-basis: 100%;
        }
    }

    & .two-third{
        flex-basis: 69%;
        flex-wrap: nowrap;
        margin-bottom: 0;

       //IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 68%;
            flex-wrap: nowrap;
        }

        @media only screen and (max-width: 767px) {
            flex-basis: 100%;
        }
    }

    & .three-quarter{
        flex-basis: 77.5%;
        // flex-basis: 75%;
        flex-wrap: nowrap;
        margin-bottom: 0;

        //IE11
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex-basis: 75%;
            flex-wrap: nowrap;
        }

        @media only screen and (max-width: 767px) {
            flex-basis: 100%;
        }
    }

}


