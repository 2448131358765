//  ------------------------------------------
//	SEARCH
//  ------------------------------------------


.searchinfo {
    margin-bottom: 25px;
}

#tx-solr-search-form-pi-results .input-group {
    background-color: #f2f2f2;
    border: 1px solid #cfcecf;
    padding: 10px;
    margin-bottom: 10px;
}
    
.tx-solr-q {
    width: 80%;
    border: 1px solid #cfcecf;
    padding: 5px;
    width: 80%;
}

.tx-solr-submit {
    background-color: #cfcecf;
    border: none;
    padding: 6px;
    color: #494949;
    font-weight: bold;
    width: 15%;
}

#tx-solr-search {
    .list-group-item {
        margin-bottom: 20px;
        border-bottom: 1px solid gray;
    }
}




.pagination {
    display: flex;
    gap: 8px;
    float: right;

    #tx-solr-pagination-bottom & {
        float: none;
        justify-content: center;
    }
    
  }
    
.solr-pagination {
    margin-top: 15px;
}


  